import { deathClaimApiSlice } from "./deathClaimApiSlice";

export const deathClaimApiManagementSlice = deathClaimApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    familyMemberSearch: builder.mutation({
      query: (data) => ({
        url: "/reporter",
        method: "POST",
        body:data
      }),
    })
  }),
});


export const {
  useFamilyMemberSearchMutation
} = deathClaimApiManagementSlice