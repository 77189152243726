import { medicalProfessionalApiSlice } from "./medicalProfessionalApiSlice";

export const medicalProfessionalApiManagementSlice = medicalProfessionalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMedicalProfessionalDocs: builder.query({
        query: (data) => ({
          url:`/doc`,
          method: "POST",
          body: data
        }),
      }),
    downloadDocs:  builder.query({
      query: (docId,clientId,medicalprofessionalId) => ({
        url:`/download?docId=${docId}&clientIdentifier=${clientId}&professionalId=${medicalprofessionalId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetMedicalProfessionalDocsQuery,
  useDownloadDocsQuery
} = medicalProfessionalApiManagementSlice;
