import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  external_user_share_landing_page,
  login,
  additional_prof_landingpage,
  firmsuperadmin_LandingPage,
  attorney_LandingPage,
  client_LandingPage,
  executor_LandingPage,
  familymember_landingpage,
} from "../../constants/routes";
import { ChevronDown } from "react-bootstrap-icons";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../constants/labels";
import {} from "../../constants/routes";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ROLE } from "../../constants/role";
import { setEmptyDetails } from "../../slices/shareReceiveBankDetailsSlice";
export default function HeaderProfileExternalUser() {
  const dispatch = useDispatch();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  let media = "lg";
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
      try {
        const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
        dispatch(signOutApiCall());
        dispatch(setEmptyDetails())
        toast.success(res?.message);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
  };

  const handleLogoNavigation = () => {
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);

    if (userInfo?.role === ROLE.firmsuperadmin) {
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${firmsuperadmin_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
        else if(identifierID && requestType && (requestType === "deathClaim")){
          navigate(`${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
        }
        else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
          navigate(`${firmsuperadmin_LandingPage}/reporterlanding`)
        }
        else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
          navigate(`${firmsuperadmin_LandingPage}/additionproflanding`)
        }
      else{
        navigate(firmsuperadmin_LandingPage);
      }
    }else if (userInfo?.role === ROLE.attorney) {
      if(identifierID && requestType && (requestType !== "addProf"  && requestType !== "deathClaim")){
        navigate(
          `${attorney_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${attorney_LandingPage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${attorney_LandingPage}/additionproflanding`)
      }
      else{
        navigate(attorney_LandingPage);
      }
    }else if(userInfo?.role === ROLE.client){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${client_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${client_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${client_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${client_LandingPage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${client_LandingPage}/additionproflanding`)
      }
      else{
        navigate(client_LandingPage);
      }
    } else if(userInfo?.role === ROLE.familymember){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${familymember_landingpage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${familymember_landingpage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${familymember_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      } 
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${familymember_landingpage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${familymember_landingpage}/additionproflanding`)
      }
      else{
        navigate(familymember_landingpage) 
      }
    }else if(userInfo?.role === ROLE.executor){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${executor_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${executor_LandingPage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${executor_LandingPage}/additionproflanding`)
      }
      else{
        navigate(executor_LandingPage);
      }
    }else if (userInfo?.role === ROLE.externaluser){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${external_user_share_landing_page}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${external_user_share_landing_page}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${external_user_share_landing_page}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${external_user_share_landing_page}/additionproflanding`)
      }else{
        navigate(
          `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }
          
    }else if(userInfo?.role === ROLE.additionalProf){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${additional_prof_landingpage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${additional_prof_landingpage}?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${additional_prof_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${additional_prof_landingpage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${additional_prof_landingpage}/additionproflanding`)
      }else{
          navigate(`${additional_prof_landingpage}?identifier=${identifierID}&type=${requestType}`);
      }
    }
    else if(userInfo?.role === ROLE.familymember){
      if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
        navigate(
          `${familymember_landingpage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
        );
      }else if(identifierID && requestType && (requestType === "addProf")){
        navigate(`${familymember_landingpage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(identifierID && requestType && (requestType === "deathClaim")){
        navigate(`${familymember_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
      }
      else if(window.location.pathname.includes("reporterlanding") && identifierID === null){
        navigate(`${familymember_landingpage}/reporterlanding`)
      }
      else if(window.location.pathname.includes("additionproflanding") && identifierID === null){
        navigate(`${familymember_landingpage}/additionproflanding`)
      }else{
          navigate(`${familymember_landingpage}?identifier=${identifierID}&type=${requestType}`);
      }  
    }
    else{
      navigate(login);
    }
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <div
              className="cursor-point"
              data-testid="header-profile-logo"
              onClick={() => handleLogoNavigation()}
            >
              <HeaderLogo />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={dropdownRef}>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {isLoading && <Loader />}
    </>
  );
}
