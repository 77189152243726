import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import InfoIcon from "../../assets/images/information.png";
import PropTypes from "prop-types";

export default function TooltipPopover({ content, placement }) {
  const popover = (
    <Popover id={placement=== "top" ? "tooltip-password" : "tooltip-changeattorney"}>
      <Popover.Body>
        <div>{content}</div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["click", "focus", "hover"]}
      placement={placement}
      overlay={popover}
    >
      <img src={InfoIcon} className="tooltip-img" alt="Info" />
    </OverlayTrigger>
  );
}

TooltipPopover.propTypes = {
  content: PropTypes.object.isRequired,
};
