import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  external_user_share_landing_page
} from "../../constants/routes";
import ShareUserLandingPage from "../../components/externalUser/ShareUserLandingPage";
import NullUserLandingPage from "../../components/externalUser/NullUserLandingPage";
import { setDetails } from "../../slices/shareReceiveBankDetailsSlice";
export default function ExternalUserLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  const urlType = queryParameters.get("type");
  useEffect(() => {
    if (identifierID && urlType && (urlType === "addProf")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${external_user_share_landing_page}/additionproflanding?identifier=${identifierID}&type=${urlType}`);
    }
    if (identifierID && urlType && (urlType === "deathClaim")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${external_user_share_landing_page}/reporterlanding?identifier=${identifierID}&type=${urlType}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let renderComponent;
  if (requestType === "share_bank_detail") {
    renderComponent = <ShareUserLandingPage />;
  } else if (requestType === "null") {
    renderComponent = <NullUserLandingPage />
  }

  return (
    <>
      <div data-testid="external-user-landing-page">{renderComponent}</div>
      <div className="background-mvs-image type-1"></div>
    </>
  );
}
