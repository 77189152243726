import { Container } from "react-bootstrap";

export default function NullUserLandingPage() {
  return (
    <>
      <div
        className="superadmin-container attorney-edit-client-page"
        data-testid="null-user-landing-page"
      >
        <Container fluid className="px-lg-4">
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="p-4 py-5">
              <div className="px-4 py-2 external-user-message">
                Thanks you very much for using FamVault. <br />
                Please visit us on{" "}
                <a href="https://www.famvault.com" className="anchor-tag">
                  www.famvault.com
                </a>{" "}
                to know more about us.
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
    </>
  );
}
