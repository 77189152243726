import React from "react";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";

function BreadCrumb({ items }) {
  return (
    <>
      <nav className="custom-breadcrumb">
        <ol className="breadcrumb">
          {items.map((item) => (
            <LinkContainer key={item.id} state={item.state} to={`${item.link}`}>
              <li
                className={`breadcrumb-item custom-breadcrumb-item ${
                  item.active ? "active" : ""
                }`}
                key={item.id}
              >
                {item.content}
              </li>
            </LinkContainer>
          ))}
        </ol>
      </nav>
    </>
  );
}

BreadCrumb.propTypes = {
  items: PropTypes.array.isRequired,
};

export default BreadCrumb;
