import React from "react";
import { Container, Row } from "react-bootstrap";
import "../../styles/AccessDenied.scss";

export default function AccessExpired() {
  return (
    <>
      <Container fluid className="p-5">
        <Row className="mt-5">
          <div className="custom-access-denied-container p-4">
            <div className="access-denied-text font-60">Access Expired</div>
            <div className="access-denied-text pt-4">
              You dont have permission to view this page. Your access got expired. Request access from
              the administrator.
            </div>
          </div>
        </Row>
      </Container>
      <div className="background-mvs-image type-1"></div>
    </>
  );
}