import React, { useEffect, useState } from "react";
import { Container, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  attorney_LandingPage,
  attorney_ViewClient,
  attorney_client_editFamily,
} from "../../constants/routes";
import Loader from "../../components/Loader/Loader";
import { attorneyTitle, clientDetails } from "../../constants/attorney/lables";
import AttorneyTitleBar from "../../components/attorney/AttorneyTitleBar";
import { useGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import { clientRelationship } from "../../constants/attorney/mockData";
import dayjs from "dayjs";

export default function AttorneyViewFamilyMemberPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: attorney_LandingPage,
      content: attorneyTitle,
      active: false,
    },
    {
      id: "item-2",
      link: attorney_ViewClient,
      content: clientDetails,
      active: true,
    },
  ]);

  const { data, error, isLoading } = useGetStakeHolderDetailsQuery(
    location?.state?.id,
    {
      refetchOnMountOrArgChange: true,
      skip: location?.state?.id === undefined,
    }
  );

  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: attorney_LandingPage,
        content: attorneyTitle,
        active: false,
      },
      {
        id: "item-2",
        link: attorney_ViewClient,
        content: `${data?.familyMember?.name} Details`,
        active: true,
      },
    ]);
  }, [data]);

  const dateString = data?.familyMember?.dateOfBirth || "";
  let formattedDate = "";
  if (dateString) {
    // const date = new Date(dateString);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // formattedDate = `${month < 10 ? "0" : ""}${month}-${
    //   day < 10 ? "0" : ""
    // }${day}-${year}`;
    formattedDate = dayjs(dateString.split('T')[0]).format('MM-DD-YYYY');
  }

  const handleEditPage = () => {
    navigate(attorney_client_editFamily, {
      state: {
        page: "viewFamilyPage",
        id: location?.state?.id,
        res: data,
        prevPage: "viewpage",
      },
    });
  };
  
  const handlePrevPage = () => {
    navigate(attorney_LandingPage);
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div
        className="superadmin-container"
        data-testid="attorney-view-family-page"
      >
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={true}
              buttonText="Edit"
              width="90px"
              title={data?.familyMember?.name ? data?.familyMember?.name : "--"}
              navigateTo={handleEditPage}
              navigateToPrev={handlePrevPage}
              hasBackButton={true}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container">
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3">
            <div className="employee-view-container pt-3">
              <div className="view-details">
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-contant">
                      {data?.familyMember?.name
                        ? data?.familyMember?.name
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">DOB</div>
                    <div className="firm-view-contant">
                      {data?.familyMember?.dateOfBirth ? formattedDate : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Relationship</div>
                    <div className="firm-view-contant">
                      {data?.familyMember?.relationship
                        ? clientRelationship.filter(
                            (ele) => ele.id === data?.familyMember?.relationship
                          )[0]?.label
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Email</div>
                    <div className="firm-view-contant">
                      {data?.familyMember?.emailId
                        ? data?.familyMember?.emailId
                        : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Mobile Phone</div>
                    <div className="firm-view-contant">
                      {data?.familyMember?.phoneNumber
                        ? data?.familyMember?.phoneNumber
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}
