import { Button, Col, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../styles/modalPopupType2.scss"
function CertificateUploadSuccessModal({
  show,
  heading,
  hasCloseButton,
  modalClass,
  modalSize,
  modalContentElement,
  hasFooter,
  footerButtonText,
  footerButtonClass,
  onClickCloseNavigation,
  animation,
  handleNavigation
}) {

  const handleClose = () => {
    onClickCloseNavigation()
  };
  return (
    <>
      <Modal
        centered
        contentClassName={modalClass}
        size={modalSize}
        show={show}
        onHide={handleClose}
        animation={animation}
        data-testid="certificate-upload-success"
      >
        {hasCloseButton ? (
          <Modal.Header closeButton className="border-0" data-testid="modal-header-close-button">
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="border-0">
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="modal-text">{modalContentElement}</Modal.Body>
        {hasFooter && (
            <div className="modal-footer border-0 align-self-center">
            <Row>
            <Col>
            <div className="d-flex justify-content-center" >
            <Button className={footerButtonClass}  onClick={handleNavigation} data-testid="login-button">
              {footerButtonText}
            </Button>
            </div>
            </Col>
        </Row>
        </div>
        )}
      </Modal>
    </>
  );
}

export default CertificateUploadSuccessModal;

CertificateUploadSuccessModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  heading: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  hasBottomCloseButton: PropTypes.bool,
  modalClass: PropTypes.string,
  modalSize: PropTypes.string,
  titleClass: PropTypes.string,
  headerClass: PropTypes.string,
  modalContentElement: PropTypes.object.isRequired,
  hasFooter: PropTypes.bool,
  footerClass: PropTypes.string,
  footerButtonText: PropTypes.string,
  footerButtonClass: PropTypes.string,
  onClickCloseNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  animation: PropTypes.bool,
};
