import { documentApiManagement } from "./documentApiManagement";
import { notificationApiSlice } from "./notificationSlice";

export const notificationApiManagementSlice =
  notificationApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getNotifications: builder.query({
        query: (userId) => ({
          url: `/notifications?userId=${userId}`,
          method: "GET",
        }),
        providesTags: ["getNotifications"],
      }),
      putNotifications: builder.mutation({
        query: (notificationId) => ({
          url: `/notifications?notificationId=${notificationId}`,
          method: "PUT",
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
        },
        invalidatesTags: ["getNotifications"],
      }),
      deleteNotifications: builder.mutation({
        query: (data) => ({
          url: `/notifications`,
          method: "DELETE",
          body: data,
        }),
        invalidatesTags: ["getNotifications"],
      }),
    }),
  });

export const {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  usePutNotificationsMutation,
  useDeleteNotificationsMutation,
} = notificationApiManagementSlice;
