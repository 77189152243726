import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ModalSearch({
  showModal,
  setShowCallback,
  modalContentElement,
  headerTitle,
}) {
  const handleClose = () => setShowCallback(false);
  return (
    <>
      <Modal
        id="modal-popup-container-search"
        className="reassign-attr"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="modal-popup-type2"
      >
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-4 p-md-4 p-sm-4 p-4">
            <div className="modal-content-section">{modalContentElement}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalSearch.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  modalContentElement: PropTypes.object.isRequired,
  headerTitle: PropTypes.string,
};

export default ModalSearch;
