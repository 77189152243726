import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

function ModalPopupType2({ showModal, setShowCallback, setYesCallback, content, headerTitle, singleOkButton, setOkCallback, titleClass, contentClass }) {
  const handleClose = () => setShowCallback(false);
  const handleClickYes = () => setYesCallback(true);
  const handleClickOk= ()=> setOkCallback(true);
  return (
    <>
      <Modal
        id="modal-popup-container-type-2"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        data-testid="modal-popup-type2"
      >
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title className={titleClass ? titleClass:"modal-custom-title"}>
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-5 p-md-5 p-sm-5 p-2">
            <div className={contentClass ?contentClass:"modal-content-section"}>{content}</div>
            {
              singleOkButton ?
              <div className="modal-button-section mt-3">
                <Button onClick={handleClickOk} className="custom-popup-button btn-yes left-radius right-radius me-2" data-testid="ok-button">
                Ok
              </Button>  
              </div>
              :
              <div className="modal-button-section mt-3">
              <Button onClick={handleClickYes} className="custom-popup-button btn-yes left-radius me-2" data-testid="yes-button">
                Yes
              </Button>
              <Button
                onClick={handleClose}
                className="custom-popup-button btn-no right-radius"
                 data-testid="no-button"
              >
                No
              </Button>
            </div>
            }            
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalPopupType2.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  content: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default ModalPopupType2;
