/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Alert, Container, Table } from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
// import { ReactComponent as PwdIcon } from "../../assets/images/pwd-regenerate.svg";
import { useGetfirmbyidQuery } from "../../slices/firmApiManagement";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
// import ModalPopupType2 from "../../components/modal/ModalPopupType2";
// import { useLazyRegenerateadminpasswordQuery } from "../../slices/userAuthManagementSlice";
// import { toast } from "react-toastify";
import { mvssuperadmin_firmEditDetails, mvssuperadmin_firmViewDetails, mvssuperadmin_landingpage } from "../../constants/routes";

const FirmDetailsViewPage = () => {
  // const [showModal, setShowModal] = useState(false);
  // const [showYes, setYesClicked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetfirmbyidQuery(location?.state?.id, {
    skip: location?.state?.id === undefined,
  });
  // const [trigger] = useLazyRegenerateadminpasswordQuery();

  // useEffect(() => {
  //   if (showYes) {
  //     setShowModal(false);
  //     getResult();
  //   }
  // }, [showYes]);

  // const getResult = async () => {
  //   const result = await trigger(data?.superAdmin?.email).unwrap();
  //   if (result?.isSuccessful) {
  //     toast.success("Password Regenerate Successfully!");
  //   } else {
  //     toast.error(result?.message);
  //   }
  // };

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: mvssuperadmin_landingpage,
      content: "Firm Details",
      active: false,
      state: { id: location?.state?.id, activeTab: location?.state?.activeTab },
    },
    {
      id: "item-2",
      link: mvssuperadmin_firmViewDetails,
      content: `Firm: --`,
      active: true,
      state: { id: location?.state?.id, activeTab: location?.state?.activeTab },
    },
  ]);

  useEffect(() => {
    if (data) {
      setItems([
        {
          id: "item-1",
          link: mvssuperadmin_landingpage,
          content: "Firm Details",
          active: false,
          state: {
            id: location?.state?.id,
            activeTab: location?.state?.activeTab,
          },
        },
        {
          id: "item-2",
          link: mvssuperadmin_firmViewDetails,
          content: `Firm: ${data?.firmName}`,
          active: true,
          state: {
            id: location?.state?.id,
            activeTab: location?.state?.activeTab,
          },
        },
      ]);
    }
  }, [data]);
  const handleEditPage = () => {
    navigate(mvssuperadmin_firmEditDetails, {
      state: {
        page: "firmeditdetailspage",
        id: location?.state?.id,
        activeTab: location?.state?.activeTab,
      },
    });
  };

  // const handleClickModalPopup = async () => {
  //   setShowModal(true);
  //   setYesClicked(false);
  // };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div className="firm-title-page-container" data-testid="firm-view-page">
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={`Firm: ${data?.firmName ? data?.firmName : " --"}`}
              hasButton={true}
              buttonText={"Edit"}
              width="90px"
              navigateTo={handleEditPage}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-sm-4 pt-3">
            <BreadCrumb items={items} />
            <div className="pt-2 pb-2">
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">Type of Firm</div>
                  <div className="firm-view-contant">
                    {data?.firmTypeName ? data?.firmTypeName : "--"}
                  </div>
                </div>
                <div className="firm-view-second px-lg-5">
                  <div className="firm-view-label">Contact Person</div>
                  <div className="firm-view-contant">
                    {data?.contactPerson ? data?.contactPerson : "--"}
                  </div>
                </div>
              </div>
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">Email</div>
                  <div className="firm-view-contant">
                    {data?.emailId ? data?.emailId : "--"}
                  </div>
                </div>
                <div className="firm-view-second px-lg-5">
                  <div className="firm-view-label">Mobile Phone</div>
                  <div className="firm-view-contant">
                    {data?.phoneNumber ? data?.phoneNumber : "--"}
                  </div>
                </div>
              </div>
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">Number of Employees</div>
                  <div className="firm-view-contant">
                    {data?.numberOfEmployees ? data?.numberOfEmployees : "--"}
                  </div>
                </div>
                <div className="firm-view-second px-lg-5">
                  <div className="firm-view-label">Company Address</div>
                  <div className="firm-view-contant">
                    {data?.address.addressLine2
                      ? `${data?.address.addressLine1}, ${data?.address.addressLine2}`
                      : data?.address.addressLine1? `${data?.address.addressLine1}` : "--"}
                  </div>
                </div>
              </div>
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">City</div>
                  <div className="firm-view-contant">
                    {data?.address.city ? data?.address.city : "--"}
                  </div>
                </div>
                <div className="firm-view-second px-lg-5">
                  <div className="firm-view-label">State</div>
                  <div className="firm-view-contant">
                    {data?.address.state ? data?.address.state : "--"}
                  </div>
                </div>
              </div>
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">Zip</div>
                  <div className="firm-view-contant">
                    {data?.address?.zip ? data?.address?.zip : "--"}
                  </div>
                </div>
                <div className="firm-view-second px-lg-5">
                  <div className="firm-view-label">Country</div>
                  <div className="firm-view-contant">
                    {data?.address?.country ? data?.address?.country : "--"}
                  </div>
                </div>
              </div>
            </div>

            <div className="divider-firm"></div>

            <div className="firm-super-admin-section mb-5">
              <div className="firm-super-admin-title pt-3 pb-3">
                Super Admin Details
              </div>
              <div className="d-none d-sm-block">
                <Table responsive className="custom-table-firm-superadmin">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile Phone</th>
                      {/* <th>Regenerate password</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        {data?.superAdmin?.firstName &&
                        data?.superAdmin?.lastName
                          ? `${data?.superAdmin?.firstName} ${data?.superAdmin?.lastName}`
                          : "--"}
                      </td>
                      <td>
                        {data?.superAdmin?.email
                          ? data?.superAdmin?.email
                          : "--"}
                      </td>
                      <td>
                        {data?.superAdmin?.phoneNumber
                          ? data?.superAdmin?.phoneNumber
                          : "--"}
                      </td>
                      {/* <td className="d-flex justify-content-center">
                        <div
                          className="regenerate-password-icon"
                          onClick={handleClickModalPopup}
                          data-testid="regenerate-password-icon-web"
                        >
                          {data ? <PwdIcon /> : "--"}
                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-block d-sm-none pb-3">
                <div className="pb-3">
                  <div className="firm-view-first">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-contant">
                      {data?.superAdmin?.firstName && data?.superAdmin?.lastName
                        ? `${data?.superAdmin?.firstName} ${data?.superAdmin?.lastName}`
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Email</div>
                    <div className="firm-view-contant">
                      {data?.superAdmin?.email ? data?.superAdmin?.email : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="firm-view-first">
                    <div className="firm-view-label">Mobile Phone</div>
                    <div className="firm-view-contant">
                      {data?.superAdmin?.phoneNumber
                        ? data?.superAdmin?.phoneNumber
                        : "--"}
                    </div>
                  </div>
                  {/* <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Regenerate password</div>
                    <div className="firm-view-contant px-5 mt-2">
                      <div
                        className="regenerate-password-icon"
                        data-testid="regenerate-password-icon-mobile"
                        onClick={handleClickModalPopup}
                      >
                        {data ? <PwdIcon /> : "--"}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* <ModalPopupType2
          setShowCallback={() => setShowModal(false)}
          setYesCallback={() => setYesClicked(true)}
          headerTitle="Regenerate Password"
          content={`Are you sure you want to regenerate the password for this Super
            Admin(${data?.superAdmin?.name}) account?`}
          showModal={showModal}
        /> */}
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default FirmDetailsViewPage;
