import React from "react";
import { Modal, Button} from "react-bootstrap";
import { confirmationMessage } from "../../constants/client/labels";

export default function BulkShareConfirmationPopup({
  showModal,
  setShowCallback,
  setSuccessCallback,
  formData
}) {

  const handleClose = () => {
    setShowCallback();
  };
  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="personal-use-modal"
        data-testid="bulk-share-confirmation-container"
      >
        <Modal.Header
          className="share-doc-modal-header receive-file-header"
          closeButton
        >
        </Modal.Header>
        <Modal.Body className="share-doc-modal-body">
        <div className="d-flex justify-content-center">
        <div className="px-3">
            <div className="text-align-center is-personal-use">
            <b>{confirmationMessage(formData.email, formData.name)}</b>
            </div>
            <div className="my-3 mb-4"><b> Are you sure you want to transfer these files ? </b></div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0 pb-4">
          <Button
            className="custom-button btn-standard doc-share-submit-btn"
            type="button"
            onClick={setSuccessCallback}
            data-testid="personal-use-submit-btn"
          >
            Yes
          </Button>
          <Button
            className="custom-button doc-share-submit-btn-nobg"
            type="button"
            onClick={handleClose}
            data-testid="personal-use-no-btn"
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
