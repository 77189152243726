import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  client_LandingPage,
  client_share_bank_details,
  client_upload_docs_external_user,
} from "../../constants/routes";
import { addemp } from "../../constants/firmdmin/labels";
import { validation } from "../../constants/firm/validationsMessages";
import {
  emailRules,
  alphabetRules,
  usphonNumberRules,
  indphonNumberRules,
  phoneNumberRules,
} from "../../constants/regExp";
import { formPlaceholder } from "../../constants/firm/labels";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ModalPopupType6 from "../../components/modal/ModalPopupType6";
import { useCreateExternalUserMutation } from "../../slices/userAuthManagementSlice";
import { clientModalMessage, clientTitle, shareBankDetails } from "../../constants/client/labels";
import { countryCodeList } from "../../constants/labels";

export default function ClientShareBankDetails() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shareDetailsData, setShareDetailsData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_share_bank_details,
      content: addemp,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);

  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: client_LandingPage,
        content: clientTitle,
        active: false,
      },
      {
        id: "item-2",
        link: client_share_bank_details,
        content: shareBankDetails,
        active: true,
        state: { id: "" },
      },
    ]);
  }, []);

  const schema = yup.object().shape({
    firstName: yup
      .string().trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string().trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [createExternalUser, { isLoading: createExternalUserLoading }] =
    useCreateExternalUserMutation();

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };

  const onSubmit = async (formData) => {
    const transformData = {
      role: "external_user",
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.email,
      phoneNumber: `+${formData.phone}`,
      createdBy: userInfo?.userId,
    };
    const data = JSON.stringify(transformData);
    setShareDetailsData(data);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      const res = await createExternalUser(shareDetailsData).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        navigate(client_upload_docs_external_user, {
          state: {
            res: res,
          },
        });
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const handleCancelNavigation = () => {
    navigate(client_LandingPage);
  };

  const handleCancel = () => {
    setShowModal(false);
    navigate(client_share_bank_details);
  };

  return (
    <>
      <div className="superadmin-container client-share-bank-details h-100">
        <Container fluid className="px-lg-4 pb-2 h-100">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="client-share-bank-details"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={shareBankDetails}
            />
          </div>
          <div className="firm-page-outer-container firm-page-add-outer-container">
            <div className="firm-page-container unset-height employee-form-container">
              <Form
                className="custom-form employee-form px-2 px-lg-4 px-md-4 type-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col className="custom-sub-title pt-2">
                    Please provide the details of the person to whom the bank
                    details are being shared
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} className="mt-4">
                    <Row>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="ext-firstname"
                            className="required mb-0"
                          >
                            {formPlaceholder.firstName}
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            maxLength={30}
                            {...register("firstName")}
                            isInvalid={!!errors.firstName}
                            data-testid="ext-firstname"
                            id="ext-firstname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.firstName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={6} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label
                            htmlFor="ext-lastname"
                            className="required mb-0"
                          >
                            {formPlaceholder.lastName}
                          </Form.Label>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="lastName"
                            maxLength={30}
                            autoComplete="off"
                            {...register("lastName")}
                            isInvalid={!!errors.lastName}
                            data-testid="ext-lastname"
                            id="ext-lastname"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.lastName?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="ext-email" className="required mb-0">
                        {formPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur = {(e) => e.target.value = e.target.value.trim()}
                        data-testid="ext-email"
                        id="ext-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="" className="required mb-0">
                        {formPlaceholder.phone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        autoComplete="off"
                        {...register("phone", {
                          required: true,
                        })}
                        isInvalid={!!errors.phone}
                        data-testid="ext-phone"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center mt-lg-2 mt-md-2">
                  <Button
                    className="custom-button btn-nobg mx-2"
                    data-testid="cancel-button"
                    onClick={handleCancelNavigation}
                  >
                    {formPlaceholder.cancelBtnText}
                  </Button>
                  <Button
                    type="submit"
                    className="custom-button btn-standard mx-2"
                    data-testid="submit-button"
                  >
                    {formPlaceholder.submitBtnText}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
        <ModalPopupType6
          setShowCallback={handleCancel}
          setHideCallback={() => setShowModal(false)}
          setYesCallback={handleConfirm}
          content={clientModalMessage}
          showModal={showModal}
        />
      </div>
      {createExternalUserLoading && <Loader />}
    </>
  );
}
