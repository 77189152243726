import React, { useState, useRef, useEffect } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  firmsuperadmin_Changepassword,
  firmsuperadmin_LandingPage,
  firmsuperadmin_ReportsPage,
  login,
} from "../../constants/routes";
import { ChevronDown, X } from "react-bootstrap-icons";
import usersIcon from "../../assets/images/users-icon.png";
import { ReactComponent as PasswordIcon } from "../../assets/images/change_pwd_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/report-icon.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  changePassword,
  logout,
  reports,
  employeeManagementLabel,
  clientManagementLabel,
} from "../../constants/labels";
import {} from "../../constants/routes";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";

export default function HeaderProfileFirmAdmin() {
  const dispatch = useDispatch();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const reqType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );

  const [collapse, setCollapese] = useState(false);
  const [show, setShow] = useState(false);
  const [activeNav, setActiveName] = useState("empmanagement");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  let media = "lg";
  const location = useLocation();
  const [labelToShow, setLabelToShow] = useState(userInfo?.firmTypeId === 1 ? employeeManagementLabel : clientManagementLabel);
  const ssoResponse  =  sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse)
  const isSSOLogin = ssoResponse ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful : false 

  useEffect(() => {
    const res = userInfo?.firmTypeId;
    if (userInfo?.firmTypeId) {
      if (res === 1) {
        setLabelToShow(employeeManagementLabel);
      } else if (res === 2) {
        setLabelToShow(clientManagementLabel);
      }
    }
    if(identifierID && reqType && (reqType === "addProf")){
      setLabelToShow(employeeManagementLabel);
    }
  }, [identifierID, userInfo, reqType]);

  useEffect(() => {
    if (location?.pathname.includes("reports")) {
      setActiveName("firmadminreports");
    } else {
      setActiveName("empmanagement");
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      //console.log(res);
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      //console.error(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChangePassword = () => {
    setCollapese((collapse) => !collapse);
    navigate(firmsuperadmin_Changepassword);
    const firmType = userInfo?.firmTypeId;

    firmType === 1 && ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `Change Attorney - ${ROLE.firmsuperadmin} - 3 Level`,
      label: `Change Attorney - ${ROLE.firmsuperadmin} - 3 Level`,
    });

    firmType === 2 && ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 2 Level`,
      action: `Change Attorney - ${ROLE.firmsuperadmin} - 2 Level`,
      label: `Change Attorney - ${ROLE.firmsuperadmin} - 2 Level`,
    });
  };

  const handleLogoNavigation = () => {
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
    if (userInfo?.role === ROLE.firmsuperadmin) {
      navigate(firmsuperadmin_LandingPage, {
        state: {
          id: userInfo?.firmTypeId,
        },
      });
    } else {
      navigate(login);
    }
  };

  const handleNavLinkNavigation = () => {
    setActiveName("empmanagement");
    setShow(false);
    const user = sessionStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
    if (userInfo?.role === ROLE.firmsuperadmin) {
      navigate(firmsuperadmin_LandingPage, {
        state: {
          id: userInfo?.firmTypeId,
        },
      });
    } else {
      navigate(login);
    }
  };

  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <Navbar.Toggle
              onClick={() => setShow(true)}
              aria-controls={`offcanvasNavbar-expand-${media}`}
              className="navbar-toggle-bg-icon p-0 me-2"
              data-testid="nav-bar-toggle"
            />
            <div
              className="cursor-point"
              onClick={() => handleLogoNavigation()}
              data-testid="header-profile-logo"
            >
              <HeaderLogo />
            </div>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${media}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${media}`}
            placement="start"
            className="sidebar-mb"
            show={show}
            data-testid="nav-bar-offcanvas"
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header className="header-line position-relative">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${media}`}
                className="d-flex"
              >
                <HeaderLogo />
                <span
                  data-testid="offcanvas-close-icon"
                  onClick={() => setShow(false)}
                >
                  <X
                    size={28}
                    color="#fff"
                    className="position-absolute close-icon"
                  />
                </span>
              </Offcanvas.Title>
              <span className="offcanvas-header-pos"></span>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="bg-color offcanvas-body-flex"
              data-testid="offcanvas-body"
            >
              <div>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link
                    data-testid="firm-admin-emp-management-link"
                    onClick={() => handleNavLinkNavigation()}
                    className={`custom-nav-link pe-lg-3 ps-md-3 ${
                      activeNav === "empmanagement" && "active"
                    }`}
                  >
                    <span className="txt-color">
                      <img src={usersIcon} height={24} width={24} alt="" />{" "}
                      {labelToShow}
                    </span>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to={firmsuperadmin_ReportsPage}
                    data-testid="reports-link"
                    onClick={() => {
                      setActiveName("firmadminreports");
                      setShow(false);
                    }}
                    className={`custom-nav-link ps-lg-3 ps-md-3 ${
                      activeNav === "firmadminreports" && "active"
                    }`}
                  >
                   <span className="txt-color">
                      <ReportIcon /> {reports}
                    </span>
                  </Nav.Link>
                </Nav>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={dropdownRef}>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              { 
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    {!isSSOLogin &&
                    <li
                      onClick={handleChangePassword}
                      data-testid="change-password"
                    >
                      <PasswordIcon />
                      <span className="mx-2">{changePassword}</span>
                    </li>
                    }
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {isLoading && <Loader />}
    </>
  );
}
