import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRules } from "../../../constants/regExp";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { validation } from "../../../constants/firm/validationsMessages";
import { formPlaceholder } from "../../../constants/firm/labels";
import { useLazyGetAttorneyByEmailQuery } from "../../../slices/userAuthManagementSlice";
import { useReassignProfessionalMutation } from "../../../slices/superAdminApiManagementSlice";
import {
  professionalnotFound,
} from "../../../constants/firmdmin/labels";

export default function ReassignClientsForm({
  setSuccessModal,
  selectedClients,
  currentProfessionEmail
}) {
  const [professionalData, setProfessionalData] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const schema = yup.object().shape({
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`)
      .test({
        name: "new-professional-email",
        skipAbsent: true,
        test: (value, ctx) => {
          if(currentProfessionEmail!==null && (value === currentProfessionEmail)){
            return ctx.createError({
              message: validation.currentProfessional.message,
            });
          }
          return true;
        }
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [trigger, { isLoading }] = useLazyGetAttorneyByEmailQuery();
  const [reassignProfessional, { isLoading: reassignProfessionalLoading }] =
    useReassignProfessionalMutation();

  const onSubmit = async (formData) => {
    try {
      if (professionalData === null) {
        const res = await trigger({
          email: formData.email,
          firmId: userInfo?.firmId,
        }).unwrap();
        if(res?.data && res?.data?.length>0){
          setProfessionalData(res.data[0])
          toast.success(res?.message);
        }else{
          toast.error(res?.errorMessage)
        }
      } else {
        const transformData = {
          newProfessionalUserId: professionalData?.attorneyId,
          clientUserIds: selectedClients,
        };
        const data = JSON.stringify(transformData);
        const res = await reassignProfessional(data).unwrap();
        if (res?.isSuccessful) {
          setSuccessModal(true);
        } else {
          toast.error(res?.errorMessage);
        }
      }
    } catch (err) {
      if (err?.status === 404) {
        toast.error(professionalnotFound);
      } else {
        toast.error(err?.errorMessage);
      }
    }
  };
  const handleBackButton = () => {
    setProfessionalData(null);    
    setValue("email", "");
  };
  return (
    <>
      <div>
        <div>
          <Form
            className="custom-form w-100 type-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input input-border-radius-unset"
                type="email"
                name="email"
                placeholder={formPlaceholder.enterNewProfessional}
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur = {(e) => e.target.value = e.target.value.trim()}
                data-testid="firm-email"
                id="firm-email"
                disabled={professionalData === null?  false:true}
              />

              <Form.Control.Feedback
                data-testid="invalid-feedback"
                type="invalid"
              >
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {professionalData && Object.keys(professionalData).length > 0 && (
              <div className="employ-data-title d-flex mb-2">
                New Professional Details
              </div>
            )}
            {professionalData && Object.keys(professionalData).length > 0 && (
              <div className="overflow-div">
                <div>
                  <div className="attorney-details d-flex pb-2">
                    <div className="d-flex flex-column align-items-start attorney-text">
                      <div>
                        <span title={professionalData?.fullName}>
                          Name: {professionalData?.fullName}
                        </span>
                      </div>
                      <div>
                        <span>Mobile phone: {professionalData?.phone}</span>
                      </div>
                      <div>
                        <span title={professionalData?.email}>
                          Email: {professionalData?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center mt-4">
              {professionalData && Object.keys(professionalData).length > 0 && (
                <Button
                  className="custom-button btn-nobg mx-2"
                  data-testid="cancel-button"
                  onClick={handleBackButton}
                >
                  {formPlaceholder.backButton}
                </Button>
              )}
              <Button
                type="submit"
                className="custom-button btn-standard"
                data-testid="submit-button"
              >
                {formPlaceholder.submitBtnText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {(isLoading || reassignProfessionalLoading) && <Loader />}
    </>
  );
}
