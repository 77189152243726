import React from "react";
import { Container, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useGetStakeHolderDetailsQuery } from "../../../slices/stackHolderApiManagmentSlice";
import {viewpageLabel} from "../../../constants/client/labels";
export default function ViewSuccessorDetails() {
  const location = useLocation();
  const { data, error, isLoading } = useGetStakeHolderDetailsQuery(
    location?.state?.id?.doc?.userId,
    {
      refetchOnMountOrArgChange: true,
      skip: location?.state?.id === undefined,
    }
  );
  const dateString = data?.successor?.dateOfBirth || "";
  let formattedDate = "";
  if (dateString) {
    // const date = new Date(dateString);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // formattedDate = `${month < 10 ? "0" : ""}${month}-${
    //   day < 10 ? "0" : ""
    // }${day}-${year}`;
    formattedDate = dateString.split('T')[0]
  }

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div className="firm-page-outer-container" data-testid="attorney-view-successor-page">
        <Container fluid className="px-lg-4">
          <div className="firm-page-container attorney-view-page employee-form-container px-lg-4 px-3">
            <div className="employee-view-container pt-3">
              <div className="view-details">
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">{viewpageLabel.name}</div>
                    <div className="firm-view-contant">
                      {data?.successor?.name ? data?.successor?.name : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">{viewpageLabel.dob}</div>
                    <div className="firm-view-contant">
                      {data?.successor?.dateOfBirth ? formattedDate : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">{viewpageLabel.email}</div>
                    <div className="firm-view-contant">
                      {data?.successor?.emailId ? data?.successor?.emailId : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">{viewpageLabel.mobilePhone}</div>
                    <div className="firm-view-contant">
                      {data?.successor?.phoneNumber
                        ? data?.successor?.phoneNumber
                        : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">{viewpageLabel.driversLicPassport}</div>
                    <div className="firm-view-contant">
                      {
                      data?.successor?.drivingLicense
                        ? data?.successor?.drivingLicense
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">{viewpageLabel.address}</div>
                    <div className="firm-view-contant">
                    {
                      data?.successor?.address?.addressLine2
                        ? `${data?.successor?.address?.addressLine1}, ${data?.successor?.address?.addressLine2}`
                        : data?.successor?.address?.addressLine1 ? `${data?.successor?.address?.addressLine1}`: "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">{viewpageLabel.zip}</div>
                    <div className="firm-view-contant">
                    {data?.successor?.address?.zip
                        ? data?.successor?.address?.zip
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">{viewpageLabel.city}</div>
                    <div className="firm-view-contant">
                    {data?.successor?.address?.city
                        ? data?.successor?.address?.city
                        : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">{viewpageLabel.state}</div>
                    <div className="firm-view-contant">
                      {data?.successor?.address?.state
                        ? data?.successor?.address?.state
                        : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">{viewpageLabel.country}</div>
                    <div className="firm-view-contant">
                    {data?.successor?.address?.country
                        ? data?.successor?.address?.country
                        : "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}
