import { personalUseApiSlice } from "./personalUseApiSlice";

export const personalUseApiManagementSlice = personalUseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getAllDocs: builder.query({
        query: (userId) => ({
          url: `/docs?userId=${userId}`,
          method: "GET",
        }),
      }),
      updatePersonalDocs: builder.mutation({
        query: ({userId,docIds}) => ({  
          url: `/docs?userId=${userId}`,
          method: "PUT",
          body: docIds
        }),
      })
    }),
  });

export const {
  useGetAllDocsQuery,
  useUpdatePersonalDocsMutation
} = personalUseApiManagementSlice;
