import { documentApiManagement } from "./documentApiManagement";
import { firmApiManagement } from "./firmApiManagement";
import { userApiSlice } from "./userApiSlice";

export const superadminApiManagementSlice = userApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteAttorney: builder.mutation({
      query: (attorneyId) => ({
        url: `/${attorneyId}`,
        method: "DELETE",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          firmApiManagement.util.invalidateTags(["getAllAttorneyDetails"])
        );
      },
      invalidatesTags: ["getAllAttorneyDetails"],
    }),
    regenerateadminpassword: builder.query({
      query: (username) => ({
        url: `/regenerateAdminPassword`,
        method: "GET",
        headers: {
          Username: `${username}`,
        },
      }),
    }),
    createEmployee: builder.mutation({
      query: (data) => ({
        url: "/attorneys",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getEmployeebyid", "getAllAttorneyDetails"],
    }),
    editEmployee: builder.mutation({
      query: ({ attorneyId, data }) => ({
        url: `/attorneys/${attorneyId}`,
        method: "PUT",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
        );
      },
      invalidatesTags: ["getEmployeebyid", "getAllAttorneyDetails"],
    }),
    getEmployee: builder.query({
      query: (userId) => ({
        url: `/attorneys/${userId}`,
        method: "GET",
      }),
      providesTags: ["getEmployeebyid"],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: "/changePassword",
        method: "POST",
        body: data,
      }),
    }),
    reassignProfessional: builder.mutation({
      query: (data) => ({
        url: "reassign-attorney",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getEmployeebyid", "getAllAttorneyDetails"],
    }),
  }),
});

export const {
  useLazyRegenerateadminpasswordQuery,
  useDeleteAttorneyMutation,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useChangePasswordMutation,
  useReassignProfessionalMutation,
  useLazyGetEmployeeQuery
} = superadminApiManagementSlice;
