import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { signOutApiCall } from "./authSlice";
import { toast } from "react-toastify";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL_PROBATESERVICE}/api/v1`,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    headers.set("Access-Control-Allow-Credentials", "true");
    const token = getState()?.auth?.userInfo?.accessToken;
       if (token) {
          headers.set("Authorization", `Bearer ${token}`);
       }
    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(signOutApiCall());
    toast.error(`Signed out successfully due to token expiration!`);
  }
  return result;
};

export const noClaimApiSlice = createApi({
  reducerPath: "noClaimSlice",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["NoClaim"],
  endpoints: (builder) => ({}),
});
