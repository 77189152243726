import React, { useState, useRef, useEffect } from "react";
import { Navbar, Offcanvas } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import {
  executor_LandingPage,
  executor_Changepassword,
  executor_clientProfile
} from "../../constants/routes";
import { ChevronDown, X } from "react-bootstrap-icons";
import { ReactComponent as PasswordIcon } from "../../assets/images/change_pwd_icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { ReactComponent as EditProfile } from "../../assets/images/edit-profile.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {
  changePassword,
  logout,
  memberProfile
} from "../../constants/labels";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import ClientOffCanvasEditComponent from "../editProfileOffCanvas/ClientOffCanvasEditComponent";
import ModalPopup from "../../components/modal/ModalPopup";
import SuccessModalContent from "../../components/widgets/SuccessModalContent";
import { firmAttorneylabel } from "../../constants/firm/labels";

export default function HeaderProfileExecutor() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const [show, setShow] = useState(false);
  const [visible, showVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [showSuccessModal, setSuccessModal] = useState(false);
  let media = "lg";
  const ssoResponse  =  sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse)
  const isSSOLogin = ssoResponse ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful : false
  const handleClose = () => showVisible(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleChangePassword = () => {
    setCollapese((collapse) => !collapse);
    navigate(executor_Changepassword);
  };

  const handleEditProfile = () => {
    if (window.innerWidth >= 768) {
      setCollapese((collapse) => !collapse);
      showVisible(true);
    } else {
      setCollapese((collapse) => !collapse);
      navigate(executor_clientProfile, {
        state: {
          page: "executorPage",
          id: userInfo?.clientId,
          pathname: location?.pathname,
          state: location?.state
        },
      });
    }
  };

  const handleLogoNavigation = () => {
    navigate(executor_LandingPage);
  };
  const handlePrevPage = () => {
    setSuccessModal(false);
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <div
              onClick={() => handleLogoNavigation()}
              data-testid="header-profile-logo"
              className="cursor-point"
            >
              <HeaderLogo />
            </div>
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${media}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${media}`}
            placement="start"
            className="sidebar-mb"
            show={show}
            data-testid="nav-bar-offcanvas"
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header className="header-line position-relative">
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${media}`}
                className="d-flex"
              >
                <HeaderLogo />
                <span
                  data-testid="offcanvas-close-icon"
                  onClick={() => setShow(false)}
                >
                  <X
                    size={28}
                    color="#fff"
                    className="position-absolute close-icon"
                  />
                </span>
              </Offcanvas.Title>
              <span className="offcanvas-header-pos"></span>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="bg-color offcanvas-body-flex"
              data-testid="offcanvas-body-attorney"
            ></Offcanvas.Body>
          </Navbar.Offcanvas>

          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={dropdownRef}>
              <div>
                <span></span>
                <span></span>
              </div>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    {/* <li className="disabled">
                      <span className="mx-2">{myProfile}</span>
                    </li> */}
                    {!isSSOLogin && <li onClick={handleEditProfile} data-testid="edit-profile">
                      <EditProfile />
                      <span className="mx-2">{memberProfile}</span>
                    </li>}
                    {!isSSOLogin && <li
                      onClick={handleChangePassword}
                      data-testid="change-password"
                    >
                      <PasswordIcon />
                      <span className="mx-2">{changePassword}</span>
                    </li>}
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {isLoading && <Loader />}
      {
        visible && 
        <ClientOffCanvasEditComponent
        visible={visible}
          onClose={handleClose}
          state={{
            page: "executorPage",
            id: userInfo?.clientId,
          }}
         />
      }
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={firmAttorneylabel.changeRequest} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handlePrevPage}
        animation={false}
      />
      {isLoading && <Loader />}
    </>
  );
}
