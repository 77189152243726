import { Container } from "react-bootstrap";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";
import Loader from "../../components/Loader/Loader";
import { useGetNoClaimQuery } from "../../slices/noClaimApiManagementSlice";
import { useNavigate } from "react-router-dom";
import { defaultRoute } from "../../constants/routes";

export default function NoClaim() {
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  const {data, isLoading, error}= useGetNoClaimQuery(identifierID)
  const message= data?.message || data || error?.message ||  error?.data?.message || error?.error || error
  const navigate = useNavigate();
return (
  identifierID ? (
    <>
    <div
      className="attorney-client-title-page-container px-lg-4 px-xl-4"
      data-testid="death-claim-title"
    >
      <Container fluid>
        <div className="attoner-client-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
          <EmployeeTitleBar
            items={false}
            hasButton={false}
            title="Member Death Confirmation"
          />
        </div>
      </Container>
    </div>
    <div className="firm-page-outer-container px-lg-4 px-xl-4">
      <Container fluid>
      <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
            <div className="deathclaim-message-container px-lg-2 px-md-2 px-sm-2 py-4">
              <span className="info-blue mx-3">
                <InfoIcon />
              </span>
              <div
                className="question-container"
                data-testid="deathclaim-view-page"
              >
                {message}
              </div>
            </div>
          </div>
        </Container>
        </div>
        {isLoading && <Loader/>}
    </>
  ):(
    navigate(defaultRoute)
  )
)

}