import React from "react";
import {
  Offcanvas,
  Button,
  ButtonGroup,
} from "react-bootstrap";
export default function ClientNotificationCanvas({ visible, onClose, state, notification, setUserNotification }) {
  return (
    <>
      <Offcanvas
        className="offcanvas-notification"
        show={visible}
        onHide={onClose}
        placement="end"
        data-testid="notification-status-canvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="notification-title">Notification Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="px-3">
          <div className="divider-firm"></div>
        </div>
        <Offcanvas.Body>
          <div className="notification-msg px-3 pt-4">Do you want to turn on notifications?</div>
          <div className="notification-msg px-3 pt-2"><b>Note: </b>Turning this off will not affect mail notifications</div>
          <div className="pt-4 d-flex justify-content-center">
            <ButtonGroup>
              <Button className={notification ?"btn-yes" : "btn-no"} data-testid="btn-notification-yes" onClick={() => setUserNotification(true)}>Yes</Button>
              <Button className={notification ?"btn-no" : "btn-yes"} data-testid="btn-notification-no" onClick={() => setUserNotification(false)}>No</Button>
            </ButtonGroup>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
