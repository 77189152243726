import {
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import { ReactComponent as Share } from "../../assets/images/sharedocument.svg";
import { reportPlaceholder } from "../../constants/firm/labels";
import AgGrid from "../table/AgGrid";
import {
  useDownloadFirmDetailsReportMutation,
  useGetFirmReportDetailsQuery,
} from "../../slices/reportApiManagementSlice";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import ShareReportModalPopup from "../modal/ShareReportModalPopup";

export default function FirmDetailsReport() {
  const [selectedFilter, setSelectedFilter] = useState("Firms Enrolled");
  const [selectedTile, setSelectedTile] = useState("Firms Enrolled");
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [filteredDataCounts, setFilteredDataCounts] = useState({});
  const [willsUploadedCount, setWillsUploadedCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");

  let empnameWidth = 250;
  let empnameMinWidth = 250;

  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }

  const parseDate = (params) => {
    if (!params.value) return "";

    const dateObj = new Date(params.value);

    if (isNaN(dateObj.getTime())) {
      return "Invalid Date";
    }

    const dateString = dateObj.toISOString().split("T")[0];
    return dateString;
  };

  const { data, isLoading } = useGetFirmReportDetailsQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      setRowData(data);
      const initialCounts = {
        "Firms Enrolled": data.length,
        "3 Tier Firms": data.filter((item) => item.firmType === "3 Tier Firm")
          .length,
        "2 Tier Firms": data.filter((item) => item.firmType === "2 Tier Firm")
          .length,
        "Total Wills Uploaded": data.length,
      };
      setFilteredDataCounts(initialCounts);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      let count = 0;
      for (let i = 0; i < data?.length; i++) {
        count += data[i]?.willsUploaded;
      }
      setWillsUploadedCount(count);
    }
  }, [data]);

  const handleFilterClick = (filterType, tileRef) => {
    if (data) {
      setSelectedFilter(filterType);
      setSelectedTile(tileRef);

      let filteredData = [];

      if (filterType === "Firms Enrolled") {
        filteredData = data;
      } else if (filterType === "Total Wills Uploaded") {
        filteredData = [...data]
        .filter((item) => item.willsUploaded > 0)
        .sort(
          (a, b) => b.willsUploaded - a.willsUploaded
        );
      } else {
        filteredData = data.filter((item) => item.firmType === filterType);
      }

      setRowData(filteredData);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const GridFirmName = (params) => {
    const { value } = params;
    const firmName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    return (
      <>
        {value.length > 20 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
          >
            <div className="truncate-tooltip grid-firm-email-anchor">
              {firmName}
            </div>
          </OverlayTrigger>
        ) : (
          <div className="truncate-tooltip grid-firm-email-anchor">
            {firmName}
          </div>
        )}
      </>
    );
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const [columnDefs] = useState([
    {
      headerName: reportPlaceholder.firmName,
      field: "firmName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFirmName,
    },
    {
      headerName: reportPlaceholder.firmType,
      minWidth: 150,
      field: "firmType",
    },
    {
      headerName: reportPlaceholder.employeeSize,
      minWidth: 150,
      field: "employeeSize",
    },
    {
      headerName: reportPlaceholder.totalAccount,
      minWidth: 150,
      field: "totalAccount",
    },
    {
      headerName: reportPlaceholder.willsUploaded,
      minWidth: 150,
      field: "willsUploaded",
    },
    {
      headerName: reportPlaceholder.activationDate,
      minWidth: 150,
      field: "activationDate",
      cellRenderer: parseDate,
      sortable: true,
      unSortIcon: true,
    },
    {
      headerName: reportPlaceholder.expiryDate,
      minWidth: 150,
      field: "expiryDate",
      cellRenderer: parseDate,
      sortable: true,
      unSortIcon: true,
    },
  ]);
  const headerRow = [
    {
      firmName:reportPlaceholder.firmName,
      firmType:reportPlaceholder.firmType,
      employeeSize:reportPlaceholder.employeeSize,
      totalAccount:reportPlaceholder.totalAccount,
      willsUploaded:reportPlaceholder.willsUploaded,
      activationDate:reportPlaceholder.activationDate,
      expiryDate:reportPlaceholder.expiryDate
    }
  ]
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 150,
    };
  }, []);

  const paginationSizeLimit = 10;

  const customFilter = useMemo(() => {
    if (!searchText) return rowData;

    return rowData.filter((item) => {
      return (
        item.firmName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.firmType?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.employeeSize
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.totalAccount
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.willsUploaded
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        parseDate({ value: item.activationDate })
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        parseDate({ value: item.expiryDate })
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }, [searchText, rowData]);
  
  const [triggerDownload, { isLoading: download }] =
    useDownloadFirmDetailsReportMutation();

  const handleShare = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const fileName = `Firm Details - ${selectedFilter}`;
    setFileName(fileName);
  }, [selectedFilter]);

  const filteredFields = customFilter.map((item) => ({
    ...item,
    activationDate: parseDate({ value: item.activationDate }),
    expiryDate: parseDate({ value: item.expiryDate }),
  }))
  .map(({ firmId, ...rest}) => rest);
  
  const handleDownload = async (type) => {
    if (filteredFields) {
      let reportTitle;
      let fileType = type.toLowerCase();
      let fileName = `Firm_Details_Report.${fileType}`;

      if (selectedFilter === "Total Wills Uploaded") {
        reportTitle = `Firm Details Report - ${selectedFilter} - ${willsUploadedCount}`;
      } else {
        reportTitle = `Firm Details Report - ${selectedFilter} - ${filteredDataCounts[selectedFilter]}`;
      }

      try {
        const result = await triggerDownload({
          reportTitle,
          data: filteredFields,
          type: fileType,
        }).unwrap();
        const url = window.URL.createObjectURL(new Blob([result]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        toast.success(`Report downloaded successfully.`);
      } catch (error) {
        toast.error(
          error?.data?.message ||
            error?.message ||
            "Failed to download the file. Please try again."
        );
      }
    } else {
      toast.error("No data available for download.");
    }
  };

  return (
    <>
      <div
        className="px-3 px-lg-5 firm-reports-page-container"
        data-testid="firm-details-reports-page"
      >
        <div className="firm-reports-second-div">
          <div className="py-3 d-flex justify-content-center justify-content-space-between gap-4 flex-wrap d-block d-lg-none">
            <Container>
              <Row className="justify-content-center">
                <Col className="p-3" lg={2} md={6} sm={12}>
                  <div
                    className={`pt-2 bg-blue firm-report-tiles clickable-tile ${
                      selectedTile === "Firms Enrolled" && "selected-tile-blue"
                    }`}
                    onClick={(e) =>
                      handleFilterClick("Firms Enrolled", "Firms Enrolled")
                    }
                    data-testid="firm-enrolled"
                  >
                    <div className="px-2 firm-reports-tiles firm-titles-header">
                      {reportPlaceholder.firmEnrolled}
                    </div>
                    <div className="px-2 firm-titles-value">
                      {filteredDataCounts["Firms Enrolled"] || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={6} sm={12}>
                  <div
                    className={`pt-2 bg-grey firm-report-tiles clickable-tile ${
                      selectedTile === "3 Tier Firms" && "selected-tile-grey"
                    }`}
                    onClick={(e) =>
                      handleFilterClick("3 Tier Firm", "3 Tier Firms")
                    }
                    data-testid="three-tier-firm"
                  >
                    <div className="px-2 firm-reports-tiles firm-titles-header">
                      {reportPlaceholder.threeTierFirm}
                    </div>
                    <div className="px-2 firm-titles-value">
                      {filteredDataCounts["3 Tier Firms"] || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={6} sm={12}>
                  <div
                    className={`pt-2 bg-red firm-report-tiles clickable-tile ${
                      selectedTile === "2 Tier Firms" && "selected-tile-red"
                    }`}
                    onClick={(e) =>
                      handleFilterClick("2 Tier Firm", "2 Tier Firms")
                    }
                    data-testid="two-tier-firm"
                  >
                    <div className="px-2 firm-reports-tiles firm-titles-header">
                      {reportPlaceholder.twoTierFirm}
                    </div>
                    <div className="px-2 firm-titles-value">
                      {filteredDataCounts["2 Tier Firms"] || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={6} sm={12}>
                  <div
                    className={`pt-2 bg-green firm-report-tiles clickable-tile ${
                      selectedTile === "Total Wills Uploaded" &&
                      "selected-tile-green"
                    }`}
                    onClick={(e) =>
                      handleFilterClick(
                        "Total Wills Uploaded",
                        "Total Wills Uploaded"
                      )
                    }
                    data-testid="total-wills-uploaded"
                  >
                    <div className="px-2 firm-reports-tiles firm-titles-header">
                      {reportPlaceholder.totalWillsUploaded}
                    </div>
                    <div className="px-2 firm-titles-value">
                      {willsUploadedCount || 0}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-none d-lg-block">
            <div className="py-3 px-3 d-flex justify-content-center justify-content-space-between gap-4 flex-wrap">
              <div
                className={`pt-2 bg-blue firm-report-tiles clickable-tile ${
                  selectedTile === "Firms Enrolled" && "selected-tile-blue"
                }`}
                onClick={(e) =>
                  handleFilterClick("Firms Enrolled", "Firms Enrolled")
                }
                data-testid="firm-enrolled"
              >
                <div className="px-2 firm-reports-tiles firm-titles-header">
                  {reportPlaceholder.firmEnrolled}
                </div>
                <div className="px-2 firm-titles-value">
                  {filteredDataCounts["Firms Enrolled"] || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-grey firm-report-tiles clickable-tile ${
                  selectedTile === "3 Tier Firms" && "selected-tile-grey"
                }`}
                onClick={(e) => handleFilterClick("3 Tier Firm", "3 Tier Firms")}
                data-testid="three-tier-firm"
              >
                <div className="px-2 firm-reports-tiles firm-titles-header">
                  {reportPlaceholder.threeTierFirm}
                </div>
                <div className="px-2 firm-titles-value">
                  {filteredDataCounts["3 Tier Firms"] || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-red firm-report-tiles clickable-tile ${
                  selectedTile === "2 Tier Firms" && "selected-tile-red"
                }`}
                onClick={(e) => handleFilterClick("2 Tier Firm", "2 Tier Firms")}
                data-testid="two-tier-firm"
              >
                <div className="px-2 firm-reports-tiles firm-titles-header">
                  {reportPlaceholder.twoTierFirm}
                </div>
                <div className="px-2 firm-titles-value">
                  {filteredDataCounts["2 Tier Firms"] || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-green firm-report-tiles clickable-tile ${
                  selectedTile === "Total Wills Uploaded" &&
                  "selected-tile-green"
                }`}
                onClick={(e) =>
                  handleFilterClick(
                    "Total Wills Uploaded",
                    "Total Wills Uploaded"
                  )
                }
                data-testid="total-wills-uploaded"
              >
                <div className="px-2 firm-reports-tiles firm-titles-header">
                  {reportPlaceholder.totalWillsUploaded}
                </div>
                <div className="px-2 firm-titles-value">
                  {willsUploadedCount || 0}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 search-container d-block d-sm-none">
            <div className="search-icon">
              <AGSearchIcon />
            </div>
            <div className="search-input-field ">
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                autoComplete="off"
                onChange={handleSearchChange}
                id="search-firm-input-field"
                data-testid="grid-search-input"
              />
            </div>
            {searchText && (
              <div
                onClick={handleClearSearch}
                className="clear-search-button-mb"
              >
                <SearchXIcon />
              </div>
            )}
          </div>

          <div className="firm-reports-second-div py-2">
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div className="firm-reports-table-title d-flex">
                {selectedTile}
              </div>
              <div className="d-flex gap-3">
                <div className="d-flex">
                  <div className="search-container d-none d-md-block">
                    <div className="search-icon">
                      <AGSearchIcon />
                    </div>
                    <div className="search-input-field flex-1">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        autoComplete="off"
                        onChange={handleSearchChange}
                        id="search-firm-input-field"
                        data-testid="grid-search-input"
                      />
                    </div>
                    {searchText && (
                      <div
                        onClick={handleClearSearch}
                        className="clear-search-button"
                      >
                        <SearchXIcon />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div>
                    <Dropdown>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="tooltip">Download</Tooltip>
                        }
                      >
                        <Dropdown.Toggle
                          as="div"
                          className="ms-2 cursor-pointer"
                          data-testid="download"
                        >
                          <Download />
                        </Dropdown.Toggle>
                      </OverlayTrigger>
                      <Dropdown.Menu align="right">
                        <Dropdown.Item onClick={() => handleDownload("PDF")}>
                          {reportPlaceholder.downloadAsPDF}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("CSV")}>
                          {reportPlaceholder.downloadAsCSV}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="tooltip">Share</Tooltip>}
                    >
                      <div className="ms-3 cursor-pointer" data-testid="share">
                        <Share onClick={handleShare} />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2 px-3">
            <AgGrid
              quickFilterText={searchText}
              rowData={customFilter}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowStyle={getRowStyle}
              paginationSizeLimit={paginationSizeLimit}
              rowSelection={"multiple"}
            />
            {(isLoading || download) && <Loader data-testid="loader" />}
          </div>
        </div>
      </div>
      <ShareReportModalPopup
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        bodyData={[...headerRow, ...filteredFields]}
        shareFileName={fileName}
      />
    </>
  );
}
