import React from "react";
import OnBoardingFirmForm from "../../components/form/firm/OnBoardingFirmForm";
import { Container, Row, Col } from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import {
  mvssuperadmin_firmOnboardingForm,
  mvssuperadmin_landingpage,
} from "../../constants/routes";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import { firmDetailsLabel, firmOnboardingTitle } from "../../constants/labels";

export default function FirmOnboardingForm() {
  const items = [
    {
      id: "item-1",
      link: mvssuperadmin_landingpage,
      content: firmDetailsLabel,
      active: false,
    },
    {
      id: "item-2",
      link: mvssuperadmin_firmOnboardingForm,
      content: firmOnboardingTitle,
      active: true,
    },
  ];
  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="firm-onboarding-page"
      >
        <Container fluid data-testid="onboarding-firm-form">
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={firmOnboardingTitle}
              hasButton={false}
              buttonText={""}
              navigateTo={""}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container unset-height px-0 px-lg-4 px-md-4 px-sm-4 pt-3">
            <Row className="form-row">
              <Col>
                <BreadCrumb items={items} />
              </Col>
            </Row>
            <OnBoardingFirmForm />
          </div>
        </Container>
      </div>
    </>
  );
}
