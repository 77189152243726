import { Button } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  alphabetRules,
  emailRules,
  indphonNumberRules,
  npiRules,
  phoneNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { Controller, useForm } from "react-hook-form";
import { medicalPractioner } from "../../constants/deathclaim/labels";
import { validation } from "../../constants/deathclaim/validationMessages";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import { client_details } from "../../constants/routes";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { useMedicalProfessionalLogMutation } from "../../slices/loginAuthManagementSlice";
import { countryCodeList } from "../../constants/labels";

function MedicalProfessionalDetails() {
  const location = useLocation();
  const savedFormData = location.state?.existData;
  const savedClientData =  location.state?.clientData;
  const [countryCode, setCountryCode] = useState(savedFormData?.countryCode || "");
  const [phoneNumber, setPhoneNumber] = useState(savedFormData?.phoneNumber || "");
  const navigate = useNavigate();

  const [medicalprofessional, { isLoading: medicalprofessionalLoading }] =
    useMedicalProfessionalLogMutation();
  
  const schema = yup.object().shape({
    name: yup
      .string().trim()
      .required(`${validation.name.required}`)
      .matches(alphabetRules, `${validation.name.rule}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
      npi: yup
      .string().trim()
      .matches(npiRules, { message: `${validation.npi.npiRules}` }),
  });

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (savedFormData) {
      setValue("name", savedFormData.name);
      setValue("email", savedFormData.emailId);
      setValue("npi", savedFormData.npiNumber);
    }
  }, [savedFormData, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "name") {
      event.target.value = value.slice(0, 35);
    }
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "npi") {
      event.target.value = value.slice(0, 10);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const fetchIPAddress = async () => {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null
    }
   
};


  const handleClickSubmit = async (formData) => {
    const transformData = {
      name: formData.name,
      emailId: formData.email,
      phoneNumber: `+${formData.phone}`,
      npiNumber: formData.npi,
      ipAddress: await fetchIPAddress()
    };
    const existData = {
      name: formData.name,
      emailId: formData.email,
      phoneNumber: phoneNumber,
      countryCode: countryCode,
      npiNumber: formData.npi
    };
    try {
      const res = await medicalprofessional(transformData).unwrap();
      if (res?.isSuccessful) {
        navigate(client_details,{ state: { medData: existData, medicalprofessionalid : res.clientIdentifier, clientData: savedClientData } })
      }
      else{
        toast.error(res.message)
      }
    } catch (err) {
        toast.error(
          err?.data?.message ||
            err?.data?.errorMessage ||
            err.error ||
            err?.errorMessage ||
            err
        );
      }
    }

  return (
    <>
      <div className="medical-professional-container">
        <div className="centered-box">
          <Form
            className="custom-form w-100 type-2"
            onSubmit={handleSubmit(handleClickSubmit)}
          >
            <div className="px-2">
              <div className="modal-content-section">
                <h2 className="med-header"> Enter the below details </h2>
                <Row className="form-row">
                  <Col>
                    <Row>
                      <Col lg={12} md={12} className="mt-2">
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="name"
                                className="required mb-1"
                              >
                                {medicalPractioner.name}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                autoComplete="off"
                                {...register("name", {
                                  required: true,
                                })}
                                className="form-input-med input-border-radius-unset"
                                maxLength={35}
                                isInvalid={!!errors.name}
                                data-testid="name"
                                id="name"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="input-feedback"
                              >
                                {errors.name?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                id="med-email"
                                className="required mb-1"
                              >
                                {medicalPractioner.email}
                              </Form.Label>
                              <Form.Control
                                className="form-input-med input-border-radius-unset"
                                type="email"
                                name="email"
                                autoComplete="off"
                                {...register("email")}
                                isInvalid={!!errors.email}
                                onBlur = {(e) => e.target.value = e.target.value.trim()}
                                data-testid="med-email"
                                id="med-email"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.email?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="phone"
                                className="required mb-1"
                              >
                                {medicalPractioner.phone}
                              </Form.Label>
                              <Controller
                                control={control}
                                name="phone"
                                render={({ field }) => (
                                  <div
                                    className={
                                      errors.phone
                                        ? "phone-input-invalid-container-med"
                                        : "phone-input-valid-container-med"
                                    }
                                  >
                                    <PhoneInput
                                      defaultCountry="us"
                                      onlyCountries={countryCodeList}
                                      placeholder="Enter the phone number"
                                      title=""
                                      data-testid = "med-phone"
                                      value={phoneNumber}
                                      country={"us"}
                                      defaultValue=""
                                      inputProps={{
                                        required: true,
                                        autoFocus: false,
                                        title: "",
                                      }}
                                      countryCodeEditable={false}
                                      onChangeCapture={handleMaxNumber}
                                      onChange={(value, phone) => {
                                        if (
                                          phone.dialCode === "1" &&
                                          phone.countryCode === "us"
                                        ) {
                                          setCountryCode("+1");
                                          setPhoneNumber(value);
                                        }
                                        if (
                                          phone.dialCode === "91" &&
                                          phone.countryCode === "in"
                                        ) {
                                          setCountryCode("+91");
                                          setPhoneNumber(value);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              />
                              <Form.Control
                                className="form-input-med input-border-radius-unset input_number"
                                type="hidden"
                                name="phone"
                                value={phoneNumber}
                                autoComplete="off"
                                {...register("phone")}
                                isInvalid={!!errors.phone}
                                data-testid = "med-phone"
                                id="med-phone"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.phone?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                          <Form.Group className="mb-3">
                      <Form.Label htmlFor="med-npi" className="required mb-1">
                        {medicalPractioner.npi}
                      </Form.Label>
                      <Form.Control
                        className="form-input-med input-border-radius-unset input_number"
                        type="number"
                        name="npi"
                        autoComplete="off"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        {...register("npi")}
                        isInvalid={!!errors.npi}
                        data-testid="med-npi"
                        id="med-npi"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.npi?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center mt-lg-2 mt-md-2 mt-4">
                      <Button
                        type="submit"
                        className="custom-button btn-standard next-button"
                        data-testid="next-button"
                        placeholder="next-button"
                      >
                        Next
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {medicalprofessionalLoading && <Loader />}
    </>
  );
}

export default MedicalProfessionalDetails;
