import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as EditAction } from "../../assets/images/EditAction.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/CheckIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import ReactGA from "react-ga4";
import AgGrid from "../../components/table/AgGrid";
import Loader from "../../components/Loader/Loader";
import {
  attorneyDetails,
  attorneyTitle,
  attorneyTitleButton,
  searchPlaceholder,
} from "../../constants/attorney/lables";
import {
  attorney_AddClient,
  attorney_EditClient,
  attorney_ViewClient,
  attoney_foldersPage,
  attorney_client_addFamily,
  attorney_client_viewFamily,
  attorney_LandingPage,
  attorney_bulk_upload
} from "../../constants/routes";
import { useGetAllClientDetailsQuery } from "../../slices/attorneyApiManagementSlice";
import { ROLE } from "../../constants/role";
import { setEmptyDetails } from "../../slices/attorneyAddClientSlice";
import { useGoogleAnalyticsKeyQuery } from "../../slices/gaApiSliceManagement";
import { setDetails } from "../../slices/shareReceiveBankDetailsSlice";
import { saLandingPlaceholder } from "../../constants/firm/labels";
export default function AttorneyLandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [rowData, setRowData] = useState([]);
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const identifierID = queryParameters.get("identifier");
  const urlType = queryParameters.get("type");
  let empnameWidth = 200;
  let empnameMinWidth = 250;
  let empnameWidthSSO = 300;
  let empnameMinWidthSSO = 300;
  let empemailWidthSSO = 400;

  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
    empnameWidthSSO = 100;
    empnameMinWidthSSO = 140;
    empemailWidthSSO = 350;
  }

  const addClient = () => {
    dispatch(setEmptyDetails());
    navigate(attorney_AddClient, {
      state: {
        page: "addclient",
        id: userInfo?.userId,
      },
    });
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `Add Client - ${ROLE.attorney} - 3 Level`,
      label: `Add Client - ${ROLE.attorney} - 3 Level`,
    });
  };
  const viewClient = (params) => {
    navigate(attorney_ViewClient, {
      state: {
        page: "viewclient",
        id: params?.clientId,
      },
    });
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `View Client - ${ROLE.attorney} - 3 Level`,
      label: `View Client - ${ROLE.attorney} - 3 Level`,
    });
  };

  const editClient = (params) => {
    navigate(attorney_EditClient, {
      state: {
        page: "editclient",
        id: params?.clientId,
      },
    });
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `Edit Client - ${ROLE.attorney} - 3 Level`,
      label: `Edit Client - ${ROLE.attorney} - 3 Level`,
    });
  };
  const clientDocuments = (event) => {
    navigate(attoney_foldersPage, {
      state: {
        page: "clientFolders",
        user: { clientId: event?.data?.clientId },
      },
    });
  };
  const handleBulkUpload = (params) => {
    navigate(attorney_bulk_upload);
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 2 Level`,
      action: `Bulk Upload ${ROLE.firmsuperadmin} - 2 Level`,
      label: `Bulk Upload ${ROLE.firmsuperadmin} - 2 Level`,
    });
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };

  const attorneyId = userInfo?.userId;
  const { data, isLoading } = useGetAllClientDetailsQuery(attorneyId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: googleAnalyticsKey } = useGoogleAnalyticsKeyQuery({
    skip: data?.length === 0,
  });
  useEffect(() => {
    if (identifierID && urlType && (urlType === "addProf")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${urlType}`);
    }
    if (identifierID && urlType && (urlType === "deathClaim")) {
      dispatch(setDetails({ identifierID, requestType:urlType }));
      navigate(`${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${urlType}`);
    }
  }, [dispatch, identifierID, navigate, urlType])
  useEffect(() => {
    if (data?.length > 0) {
      setRowData(data);
    } else {
      setRowData([]);
    }
  }, [data]);

  useEffect(() => {
    const googleTrackingId = googleAnalyticsKey?.REACT_APP_GOOGLE_ID;
    if (data?.length > 0 && googleTrackingId) {
      const page = location.pathname + location.search;
      const usersId = userInfo?.userId;
      const role = userInfo?.role;
      const firmTypeId = userInfo?.firmTypeId;
      const firmId = userInfo?.firmId;
      ReactGA.initialize(googleTrackingId);

      ReactGA.gtag("set", "user_properties", {
        userRole: role,
        usersId: usersId,
        firmTypeId: firmTypeId,
        firmId:firmId,
      });

      ReactGA.send({
        hitType: "pageview",
        page: `${page}`,
        title: "Attorney Landing Page - 3 Level",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, googleAnalyticsKey]);

  const GridFullName = (params) => {
    const { value } = params;
    const fullName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    if (value.length > 20) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor fullname">
            {fullName}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor fullname">{fullName}</div>
      );
    }
  };

  const GridEmailName = (params) => {
    const { value } = params;
    const email = value.length > 40 ? value.substring(0, 40) + "..." : value;

    if (value.length > 40) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
      );
    }
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return <div className="grid-center-aligned-text">{p.value}</div>;
  };
  const viewTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      View
    </Tooltip>
  );
  const editTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const GridButton = ({ data, viewButtonClick, editButtonClick }) => {
    const viewPage = () => {
      viewButtonClick(data);
    };

    const editPage = () => {
      editButtonClick(data);
    };
    const uniqueKey = data?.attorneyId;
    return (
      <div className="d-flex">
        <OverlayTrigger
          placement="top"
          key={`regen-${uniqueKey}`}
          overlay={viewTooltip}
          className="sa-tooltip"
        >
          <div className="att-action-button me-4">
            <div className="action-button" data-testid="view-button" onClick={viewPage}>
              <Eye />
            </div>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          key={`edit-${uniqueKey}`}
          overlay={editTooltip}
        >
          <div className="att-action-button ms-2">
            <div className="action-button" data-testid="edit-button" onClick={editPage}>
              <EditAction />
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const addFamilyMember = (params) => {
    dispatch(setEmptyDetails());
    navigate(attorney_client_addFamily, {
      state: {
        prevPage: "landingPage",
        id: params?.clientId,
      },
    });
  };

  const viewFamilyMember = (params) => {
    navigate(attorney_client_viewFamily, {
      state: {
        page: "landingPage",
        id: params?.clientId,
      },
    });
    ReactGA.event({
      category: `${ROLE.attorney} - 3 Level`,
      action: `View Client - ${ROLE.attorney} - 3 Level`,
      label: `View Client - ${ROLE.attorney} - 3 Level`,
    });
  };

  const AddFamily = ({ data, addFamilyButtonClick, viewFamilyButtonClick }) => {
    const addFamily = () => {
      addFamilyButtonClick(data);
    };

    const viewFamily = () => {
      viewFamilyButtonClick(data);
    };

    const familyMemberName = data?.familyMember?.name;
    const truncatedFamilyMemberName =
    familyMemberName && familyMemberName?.length > 20
      ? familyMemberName.substring(0, 20) + "..."
      : familyMemberName;

    return (
      <div className="d-flex">
        <div className="att-action-button">
          {familyMemberName ? (
            <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${familyMemberName}`}>{familyMemberName}</Tooltip>}
          >
            <div
              className="action-button grid-firm-name-anchor view-family-button"
              onClick={viewFamily}
            >
              {truncatedFamilyMemberName}
            </div>
          </OverlayTrigger>
          ) : (
            <div
              className="action-button add-family-button"
              onClick={addFamily}
            >
              {"Add Emergency contact"}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CheckCloseIcon = ({ value }) => {
    return (
      <div className="grid-center-aligned-text">
        {value ? <CheckIcon /> : <CloseIcon />}
      </div>
    );
  };

  const [columnDefsNonSSO] = useState([
    {
      headerName: attorneyDetails.fullName,
      field: "fullName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => clientDocuments(event),
    },
    {
      headerName: attorneyDetails.email,
      field: "email",
      minWidth: 300,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: attorneyDetails.phone,
      field: "phone",
      minWidth: 140,
    },
    {
      headerName: attorneyDetails.uploadedDocuments,
      field: "uploadedDocuments",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
      minWidth: 150,
    },
    {
      headerName: attorneyDetails.willsUploaded,
      field: "willsUploaded",
      cellRenderer: CheckCloseIcon,
      headerClass: "center-aligned-header",
    },
    {
      headerName: "Emergency Contact",
      field: "Add Emergency Contact",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <AddFamily
            data={params.data}
            addFamilyButtonClick={(data) => {
              addFamilyMember(data);
            }}
            viewFamilyButtonClick={(data) => {
              viewFamilyMember(data);
            }}
          />
        );
      },
    },
    {
      headerName: attorneyDetails.cliActions,
      field: "attorneyAction",
      cellRenderer: (params) => {
        return (
          <GridButton
            data={params.data}
            viewButtonClick={(data) => {
              viewClient(data);
            }}
            editButtonClick={(data) => {
              editClient(data);
            }}
          />
        );
      },
      // headerClass: "center-aligned-header",
    },
  ]);
  const [columnDefsSSO] = useState([
    {
      headerName: attorneyDetails.fullName,
      field: "fullName",
      width: empnameWidthSSO,
      minWidth: empnameMinWidthSSO,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => clientDocuments(event),
    },
    {
      headerName: attorneyDetails.email,
      field: "email",
      minWidth: empemailWidthSSO,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: attorneyDetails.phone,
      field: "phone",
      minWidth: 140,
    },
    {
      headerName: attorneyDetails.uploadedDocuments,
      field: "uploadedDocuments",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
      minWidth: 190,
    },
    {
      headerName: attorneyDetails.willsUploaded,
      field: "willsUploaded",
      cellRenderer: CheckCloseIcon,
      headerClass: "center-aligned-header",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 140,
    };
  }, []);

  const paginationSizeLimit = 10;
  const noOfEmp = rowData?.length;

  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="attorney-landing-page"
      >
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={attorneyTitle}
              hasInpuField={true}
              hasButton={false}
              buttonText={attorneyTitleButton}
              navigateTo={addClient}
              searchText={searchText}
              handleSearchChange={handleSearchChange}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-sm-4">
          <Row className="mb-3">
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-start align-items-end mt-3"
              >
                <div className="sa-table-label">
                  {noOfEmp === 0 || noOfEmp === 1
                    ? saLandingPlaceholder.saClientLabel
                    : saLandingPlaceholder.saClientLabelPlural}
                  : {noOfEmp}
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-end mt-3"
                data-testid="new-firm-onboard-button"
              >
                {!isSSOLogin && (
                  <div className="search-container d-flex justify-content-between align-items-center">
                    <Button
                      className={`sa-button custom-button btn-standard btn-bulk-upload`}
                      type="button"
                      onClick={handleBulkUpload}
                    >
                      {saLandingPlaceholder.saBulkUpload}
                    </Button>
                    <Button
                      className={`sa-button custom-button btn-standard btn-add-employee`}
                      type="button"
                      onClick={addClient}
                    >
                      {saLandingPlaceholder.saAddClient}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pb-3 d-block d-md-none ">
              <div className="sa-search-container">
                <div className="search-icon">
                  <AGSearchIcon />
                </div>
                <div className="search-input-field relative-position">
                  <input
                    type="text"
                    placeholder={searchPlaceholder}
                    value={searchText}
                    onChange={handleSearchChange}
                    id="search-firm-input-field-mob"
                    data-testid="grid-search-input"
                  />
                </div>
                {searchText && (
                  <div
                    onClick={handleClearSearch}
                    className="clear-search-button"
                  >
                    <SearchXIcon />
                  </div>
                )}
              </div>
            </Row>
            <Row className="m-10">
              <Col lg={12} xs={12}>
                <AgGrid
                  quickFilterText={searchText}
                  rowData={rowData}
                  columnDefs={isSSOLogin ? columnDefsSSO : columnDefsNonSSO}
                  defaultColDef={defaultColDef}
                  getRowStyle={getRowStyle}
                  paginationSizeLimit={paginationSizeLimit}
                />
                {isLoading && <Loader data-testid="loader" />}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
