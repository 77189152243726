import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Loader from "../Loader/Loader";
import ReactPdf from "../wordtopdf/ReactPdf";
import { useSelector } from "react-redux";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";

export default function DocumentPreview({
  showModal,
  setShowCallback,
  fileContent,
  fileName,
  initialRadioSelect,
  setSubmitCallback,
  setRadioCallback,
  isLoading,
  selectedDropdown,
  isOthersLoading,
  selectedDocs,
  docType,
  docId,
  notificationMessage
}) {
  const [radioSelect, setRadioSelect] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (initialRadioSelect) {
      setRadioSelect("");
    }
  }, [initialRadioSelect]);

  const handleClose = () => {
    setShowCallback(false);
    setRadioSelect("");
  };

  const handleSubmit = () => {
    setSubmitCallback(true, radioSelect);
    if (userInfo?.role === ROLE.attorney) {
      ReactGA.event({
        category: `${ROLE.attorney} - 3 Level`,
        action: `Upload Will Document - ${ROLE.attorney} - 3 Level`,
        label: `Upload Will Document - ${ROLE.attorney} - 3 Level`,
      });
    }

    if (userInfo?.role === ROLE.client) {
      const firmType = userInfo?.firmTypeId;

      firmType === 1 &&
        ReactGA.event({
          category: `${ROLE.client} - 3 Level`,
          action: `Upload Will Document - ${ROLE.client} - 3 Level`,
          label: `Upload Will Document - ${ROLE.client} - 3 Level`,
        });

      firmType === 2 &&
        ReactGA.event({
          category: `${ROLE.client} - 2 Level`,
          action: `Upload Will Document - ${ROLE.client} - 2 Level`,
          label: `Upload Will Document - ${ROLE.client} - 2 Level`,
        });
    }
  };

  const handlRadioButton = (e) => {
    setRadioSelect(e.target.value);
    setRadioCallback(e.target.value);
  };
  return (
    <>
      <Modal show={showModal} fullscreen onHide={handleClose} data-testid="document-preivew">
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={2}></Col>
            <Col lg={8}>
              {fileContent && (
                <div className="PDF-viewer" data-testid="PDF-viewer">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <div className="review-title text-center">
                      {
                        notificationMessage && notificationMessage !==""?
                        notificationMessage
                        :"Please review the file that you have uploaded"
                      }                      
                    </div>
                    {selectedDropdown === "DT_001" && (
                      <div className="radio-container d-lg-flex d-md-flex mt-3">
                        <div className="radio-buttons-label">
                          {
                            notificationMessage && notificationMessage !==""?
                            "Do you retain the Hard copy?":"Do you retain an original of estate documents?"
                          }
                          <sup className="sup-required">*</sup>
                        </div>
                        <div className="radio-buttons-label ps-4">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group1"
                            type="radio"
                            id={`inline-radio-1`}
                            value={"Yes"}
                            data-testid="radio-button-yes"
                            onChange={(e) => handlRadioButton(e)}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            value={"No"}
                            data-testid="radio-button-no"
                            onChange={(e) => handlRadioButton(e)}
                          />
                        </div>
                      </div>
                    )}
                    {
                      ((notificationMessage && notificationMessage !=="") && (selectedDropdown === ""))
                    ?"":
                    ((radioSelect !== "" && selectedDropdown === "DT_001") ||
                      selectedDropdown !== "DT_001") && (
                      <Button
                        className="custom-button btn-standard submit-button-file mt-3 px-4"
                        onClick={handleSubmit}
                        data-testid="document-preview-submit"
                      >
                        Proceed to Submit
                      </Button>
                    )}
                  </div>
                  {
                    !(notificationMessage && notificationMessage!=="")?
                    <div className="mt-3">
                    <ReactPdf selectedDocs={selectedDocs} fileName={fileName} />
                  </div>
                  :""
                  }
                  
                </div>
              )}
              {
                (docType === "will") && (
                  <div className="PDF-viewer">
                    <div className="d-flex justify-content-center flex-column align-items-center"></div>
                    <div className="mt-3">
                      <ReactPdf
                        selectedDocs={selectedDocs}
                        mode="download"
                        fileName={fileName}
                        docId={docId}
                      />
                    </div>
                  </div>
                )
              }
            </Col>
            <Col lg={2}></Col>
          </Row>
        </Modal.Body>
      </Modal>
      {(isLoading || isOthersLoading) && <Loader />}
    </>
  );
}
