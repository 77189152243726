import { userApiSlice } from "./userApiSlice";

export const userAuthManagementSlice = userApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    regenerateadminpassword: builder.query({
      query: (username) => ({
        url: `/regenerateAdminPassword`,
        method: "GET",
        headers: {
          Username: `${username}`,
        },
      }),
    }),
    getAttorneyByEmail: builder.query({
      query: ({email, firmId}) => ({
        url:`/attorneys?email=${email}&firmId=${firmId}`,
        method: "GET",
      })
    }),
    clientSearch: builder.mutation({
      query: (data) => ({
        url: "/clientSearch",
        method: "POST",
        body:data
      }),
    }),
    getNotificationStatus: builder.query({
      query: (userId) => ({
        url:`/notification/${userId}`,
        method: "GET",
      }),
      providesTags : ['getUserNotifictionById']
    }),
    updateNotificationStatus: builder.mutation({
      query: (data) => ({
        url: "/notification",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['getUserNotifictionById']
    }),
    getAttorneyByClientId: builder.query({
      query: (clientId) => ({
        url:`/attorney/${clientId}`,
        method: "GET",
      })
    }),
    createExternalUser: builder.mutation({
      query: (data) => ({
        url: "/external-user",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["WireTransfer"],
    }),
  }),
});

export const {
  useLazyRegenerateadminpasswordQuery,
  useLazyGetAttorneyByEmailQuery,
  useClientSearchMutation,
  useUpdateNotificationStatusMutation,
  useGetNotificationStatusQuery,
  useLazyGetNotificationStatusQuery,
  useLazyGetAttorneyByClientIdQuery,
  useCreateExternalUserMutation
} = userAuthManagementSlice;
