import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useUploadCSVMutation, useUploadMemberMutation } from "../../slices/bulkUploadApiManagementSlice";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import ReactGA from "react-ga4";
import { ROLE } from "../../constants/role";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "rgba(168, 184, 192, 1)",
  borderStyle: "dashed",
  backgroundColor: "rgba(232, 232, 237, 1)",
  color: "rgb(33, 37, 41)",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadFile(props) {
  const [uploadcsv, { isLoading }] = useUploadCSVMutation();
  const [uploadMember, { isMemberLoading }] = useUploadMemberMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const firmId = userInfo?.firmId;
  const role = props.role;
  const professionalId = userInfo?.userId;

  const onDropAccepted = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    if (ROLE.attorney === userInfo?.role) {
      try {
        const res = await uploadMember({
          formData,
          user: { firmId, professionalId, role },
        }).unwrap();
        if (res?.message) {
          toast.success(res.message);
        }
      } catch (err) {
        toast.error(
          err?.data?.message || err?.errorMessage || err?.data?.errorMessage
        );
      }
    } else {
      try {
        const res = await uploadcsv({
          formData,
          user: { firmId, role },
        }).unwrap();
        if (res?.message) {
          toast.success(res.message);
          if (role === "attorney") {
            ReactGA.event({
              category: `${ROLE.firmsuperadmin} - 3 Level`,
              action: `Creating Bulk Upload Records - ${ROLE.firmsuperadmin} - 3 Level`,
              label: `Creating Bulk Upload Records - ${ROLE.firmsuperadmin} - 3 Level`,
            });
          }

          if (role === "client") {
            ReactGA.event({
              category: `${ROLE.client} - 2 Level`,
              action: `Creating Bulk Upload Records - ${ROLE.client} - 2 Level`,
              label: `Creating Bulk Upload Records - ${ROLE.client} - 2 Level`,
            });
          }
        }
      } catch (err) {
        toast.error(
          err?.data?.message || err?.errorMessage || err?.data?.errorMessage
        );
      }
    }
  };

  const onDropRejected = (params) => {
    // console.log(params);
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      toast.error(`This format is not valid! Please upload XLSX format only`);
    }
    // if (params[0]?.errors[0]?.code === "file-too-large") {
    //   toast.error(`File more than 20 MB is not allowed`);
    // }
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
    // maxSize: 10 * 1024 * 1024,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="drag-container pt-4" style={{ fontWeight: 400 }}>
      <div {...getRootProps({ style })} data-testid="upload-file">
        <input {...getInputProps()} />
        <div className="w-100 text-center p-4">
          <div className="mt-3">Drag and Drop Files Here</div>
          <div className="d-flex align-items-center mt-4">
            <div
              style={{ flex: "1", borderBottom: "2px dashed rgb(33, 37, 41)" }}
            />
            <div style={{ margin: "0 10px", fontWeight: "400" }}>Or</div>
            <div
              style={{ flex: "1", borderBottom: "2px dashed rgb(33, 37, 41)" }}
            />
          </div>
          <Button
            className="custom-button btn-standard mt-4"
            style={{ fontSize: "18px" }}
            onClick={open}
          >
            Browse Files
          </Button>
          <div className="pt-3">Allowed File Formats: .XLSX</div>
          {/* <div className="pt-3">Maximum file size 10MB</div> */}
        </div>
      </div>
      {(isLoading || isMemberLoading) && <Loader />}
    </div>
  );
}
