import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
// import { ReactComponent as RegenPassword } from "../../assets/images/RegenPassword.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { ReactComponent as EditAction } from "../../assets/images/EditAction.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import {
  firmsuperadmin_AddEmployeePage,
  firmsuperadmin_BulkUpload,
  firmsuperadmin_EditEmployeePage,
  firmsuperadmin_ViewEmployeePage,
} from "../../constants/routes";
import AgGrid from "../../components/table/AgGrid";
import {
  saLandingPlaceholder,
  firmAttorneylabel,
} from "../../constants/firm/labels";
import ModalPopupType2 from "../../components/modal/ModalPopupType2";
import { useDeleteAttorneyMutation } from "../../slices/superAdminApiManagementSlice";
import { useGetAllAttorneyDetailsQuery } from "../../slices/firmApiManagement";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useLazyRegenerateadminpasswordQuery } from "../../slices/superAdminApiManagementSlice";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { ROLE } from "../../constants/role";
import { useGoogleAnalyticsKeyQuery } from "../../slices/gaApiSliceManagement";
export default function FirmAdminLandingPage() {
  const [showModal, setShowModal] = useState(false);
  const [showconfirmModal, setConfirmModal] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [regenEmail, setRegenEmail] = useState(null);
  const [actionType, setActionType] = useState("");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { userInfo } = useSelector((state) => state.auth);

  const [showYes, setYesClicked] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [trigger] = useLazyRegenerateadminpasswordQuery();
  const firmId = userInfo?.firmId;
  const { data, isLoading } = useGetAllAttorneyDetailsQuery(firmId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: googleAnalyticsKey } = useGoogleAnalyticsKeyQuery({
    skip: data?.length === 0,
  });

  const [deleteAttorney, { isLoading: deletAttorneyLoading }] =
    useDeleteAttorneyMutation();
  let location = useLocation();
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  let empnameWidth = 200;
  let empnameMinWidth = 250;
  let empnameWidthSSO = 400;
  let empnameMinWidthSSO = 400;
  let emailWidthSSO = 500;

  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
    empnameMinWidthSSO = 200;
    empnameWidthSSO = 150;
    emailWidthSSO = 350;
  }
  const handleClickModalPopup = (type, params) => {
    if (params?.numberOfClients === 0) {
      setConfirmModal(true);
      setYesClicked(false);
      setActionType(type);
      setDeleteId(params?.attorneyId);
      setRegenEmail(params?.email);
      setFullName(params?.fullName);
    } else {
      setShowModal(true);
      setDeleteId(params?.attorneyId);
    }
  };

  const getResult = useCallback(async () => {
    const result = await trigger(regenEmail).unwrap();
    if (result?.isSuccessful) {
      toast.success("Password Regenerate Successfully!");
    } else {
      toast.error(result?.message);
    }
  }, [regenEmail, trigger]);
  useEffect(() => {
    if (showYes) {
      setShowModal(false);
      getResult();
    }
  }, [showYes, getResult]);
  const handleYesClicked = async () => {
    setShowModal(false);
    if (actionType === "regenPassword") {
      getResult();
    } else if (actionType === "deleteItem") {
      try {
        const res = await deleteAttorney(deleteId).unwrap();
        if (res?.isSuccessful) {
          setConfirmModal(false);
          toast.success(res?.message);
        } else {
          toast.error(res?.errorMessage);
        }
      } catch (error) {
        toast.error(error?.errorMessage);
      }
    }
  };
  const handleOkClicked = () => {
    navigate(firmsuperadmin_ViewEmployeePage, {
      state: {
        page: "viewemployee",
        id: deleteId,
      },
    });
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `Delete Employee Confirmed - ${ROLE.firmsuperadmin} - 3 Level`,
      label: `Delete Employee Confirmed - ${ROLE.firmsuperadmin} - 3 Level`,
    });
  };
  const handleBulkUpload = () => {
    navigate(firmsuperadmin_BulkUpload);
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `Bulk Upload ${ROLE.firmsuperadmin} - 3 Level`,
      label: `Bulk Upload ${ROLE.firmsuperadmin} - 3 Level`,
    });
  };

  const firmAddEmployee = () => {
    navigate(firmsuperadmin_AddEmployeePage, {
      state: {
        page: "addemployee",
        id: userInfo?.firmId,
      },
    });
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `Add Employee ${ROLE.firmsuperadmin} - 3 Level`,
      label: `Add Employee ${ROLE.firmsuperadmin} - 3 Level`,
    });
  };
  const attorneyDetailsLanding = (event) => {
    navigate(firmsuperadmin_ViewEmployeePage, {
      state: {
        page: "viewemployee",
        id: event.data.attorneyId,
      },
    });
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `View Employee ${ROLE.firmsuperadmin} - 3 Level`,
      label: `View Employee ${ROLE.firmsuperadmin} - 3 Level`,
    });
  };

  const handleEditPage = (params) => {
    navigate(firmsuperadmin_EditEmployeePage, {
      state: {
        page: "editemployee",
        id: params?.attorneyId,
      },
    });
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };

  useEffect(() => {
    const googleTrackingId = googleAnalyticsKey?.REACT_APP_GOOGLE_ID;
    if (data && data?.length > 0 && googleTrackingId) {
      const page = location.pathname + location.search;
      const usersId = userInfo?.userId;
      const role = userInfo?.role;
      const firmTypeId = userInfo?.firmTypeId;
      const firmId = userInfo?.firmId;

      ReactGA.initialize(googleTrackingId);

      ReactGA.gtag("set", "user_properties", {
        userRole: role,
        usersId: usersId,
        firmTypeId: firmTypeId,
        firmId: firmId,
      });

      ReactGA.send({
        hitType: "pageview",
        page: `${page}`,
        title: "Firm Admin Landing Page - 3 Level",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, googleAnalyticsKey]);

  useEffect(() => {
    if (data?.length > 0) {
      setRowData(data);
    } else {
      setRowData([]);
    }
  }, [data]);

  const GridFullName = (params) => {
    const { value } = params;
    const fullName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    if (value.length > 20) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor">
            {fullName}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor">{fullName}</div>
      );
    }
  };

  const GridEmailName = (params) => {
    const { value } = params;
    const email = value.length > 40 ? value.substring(0, 40) + "..." : value;

    if (value.length > 40) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
      );
    }
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return <div className="grid-center-aligned-text">{p.value}</div>;
  };
  // const regenTooltip = (props) => (
  //   <Tooltip className="tooltip" {...props}>
  //     Change Password
  //   </Tooltip>
  // );
  const deleteTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      Delete
    </Tooltip>
  );
  const editTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const GridButton = ({
    data,
    deleteButtonClick,
    // regenButtonClick,
    editButtonClick,
  }) => {
    const deleteButton = () => {
      deleteButtonClick(data);
      setFullName(data?.fullName);
      ReactGA.event({
        category: `${ROLE.firmsuperadmin} - 3 Level`,
        action: `Delete Employee - ${ROLE.firmsuperadmin} - 3 Level`,
        label: `Delete Employee - ${ROLE.firmsuperadmin} - 3 Level`,
      });
    };

    // const regenPassword = () => {
    //   regenButtonClick(data);
    //   setFullName(data?.fullName);
    //   handleClickModalPopup("regenPassword", data);
    // };

    const editPage = () => {
      editButtonClick(data);
      ReactGA.event({
        category: `${ROLE.firmsuperadmin} - 3 Level`,
        action: `Edit Employee Details - ${ROLE.firmsuperadmin} - 3 Level`,
        label: `Edit Employee Details - ${ROLE.firmsuperadmin} - 3 Level`,
      });
    };
    const uniqueKey = data?.attorneyId;
    return (
      <div className="d-flex">
        {/* <OverlayTrigger
          placement="top"
          key={`regen-${uniqueKey}`}
          overlay={regenTooltip}
          className="sa-tooltip"
        >
          <div className="sa-action-button">
            <div className="action-button pe-1" onClick={regenPassword}>
              <RegenPassword />
            </div>
          </div>
        </OverlayTrigger> */}
        <OverlayTrigger
          placement="top"
          key={`delete-${uniqueKey}`}
          overlay={deleteTooltip}
        >
          <div className="sa-action-button me-4">
            <div className="action-button" data-testid="delete-button" onClick={deleteButton}>
              <DeleteAction />
            </div>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          key={`edit-${uniqueKey}`}
          overlay={editTooltip}
        >
          <div className="sa-action-button ms-2">
            <div className="action-button" data-testid="edit-button" onClick={editPage}>
              <EditAction />
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const [columnDefsNonSSO] = useState([
    {
      headerName: saLandingPlaceholder.fullName,
      field: "fullName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => attorneyDetailsLanding(event),
    },
    {
      headerName: saLandingPlaceholder.email,
      field: "email",
      minWidth: 350,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: saLandingPlaceholder.phone,
      minWidth: 150,
      field: "phone",
    },
    {
      headerName: saLandingPlaceholder.numberOfClients,
      field: "numberOfClients",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
    },
    {
      headerName: saLandingPlaceholder.attorneyAction,
      field: "attorneyAction",
      cellRenderer: (params) => {
        return (
          <GridButton
            data={params.data}
            deleteButtonClick={(data) => {
              handleClickModalPopup("deleteItem", data);
            }}
            // regenButtonClick={(data) => {
            //   handleClickModalPopup("regenPassword", data);
            // }}
            editButtonClick={(data) => {
              handleEditPage(data);
            }}
          />
        );
      },

      getQuickFilterText: () => "",
      // headerClass: "center-aligned-header",
    },
  ]);

  const [columnDefsSSO] = useState([
    {
      headerName: saLandingPlaceholder.fullName,
      field: "fullName",
      width: empnameWidthSSO,
      minWidth: empnameMinWidthSSO,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => attorneyDetailsLanding(event),
    },
    {
      headerName: saLandingPlaceholder.email,
      field: "email",
      minWidth: emailWidthSSO,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: saLandingPlaceholder.phone,
      minWidth: 150,
      field: "phone",
    },
    {
      headerName: saLandingPlaceholder.numberOfClients,
      field: "numberOfClients",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 200,
    };
  }, []);

  const paginationSizeLimit = 10;
  const noOfEmp = rowData?.length;

  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="firm-admin-landing-page"
      >
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title="Employee Management"
              hasInpuField={true}
              searchText={searchText}
              handleSearchChange={handleSearchChange}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-sm-4">
            <Row className="mb-3">
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-start align-items-end mt-3"
              >
                <div className="sa-table-label">
                  {noOfEmp > 1
                    ? saLandingPlaceholder.superAdminTableLabelPlural
                    : saLandingPlaceholder.superAdminTableLabel}
                  : {noOfEmp}
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-end mt-3"
              >
                {!isSSOLogin && (
                  <div className="search-container d-flex justify-content-between align-items-center">
                    <Button
                      className={`sa-button custom-button btn-standard btn-bulk-upload`}
                      type="button"
                      onClick={handleBulkUpload}
                      data-testid="new-firm-onboard-button"
                    >
                      {saLandingPlaceholder.saBulkUpload}
                    </Button>
                    <Button
                      className={`sa-button custom-button btn-standard btn-add-employee`}
                      type="button"
                      onClick={firmAddEmployee}
                      data-testid="new-firm-onboard-button"
                    >
                      {saLandingPlaceholder.saAddEmployee}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pb-3 d-block d-md-none ">
              <div className="sa-search-container">
                <div className="search-icon">
                  <AGSearchIcon />
                </div>
                <div className="search-input-field relative-position">
                  <input
                    type="text"
                    placeholder={saLandingPlaceholder.saSearchText}
                    value={searchText}
                    onChange={handleSearchChange}
                    id="search-firm-input-field-mob"
                    data-testid="grid-search-input"
                  />
                </div>
                {searchText && (
                  <div
                    onClick={handleClearSearch}
                    className="clear-search-button"
                  >
                    <SearchXIcon />
                  </div>
                )}
              </div>
            </Row>
            <Row className="m-10">
              <Col lg={12} xs={12}>
                <AgGrid
                  quickFilterText={searchText}
                  rowData={rowData}
                  columnDefs={isSSOLogin ? columnDefsSSO : columnDefsNonSSO}
                  defaultColDef={defaultColDef}
                  getRowStyle={getRowStyle}
                  paginationSizeLimit={paginationSizeLimit}
                />
                {isLoading && <Loader data-testid="loader" />}
              </Col>
            </Row>
          </div>
        </Container>
        <ModalPopupType2
          setShowCallback={() => setConfirmModal(false)}
          setYesCallback={handleYesClicked}
          headerTitle={firmAttorneylabel.deleteProfessional}
          content={
            actionType === "deleteItem"
              ? `Are you sure you want to delete Professional(${fullName}) account?`
              : firmAttorneylabel.deleteConfirmText
          }
          showModal={showconfirmModal}
          titleClass={"modal-custom-title2"}
          contentClass={"modal-content-section2"}
        />
        <ModalPopupType2
          setShowCallback={() => setShowModal(false)}
          setYesCallback={handleYesClicked}
          headerTitle={firmAttorneylabel.deleteProfessional}
          content={firmAttorneylabel.deleteAssignProfessionalText}
          showModal={showModal}
          singleOkButton={true}
          setOkCallback={handleOkClicked}
          titleClass={"modal-custom-title2"}
          contentClass={"modal-content-section2"}
        />
      </div>
      <div className="background-mvs-image type-1"></div>
      {deletAttorneyLoading && <Loader />}
    </>
  );
}
