import React, { useState } from "react";
import FamilyInformation from "../attorney/people/FamilyInformation";
import { Offcanvas } from "react-bootstrap";
import ModalPopupType2 from "../modal/ModalPopupType2";

export default function FamilyOffCanvasAddProfile({
  visible,
  onClose,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div data-testid="family-add-profile">
        <Offcanvas
          show={visible}
          placement="end"
          id="attorney-client-off-canvas"
          onHide={()=>{setShowModal(true)}}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title><b>My Information | Add Emergency Contact Profile</b></Offcanvas.Title>
          </Offcanvas.Header>
          <div className="px-3 mb-1">
            <div className="divider-firm"></div>
          </div>
        <Offcanvas.Body>
            <FamilyInformation onClose={onClose}/>
        </Offcanvas.Body>
        </Offcanvas>
      </div>
      <ModalPopupType2
              setShowCallback={() => setShowModal(false)}
              setYesCallback={onClose}
              content={`Are you sure you want to cancel the changes?`}
              showModal={showModal}
            />
    </>
  );
}
