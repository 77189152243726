import { Check2Circle } from "react-bootstrap-icons";

export default function SuccessModalContent({ text }) {
  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center pb-4 pt-0">
          <Check2Circle size={54} fill="#20AE5C" />
        </div>
        <div className="text-align-center">{text}</div>
      </div>
    </div>
  );
}
