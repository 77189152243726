import {
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import { ReactComponent as Share } from "../../assets/images/sharedocument.svg";
import { reportPlaceholder } from "../../constants/firm/labels";
import AgGrid from "../table/AgGrid";
import {
  useDownloadPlatformUtilDetailsReportMutation,
  useGetFAPlatformUtilDetailsQuery,
} from "../../slices/reportApiManagementSlice";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { reportsPlaceholder } from "../../constants/firmdmin/labels";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import ShareReportModalPopup from "../modal/ShareReportModalPopup";

export default function MVSPlatformUtilReport() {
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [totalUsers, setTotalUsers] = useState("");
  const [monthlySessionsPerUser, setMonthlySessionsPerUser] = useState("");
  const [willsUploaded, setWillsUploaded] = useState("");
  const [mostActiveUser, setMostActiveUser] = useState("");
  const [desktopCount, setDesktopCount] = useState(0);
  const [tabletCount, setTabletCount] = useState(0);
  const [mobileCount, setMobileCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  
  const firmId = userInfo?.firmId;

  let empnameWidth = 200;
  let empnameMinWidth = 200;

  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }

  const { data, isLoading } = useGetFAPlatformUtilDetailsQuery(firmId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data && Array.isArray(data?.platformFirmSuperUserDtoList)) {
      setRowData(data?.platformFirmSuperUserDtoList);
    } else {
      setRowData([]);
    }
  }, [data]);

  useEffect(() => {
    const fileName = `Platform and Utilization`;
    setFileName(fileName);
  }, []);

  useEffect(() => {
    if (data) {
      setTotalUsers(data?.platformFirmSuperAdminDto?.totalUsers);
      setMonthlySessionsPerUser(
        data?.platformFirmSuperAdminDto?.monthlySessionsPerUser
      );
      setWillsUploaded(data?.platformFirmSuperAdminDto?.willsUploaded);
      setMostActiveUser(data?.platformFirmSuperAdminDto?.mostActiveUser);

      const deviceList =
        data?.platformFirmSuperAdminDto?.deviceOfChoiceList || [];

      const desktop =
        deviceList.find((device) => device.device === "desktop")?.users || 0;
      const tablet =
        deviceList.find((device) => device.device === "tablet")?.users || 0;
      const mobile =
        deviceList.find((device) => device.device === "mobile")?.users || 0;

      setDesktopCount(desktop);
      setTabletCount(tablet);
      setMobileCount(mobile);
    }
  }, [data]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const GridFirmName = (params) => {
    const { value } = params;
    const firmName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    return (
      <>
        {value.length > 20 ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
          >
            <div className="truncate-tooltip grid-firm-admin-email-anchor">
              {firmName}
            </div>
          </OverlayTrigger>
        ) : (
          <div className="truncate-tooltip grid-firm-admin-email-anchor">
            {firmName}
          </div>
        )}
      </>
    );
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return <div className="grid-center-aligned-text">{`${p.value}`}</div>;
  };

  const [columnDefs] = useState([
    {
      headerName: reportsPlaceholder.userName,
      field: "userName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFirmName,
    },
    {
      headerName: reportsPlaceholder.avgMonthlySession,
      minWidth: 180,
      field: "averageMonthlySession",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: reportsPlaceholder.totalTimeSpent,
      minWidth: 150,
      field: "totalTimeSpent",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: reportsPlaceholder.timeLoggedInPerSess,
      minWidth: 180,
      field: "timeLoggedInPerSession",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: reportsPlaceholder.TotalDocs,
      minWidth: 150,
      field: "totalDocuments",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: reportsPlaceholder.AvgMonthlyUpload,
      minWidth: 180,
      field: "averageMonthlyUpload",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: reportsPlaceholder.avgMonthlyDownload,
      minWidth: 180,
      field: "averageMonthlyDownload",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
  ]);
  const headerRow = [
    {
      userName: reportsPlaceholder.userName,
      averageMonthlySession: reportsPlaceholder.avgMonthlySession,
      totalTimeSpent: reportsPlaceholder.totalTimeSpent,
      timeLoggedInPerSession: reportsPlaceholder.timeLoggedInPerSess,
      totalDocuments: reportsPlaceholder.TotalDocs,
      averageMonthlyUpload: reportsPlaceholder.AvgMonthlyUpload,
      averageMonthlyDownload: reportsPlaceholder.avgMonthlyDownload,
    }    
  ]
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 150,
    };
  }, []);

  const paginationSizeLimit = 10;

  const [triggerDownload, { isLoading: download }] =
    useDownloadPlatformUtilDetailsReportMutation();

  const customFilter = useMemo(() => {
    if (!searchText) return rowData;

    return rowData.filter((item) => {
      return (
        item.userName?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.averageMonthlySession
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.totalTimeSpent
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.timeLoggedInPerSession
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.totalDocuments
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.averageMonthlyUpload
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.averageMonthlyDownload
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }, [searchText, rowData]);

  const filteredFields = customFilter.map((item) => ({
    userName: item.userName,
    averageMonthlySession: item.averageMonthlySession,
    totalTimeSpent: item.totalTimeSpent,
    timeLoggedInPerSession: item.timeLoggedInPerSession,
    totalDocuments: item.totalDocuments,
    averageMonthlyUpload: item.averageMonthlyUpload,
    averageMonthlyDownload: item.averageMonthlyDownload,
  }));

  const handleShare = () => {
    setShowModal(true);
  };

  const handleDownload = async (type) => {
    if (data) {
      let fileType = type.toLowerCase();
      let reportTitle = "Platform and Utilization Report";
      let fileName = `Platform and Utilization Report.${fileType}`;

      try {
        const result = await triggerDownload({
          reportTitle,
          data: filteredFields,
          type: fileType,
          firmId: firmId,
        }).unwrap();
        const url = window.URL.createObjectURL(new Blob([result]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        toast.success(`Report downloaded successfully.`);
      } catch (error) {
        toast.error(
          error?.data?.message ||
            error?.message ||
            "Failed to download the file. Please try again."
        );
      }
    } else {
      toast.error("No data available for download.");
    }
  };

  return (
    <>
      <div
        className="px-3 px-lg-5 firm-admin-reports-page-container"
        data-testid="firm-admin-details-reports-page"
      >
        <div className="firm-admin-reports-second-div">
          <div className="py-3 d-flex justify-content-center justify-content-space-between gap-4 flex-wrap d-block d-lg-none">
            <Container>
              <Row className="justify-content-center">
                <Col className="p-3" lg={2} md={4} sm={12}>
                  <div
                    className={`pt-2 bg-blue firm-admin-report-tiles firm-admin-clickable-tile`}
                  >
                    <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header">
                      {reportsPlaceholder.totalUsers}
                    </div>
                    <div className="px-2 firm-admin-titles-value">
                      {totalUsers || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={4} sm={12}>
                  <div
                    className={`pt-2 bg-grey firm-admin-report-tiles firm-admin-clickable-tile`}
                  >
                    <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header-mob">
                      {reportsPlaceholder.monthlySessionPerUser}
                    </div>
                    <div className="px-2 firm-admin-titles-value">
                      {monthlySessionsPerUser || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={4} sm={12}>
                  <div
                    className={`pt-2 bg-red firm-admin-report-tiles firm-admin-clickable-tile`}
                  >
                    <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header-mob">
                      {reportsPlaceholder.willsUploaded}
                    </div>
                    <div className="px-2 firm-admin-titles-value">
                      {willsUploaded || 0}
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={6} sm={12}>
                  <div
                    className={`pt-2 bg-green firm-admin-report-tiles firm-admin-clickable-tile`}
                  >
                    <div className="px-2 firm-admin-reports-tiles-mob firm-admin-titles-header-mob">
                      {reportsPlaceholder.deviceOfChoice}
                    </div>
                    <div className="px-2 firm-admin-titles-value">
                      <div className="row d-flex py-1">
                        <div className="col-4 div-dashed">
                          <div className="firm-admin-device-title">Desktop</div>
                          <div>{desktopCount || 0}</div>
                        </div>
                        <div className="col-4 div-dashed">
                          <div className="firm-admin-device-title">Tablet</div>
                          <div>{tabletCount || 0}</div>
                        </div>
                        <div className="col-4">
                          <div className="firm-admin-device-title">Mobile</div>
                          <div>{mobileCount || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="p-3" lg={2} md={4} sm={12}>
                  <div
                    className={`pt-2 bg-purple firm-admin-report-tiles firm-admin-clickable-tile`}
                  >
                    <div className="px-2 firm-admin-reports-tiles-device firm-admin-titles-header-mob">
                      {reportsPlaceholder.mostActiveUser}
                    </div>
                    <div className="px-2 firm-admin-titles-value-active-user">
                      {mostActiveUser
                        ? `${capitalize(mostActiveUser?.userName)}: ${
                            mostActiveUser?.timeSpent
                          } Per Week`
                        : `--`}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-none d-lg-block">
            <div className="py-3 px-3 d-flex justify-content-center justify-content-space-between gap-4 flex-wrap">
              <div
                className={`pt-2 bg-blue firm-admin-report-tiles firm-admin-clickable-tile`}
              >
                <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header">
                  {reportsPlaceholder.totalUsers}
                </div>
                <div className="px-2 firm-admin-titles-value">
                  {totalUsers || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-grey firm-admin-report-tiles firm-admin-clickable-tile`}
              >
                <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header">
                  {reportsPlaceholder.monthlySessionPerUser}
                </div>
                <div className="px-2 firm-admin-titles-value">
                  {monthlySessionsPerUser || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-red firm-admin-report-tiles firm-admin-clickable-tile`}
              >
                <div className="px-2 firm-admin-reports-tiles firm-admin-titles-header">
                  {reportsPlaceholder.willsUploaded}
                </div>
                <div className="px-2 firm-admin-titles-value">
                  {willsUploaded || 0}
                </div>
              </div>
              <div
                className={`pt-2 bg-green firm-admin-report-tiles firm-admin-clickable-tile`}
              >
                <div className="px-2 firm-admin-reports-tiles-mob firm-admin-titles-header">
                  {reportsPlaceholder.deviceOfChoice}
                </div>
                <div className="px-2 firm-admin-titles-value">
                  <div className="row d-flex py-1">
                    <div className="col-4 div-dashed">
                      <div className="firm-admin-device-title">Desktop</div>
                      <div>{desktopCount || 0}</div>
                    </div>
                    <div className="col-4 div-dashed">
                      <div className="firm-admin-device-title">Tablet</div>
                      <div>{tabletCount || 0}</div>
                    </div>
                    <div className="col-4">
                      <div className="firm-admin-device-title">Mobile</div>
                      <div>{mobileCount || 0}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`pt-2 bg-purple firm-admin-report-tiles firm-admin-clickable-tile`}
              >
                <div className="px-2 firm-admin-reports-tiles-device firm-admin-titles-header">
                  {reportsPlaceholder.mostActiveUser}
                </div>
                <div className="px-2 firm-admin-titles-value-active-user">
                  {mostActiveUser
                    ? `${capitalize(mostActiveUser?.userName)}: ${
                        mostActiveUser?.timeSpent
                      } Per Week`
                    : `--`}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 search-container d-block d-sm-none">
            <div className="firm-admin-time-window pb-3">Time Window = Last 90 days</div>
            <div className="search-icon">
              <AGSearchIcon /> 
            </div>
            <div className="search-input-field ">
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                autoComplete="off"
                onChange={handleSearchChange}
                id="search-firm-input-field"
                data-testid="grid-search-input"
              />
            </div>
            {searchText && (
              <div
                onClick={handleClearSearch}
                className="clear-search-button-mb"
              >
                <SearchXIcon />
              </div>
            )}
          </div>

          <div className="firm-admin-reports-second-div py-2">
            <div className="d-flex px-3 justify-content-between align-items-center">
              <div className="firm-admin-reports-table-title d-flex">
                <div className="firm-admin-time-window">Time Window = Last 90 days</div>
              </div>
              <div className="d-flex gap-3">
                <div className="d-flex">
                  <div className="search-container d-none d-md-block">
                    <div className="search-icon">
                      <AGSearchIcon />
                    </div>
                    <div className="search-input-field flex-1">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        autoComplete="off"
                        onChange={handleSearchChange}
                        id="search-firm-input-field"
                        data-testid="grid-search-input"
                      />
                    </div>
                    {searchText && (
                      <div
                        onClick={handleClearSearch}
                        className="clear-search-button"
                      >
                        <SearchXIcon />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div>
                    <Dropdown>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip className="tooltip">Download</Tooltip>
                        }
                      >
                        <Dropdown.Toggle
                          as="div"
                          className="ms-2 cursor-pointer"
                          data-testid="download"
                        >
                          <Download />
                        </Dropdown.Toggle>
                      </OverlayTrigger>
                      <Dropdown.Menu align="right">
                        <Dropdown.Item onClick={() => handleDownload("PDF")}>
                          {reportPlaceholder.downloadAsPDF}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDownload("CSV")}>
                          {reportPlaceholder.downloadAsCSV}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="tooltip">Share</Tooltip>}
                    >
                      <div className="ms-3 cursor-pointer">
                        <Share onClick={handleShare} data-testid="share" />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-2 px-3">
            <AgGrid
              quickFilterText={searchText}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowStyle={getRowStyle}
              paginationSizeLimit={paginationSizeLimit}
              rowSelection={"multiple"}
            />
            {(isLoading || download) && <Loader data-testid="loader" />}
          </div>
        </div>
      </div>
      <ShareReportModalPopup
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        bodyData={[...headerRow,...filteredFields]}
        shareFileName={fileName}
      />
    </>
  );
}
