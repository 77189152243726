import React, { useCallback, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LinkContainer } from "react-router-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTitle from "../components/form/FormTitle";
import { emailRules } from "../constants/regExp";
import {
  splogintitle,
  sploginEmailLabel,
  sploginPasswordLabel,
  sploginButton,
  sploginEmailReqValidation,
  sploginpasswordReqValidation,
  sploginEmailValidation,
  forgotPasswordLoginPageLabel,
} from "../constants/labels";
import { useNavigate } from "react-router-dom";
import {
  mvssuperadmin_landingpage,
  otpverification,
  resettemporarypassword,
  forgotPasswordEmailPage,
  famVault_wesbite,
  external_user_share_landing_page,
  firmsuperadmin_LandingPage,
  attorney_LandingPage,
  client_LandingPage,
  familymember_landingpage,
  executor_LandingPage,
  additional_prof_landingpage,
} from "../constants/routes";
import Header from "../components/headers/Header";
import { useSigninmvsMutation } from "../slices/loginAuthManagementSlice";
import { toast } from "react-toastify";
import { setCredentials, signOutApiCall } from "../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader/Loader";
import { ReactComponent as WebLogo } from "../assets/images/MVS-logo-web-svg.svg";
import { ROLE } from "../constants/role";
import { useLocation } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasRun = useRef(false);
  const { userInfo } = useSelector((state) => state.auth);
  const isAutomationEnabled = process.env.REACT_APP_AUTOMATION_TESTING_ENABLED;
const testUserStartsWith = process.env.REACT_APP_AUTOMATION_TEST_USER_STARTSWITH;

  const [signinmvs, { isLoading }] = useSigninmvsMutation();
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const schema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
    password: yup.string().required(`${sploginpasswordReqValidation}`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  useEffect(() => {
    if (location?.state?.email) {
      setValue("email", location?.state?.email);
    }
  }, [location, setValue]);

  const handleLogout = useCallback(async () => {
    try {
      dispatch(signOutApiCall());
      window.location.href = `${famVault_wesbite}`
      const url = `${process.env.REACT_APP_BASE_URL_USERSERVICE}/api/v1/login/signOut?accesstoken=${userInfo?.accessToken}`;
      navigator.sendBeacon(url);
      toast.success("Signed out successfully..."); 
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      const fromLogin = sessionStorage.getItem("fromLogin");
      const fromSSO = sessionStorage.getItem("fromSSO");
      if (!fromSSO && !fromLogin) {
        userInfo?.accessToken && handleLogout();
      }
    }
  }, [handleLogout, userInfo?.accessToken]);

  const onSubmit = async (formData) => {
    //console.log(formData);
    const transformData = {
      userName: formData.email,
      password: formData.password,
    };

    const data = JSON.stringify(transformData);
    try {
      const res = await signinmvs(data).unwrap();
      // console.log(res);
      dispatch(setCredentials({ ...res }));
      if(isAutomationEnabled === "true" && formData.email.startsWith(testUserStartsWith)){
        if (res?.role === ROLE.firmsuperadmin) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(firmsuperadmin_LandingPage, {
              state: {
                id: res?.firmTypeId,
              },
            });
          }
        }
        if (res?.role === ROLE.attorney) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(attorney_LandingPage);
          }
        }
        if (res?.role === ROLE.client) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${client_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${client_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(client_LandingPage);
          }
        }
        if (res?.role === ROLE.familymember) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${familymember_landingpage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${familymember_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(familymember_landingpage);
          }
        }
        if (res?.role === ROLE.executor) {
          if (
            identifierID &&
            requestType &&
            requestType !== "addProf" &&
            requestType !== "deathClaim"
          ) {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(executor_LandingPage);
          }
        }
        if (res?.role === ROLE.externaluser) {
          if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${external_user_share_landing_page}/additionproflanding?identifier=${identifierID}&type=${requestType}`
            );
          } else if (identifierID && requestType && requestType === "deathClaim") {
            navigate(
              `${external_user_share_landing_page}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(
              `${external_user_share_landing_page}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
            );
          }
        }
        if (res?.role === ROLE.additionalProf) {
          if (identifierID && requestType && requestType === "addProf") {
            navigate(
              `${additional_prof_landingpage}?identifier=${identifierID}&type=${requestType}`
            );
          } else if (
            identifierID &&
            requestType &&
            requestType === "deathClaim"
          ) {
            navigate(
              `${additional_prof_landingpage}/reporterlanding?identifier=${identifierID}&type=${requestType}`
            );
          } else {
            navigate(additional_prof_landingpage);
          }
        }
      }
      else{
      if (res?.successful && res?.message !== "Please create new password") {
        sessionStorage.setItem("fromLogin", "true");
        if (res?.role === ROLE.mvssuperadmin) {
          toast.success(res?.message);
          navigate(mvssuperadmin_landingpage);
          sessionStorage.removeItem("fromLogin");
          sessionStorage.removeItem("fromSSO");
        }
        if (res?.role === ROLE.firmsuperadmin) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.attorney) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.client) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.familymember) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.executor) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.externaluser) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
        if (res?.role === ROLE.additionalProf) {
          navigate(otpverification, {
            state: {
              userName: formData.email,
              password: formData.password,
              page: "login",
              role: res?.role,
              sessionId: res?.session,
            },
          });
        }
      } else if (
        res?.successful &&
        res?.message === "Please create new password"
      ) {
        navigate(resettemporarypassword, {
          state: {
            userName: formData.email,
            password: formData.password,
            page: "login",
            role: res?.role,
            sessionId: res?.session,
          },
        });
      } 
      else {
        toast.error(res?.message);
      }
    }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Container
        className="login-container"
        data-testid="login-test-container"
        fluid
        style={{ padding: "0px" }}
      >
        <div>
          <div className="w-100 d-lg-none">
            <Header />
          </div>
          <Row>
            <Col lg={7} className="d-none d-lg-block p-0">
              <div className="login-web-image">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <WebLogo />
                </div>
              </div>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="d-flex align-items-center justify-content-center p-0"
            >
              <div className="form-container">
                <Form
                  className="custom-form w-100 px-lg-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormTitle title={splogintitle} />
                  <Form.Group className="mb-3">
                    <Form.Label className="required" htmlFor={`email`}>
                      {sploginEmailLabel}
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      {...register("email")}
                      isInvalid={!!errors.email}
                      onBlur = {(e) => e.target.value = e.target.value.trim()}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label className="required" htmlFor={`password`}>
                      {sploginPasswordLabel}
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="off"
                      {...register("password")}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-2 mt-2">
                    <LinkContainer to={forgotPasswordEmailPage}>
                      <span className="forgot-password-label">
                        {forgotPasswordLoginPageLabel}
                      </span>
                    </LinkContainer>
                  </div>
                  <div className="w-100 pt-3">
                    <Button
                      className="custom-button btn-standard btn-login"
                      type="submit"
                      data-testid="submit-button"
                    >
                      {sploginButton}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <div className="tick-login"></div>
        </div>
      </Container>
      {isLoading && <Loader />}
    </>
  );
}
