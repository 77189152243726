import React from "react";
export default function PasswordCriteria() {
  return (
    <ul className="password-rules pt-1 pb-2 ps-3">
      <li className="password-rules-item">
        Atleast 12 characters long but 14 or more is better.
      </li>
      <li className="password-rules-item">
        A combination of uppercase letters, lowercase letters, numbers, and
        special characters
      </li>
      <li className="password-rules-item">
        {/* Special characters allowed are -!, @, #, $, %, _, -, (, ) */}
        Special characters allowed are ^$*.[]&#123;&#125;()?-"!@#%&/\&gt;&lt;':;|_~`+=.,
      </li>
      {/* <li className="password-rules-item">
        {" "}
        Should not be the same as last three passwords
      </li> */}
    </ul>
  );
}
