import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { validation } from "../../constants/firm/validationsMessages";
import {
  phoneNumberRules,
  emailRules,
  zipRules,
  alphabetRules,
  indphonNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { formPlaceholder } from "../../constants/firm/labels";
import { useGetGeoCodesZipCodeQuery } from "../../slices/ssoManagementSlice";
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../slices/superAdminApiManagementSlice";
import {
  Offcanvas,
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import ModalPopupType2 from "../modal/ModalPopupType2";
import Loader from "../Loader/Loader";
import PhoneInput from "react-phone-input-2";
import { signOutApiCall } from "../../slices/authSlice";
import { countryCodeList } from "../../constants/labels";

export default function AttorneyOffCanvasEditProfile({
  visible,
  onClose,
  state,
}) {
  const [cities, setCities] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [empData, setEmpData] = useState({});
  const { userInfo } = useSelector((state) => state.auth);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const location = useLocation();
  const dispatch =  useDispatch();
  const schema = yup.object().shape({
    firstName: yup
      .string().trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string().trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    addressLine1: yup.string().trim().required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    zip: yup
      .string().trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: yup.string().required(`${validation.city.required}`).nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const { data, error, isLoading } = useGetEmployeeQuery(state?.id, {
    skip: state?.id === undefined,
  });

  const [editEmployee, { isLoading: editEmployeeLoading }] =
    useEditEmployeeMutation();
  const { data: geoCodeData, isLoading: geoCodeLoading, error: geoCodeError } =
    useGetGeoCodesZipCodeQuery(zipCode, {
      skip: zipCode?.length !== 5,
    });

  useEffect(() => {
    if (data?.attorneyId) {
      if (data?.attorneyId && data?.phone) {
        if (data?.phone.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${data?.phone}`);
        } else {
          const firstDigit = data.phone.charAt(1);
          const lasttendigit = data.phone.slice(-10);

          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  useEffect(() => {
    if (data?.attorneyId) {
      setEmpData({
        userId: data?.attorneyId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        address: data?.address,
      });
      setValue("firstName", data.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", data.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", data.email, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("addressLine1", data.address.addressLine1, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("addressLine2", data.address.addressLine2, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("zip", data.address.zip, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("city", data.address.city, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("state", data.address.state, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("country", data.address.country, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [setValue, location?.state?.id, data]);
  useEffect(() => {
    const data = geoCodeData;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, state, country);
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States");
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, country, "United States");
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities([]);
        setValueZip("", "", "");
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities([]);
          setValueZip("", "", "");
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities([]);
          setValueZip("", "", "");
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData]);
  const setValueZip = (city, state, country) => {
    setValue("city", city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("state", state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("country", country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const handleViewPageNavigation = () => {
    onClose();
  };
  const onSubmit = async (formData) => {
    const transformData = {
      userId: userInfo?.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: `+${formData.phone}`,
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2 || "",
        zip: formData.zip,
        city: formData.city === null ? formData.cityList : formData.city,
        state: formData.state,
        country: formData.country,
      },
      roleName:userInfo?.role
    };
    const data = JSON.stringify(transformData);
    const existData = JSON.stringify(empData);
    const attorneyId = userInfo?.userId;
    const attData = {
      attorneyId,
      data
    }

    if (data !== existData) {
      try {
        const res = await editEmployee(attData).unwrap();
        if (res?.isSuccessful) {
          toast.success("Profile details updated");
          if(empData.email !== formData.email){
            dispatch(signOutApiCall());
            toast.success(`Signed out successfully`);
          }
          else{
            handleViewPageNavigation();
          }
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    } else {
      onClose();
    }
  };
  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "zip") {
      event.target.value = value.slice(0, 5);
      setZipCode(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const handleUpdateCheck = () => {
    if (
      empData.firstName === getValues("firstName") &&
      empData.lastName === getValues("lastName") &&
      empData.email === getValues("email") &&
      empData.phone === `+${getValues("phone")}` &&
      empData.address.addressLine1 === getValues("addressLine1") &&
      empData.address.addressLine2 === getValues("addressLine2") &&
      empData.address.zip === getValues("zip")
    ) {
      onClose();
    } else {
      setShowModal(true);
    }
  };
  if (error || geoCodeError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div data-testid="attorney-edit-profile">
        <Offcanvas
          show={visible}
          onHide={handleUpdateCheck}
          placement="end"
          id="attorney-client-off-canvas"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Profile</Offcanvas.Title>
          </Offcanvas.Header>
          <div className="px-3">
            <div className="divider-firm"></div>
          </div>
          <Offcanvas.Body>
            <div>
              <div className="unset-height">
                <Form
                  className="custom-form employee-form px-2 px-lg-4 px-md-4 type-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col lg={12} md={12} className="mt-4">
                      <Row>
                        <Col lg={6} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label
                              htmlFor="emp-firstname"
                              className="required mb-0"
                            >
                              {formPlaceholder.firstName}
                            </Form.Label>
                            <Form.Control
                              className="form-input"
                              type="text"
                              name="firstName"
                              autoComplete="off"
                              maxLength={30}
                              {...register("firstName")}
                              isInvalid={!!errors.firstName}
                              data-testid="emp-firstname"
                              id="emp-firstname"
                            />
                            <Form.Control.Feedback
                              data-testid="invalid-feedback"
                              type="invalid"
                            >
                              {errors.firstName?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={6} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label
                              htmlFor="emp-lastname"
                              className="required mb-0"
                            >
                              {formPlaceholder.lastName}
                            </Form.Label>
                            <Form.Control
                              className="form-input input-border-radius-unset"
                              type="text"
                              name="lastName"
                              maxLength={30}
                              autoComplete="off"
                              {...register("lastName")}
                              isInvalid={!!errors.lastName}
                              data-testid="emp-lastname"
                              id="emp-lastname"
                            />
                            <Form.Control.Feedback
                              data-testid="invalid-feedback"
                              type="invalid"
                            >
                              {errors.lastName?.message}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-email"
                          className="required mb-0"
                        >
                          {formPlaceholder.email}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="email"
                          name="email"
                          autoComplete="off"
                          {...register("email")}
                          isInvalid={!!errors.email}
                          onBlur = {(e) => e.target.value = e.target.value.trim()}
                          data-testid="emp-email"
                          id="emp-email"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.email?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-phone"
                          className="required mb-0"
                        >
                          {formPlaceholder.phone}
                        </Form.Label>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field }) => (
                            <div
                              className={
                                errors.phone
                                  ? "phone-input-invalid-container"
                                  : ""
                              }
                            >
                              <PhoneInput
                                {...field}
                                defaultCountry="us"
                                onlyCountries={countryCodeList}
                                placeholder=""
                                title=""
                                country={"us"}
                                inputProps={{
                                  required: true,
                                  autoFocus: false,
                                  title: "",
                                }}
                                countryCodeEditable={false}
                                onChangeCapture={handleMaxNumber}
                                onChange={(value, phone) => {
                                  if (
                                    phone.dialCode === "1" &&
                                    phone.countryCode === "us"
                                  ) {
                                    setCountryCode("+1");
                                    setPhoneNumber(value);
                                  }
                                  if (
                                    phone.dialCode === "91" &&
                                    phone.countryCode === "in"
                                  ) {
                                    setCountryCode("+91");
                                    setPhoneNumber(value);
                                  }
                                }}
                              />
                            </div>
                          )}
                        />
                        <Form.Control
                          className="form-input input-border-radius-unset input_number"
                          type="hidden"
                          name="phone"
                          onKeyDown={handleKeyPress}
                          onChangeCapture={handleMaxNumber}
                          autoComplete="off"
                          {...register("phone")}
                          isInvalid={!!errors.phone}
                          data-testid="emp-phone"
                          id="emp-phone"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.phone?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-addressLine1"
                          className="required mb-0"
                        >
                          {formPlaceholder.addressLine1}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="addressLine1"
                          maxLength={255}
                          autoComplete="off"
                          {...register("addressLine1")}
                          isInvalid={!!errors.addressLine1}
                          data-testid="emp-addressLine1"
                          id="emp-addressLine1"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.addressLine1?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-addressLine2"
                          className="form-label mb-0"
                        >
                          {formPlaceholder.addressLine2}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="addressLine2"
                          maxLength={255}
                          autoComplete="off"
                          {...register("addressLine2")}
                          isInvalid={!!errors.addressLine2}
                          data-testid="emp-addressLine2"
                          id="emp-addressLine2"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.addressLine2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="emp-zip" className="required mb-0">
                          {formPlaceholder.zip}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset input_number"
                          type="number"
                          name="emp-zip"
                          autoComplete="off"
                          onKeyDown={handleKeyPress}
                          onChangeCapture={handleMaxNumber}
                          {...register("zip")}
                          isInvalid={!!errors.zip}
                          data-testid="emp-zip"
                          id="emp-zip"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.zip?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-city"
                          className="required mb-0"
                        >
                          {formPlaceholder.city}
                        </Form.Label>
                        {cities?.length > 0 && (
                          <>
                            <Form.Select
                              aria-label="Default select example"
                              className="form-input input-border-radius-unset select-placeholder"
                              name="cityList"
                              isInvalid={!!errors.cityList}
                              {...register("cityList")}
                              id="emp-cities"
                              data-testid="emp-cities"
                            >
                              <option value="" hidden></option>
                              {cities?.length > 0 &&
                                cities.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback
                              data-testid="invalid-feedback"
                              type="invalid"
                            >
                              {errors.cityList?.message}
                            </Form.Control.Feedback>
                          </>
                        )}
                        {cities?.length === 0 && (
                          <>
                            <Form.Control
                              className="form-input input-border-radius-unset"
                              type="text"
                              name="city"
                              autoComplete="off"
                              {...register("city")}
                              isInvalid={!!errors.city}
                              disabled
                              data-testid="emp-city"
                              id="emp-city"
                            />
                            <Form.Control.Feedback
                              data-testid="invalid-feedback"
                              type="invalid"
                            >
                              {errors.city?.message}
                            </Form.Control.Feedback>
                          </>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-state"
                          className="required mb-0"
                        >
                          {formPlaceholder.state}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="state"
                          autoComplete="off"
                          {...register("state")}
                          isInvalid={!!errors.state}
                          data-testid="emp-state"
                          id="emp-state"
                          disabled
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.state?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="emp-country"
                          className="required mb-0"
                        >
                          {formPlaceholder.country}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="country"
                          autoComplete="off"
                          {...register("country")}
                          isInvalid={!!errors.country}
                          data-testid="emp-country"
                          id="emp-country"
                          disabled
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.country?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mt-lg-2">
                    <Button
                      type="submit"
                      className="custom-button btn-standard btn-update mx-2"
                      data-testid="update-button"
                    >
                      {formPlaceholder.updateBtnText}
                    </Button>
                    <Button
                      className="custom-button btn-nobg ms-2"
                      data-testid="cancel-button"
                      onClick={handleUpdateCheck}
                    >
                      {formPlaceholder.cancelBtnText}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <ModalPopupType2
              setShowCallback={() => setShowModal(false)}
              setYesCallback={handleViewPageNavigation}
              content={`Are you sure you want to cancel the changes?`}
              showModal={showModal}
            />
            {(geoCodeLoading || editEmployeeLoading || isLoading) && <Loader />}
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}
