import React, { useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import bytes from "bytes";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import axios from "axios";
import { signOutApiCall } from "../../slices/authSlice";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router";
import { ROLE } from "../../constants/role";
import { ReactComponent as Print } from "../../assets/images/print.svg";
import { ReactComponent as Share } from "../../assets/images/sharedocument.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { useDeleteDocumentMutation } from "../../slices/documentApiManagement";
import ModalPopupType2 from "../modal/ModalPopupType2";
import ShareDocumentModalPopup from "../modal/ShareDocumentModalPopup";
import ModalPopup from "../modal/ModalPopup";
import SuccessModalContent from "../widgets/SuccessModalContent";
import Loader from "../Loader/Loader";
import { sharedDocSuccessText } from "../../constants/labels";
import {
  attorney_LandingPage,
  client_LandingPage,
  executor_LandingPage,
} from "../../constants/routes";
export default function NewFolderDocumentDetails({ documentGroup }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showDocModal, setShowDocModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [progressMap, setProgressMap] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [docDetails, setDocDetails] = useState(null);
  const [selectedDocs] = useState("");
  const [docId, setDocId] = useState("");
  const [deleteDocument, { isLoading: docDeleteLoading }] =
    useDeleteDocumentMutation();
  function isVideoFile(filename) {
    // Define a regular expression pattern to match video file extensions
    const videoFileExtensions =
      /\.(mp4|m4v|mkv|mov|avi|wmv|flv|webm|mpeg|mpg|3gp|3g2)$/i;

    // Test the filename against the pattern
    return videoFileExtensions.test(filename);
  }
  const handleDownload = async (docId, docName) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            setLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        if (userInfo?.role === ROLE.attorney) {
          ReactGA.event(`download_other_documents_confirmation`, {
            category: `${ROLE.attorney} - 3 Level`,
            action: `Download other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
            label: `Download other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
            others_downloaded: 1,
            others_downloaded_filesize: response?.data?.size,
          });
        }
        if (userInfo?.role === ROLE.client) {
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`download_other_documents_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Download other Documents Confirmation - ${ROLE.client} - 3 Level`,
              label: `Download other Documents Confirmation - ${ROLE.client} - 3 Level`,
              others_downloaded: 1,
              others_downloaded_filesize: response?.data?.size,
            });
          firmType === 2 &&
            ReactGA.event(`download_other_documents_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Download other Documents Confirmation - ${ROLE.client} - 2 Level`,
              label: `Download other Documents Confirmation - ${ROLE.client} - 2 Level`,
              others_downloaded: 1,
              others_downloaded_filesize: response?.data?.size,
            });
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${docName}`;
        a.setAttribute("id", "download-doc");
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // Handle downloaded file data
        toast.success(`${docName} has been downloaded successfully!`);
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", error);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };
  const handlePrint = async (docId, docName) => {
    setLoading(true);
    const baseUrl = `${
      process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE
    }/api/v1/documents/${docId}?convertPdf=${true}`;
    //const baseUrlwithPdf = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/${docId}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
      });

      if (response.status === 200) {
        setLoading(false);
        const url = URL.createObjectURL(response?.data);
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.onload = function () {
          iframe.contentWindow.print();
        };
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      toast.error(`Error in Printing the file: ${error.message}`);
    }
  };
  const handleDelete = (docId, docName) => {
    setConfirmModal(true);
    setDocDetails({ docId, docName });
  };
  const handleShare = (docId) => {
    setDocId(docId);
    setShowDocModal(true);
  };
  const handleNavigation = () => {
    setSuccessModal(false);
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    }
    if (userInfo?.role === ROLE.client) {
      navigate(client_LandingPage);
    }
    if (userInfo?.role === ROLE.executor) {
      navigate(executor_LandingPage);
    }
  };
  const handleViewPageNavigation = async () => {
    const { docId } = docDetails;
    try {
      const res = await deleteDocument(docId).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        setConfirmModal(false);
      } else {
        setConfirmModal(false);
        toast.error(res?.message);
      }
    } catch (err) {
      setConfirmModal(false);
      console.error("Error deleting document:", err);
      toast.error(err?.error || err?.data?.error);
    }
  };
  return (
    <>
      <div className="new-folder-document-list pt-3">
        <Card
          key={documentGroup[0].rootDocTypeId}
          className="custom-card mb-1 custom-card-shadow"
        >
          <Card.Header className="custom-card-header">
            <div className="d-flex">
              <div className="ps-3"></div>
            </div>
          </Card.Header>
          <Card.Body className="custom-card-body">
            <div className="doc-section">
              <div className="doc-inner-section multiple d-flex justify-content-between d-block d-md-none ">
                {documentGroup &&
                  documentGroup.length > 0 &&
                  documentGroup.map((file, index) => (
                    <div key={index} className="position-relative">
                      <div>
                        <div>File Name</div>
                        <div
                          className="file-name-others"
                          onClick={() =>
                            handleDownload(file.docId, file.docName)
                          }
                        >
                          {file.docName}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div>Uploaded by</div>
                        <div>{file.uploadedBy}</div>
                      </div>
                      <div className="mt-2">
                        <div>Date and Time</div>
                        <div>
                          {dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div>Size</div>
                        <div>
                          {bytes.format(file.docSize, {
                            unitSeparator: " ",
                          })}
                        </div>
                      </div>
                      {userInfo?.role === ROLE.client && (
                        <div className="mt-2">
                          <div className="delete-doc-others d-flex small">
                            <div className="me-3">
                              <Download
                                onClick={() =>
                                  handleDownload(file.docId, file.docName)
                                }
                                data-testid="download"
                              />
                            </div>
                            
                              <div className="me-3">
                                <Share
                                  onClick={() => handleShare(file.docId)}
                                  data-testid="share"
                                />
                              </div>
                            
                            {!isVideoFile(file.docName) && (
                              <div className="me-3">
                                <Print
                                  onClick={() =>
                                    handlePrint(file.docId, file.docName)
                                  }
                                  data-testid="print"
                                />
                              </div>
                            )}
                            <div>
                              <DeleteAction
                                onClick={() =>
                                  handleDelete(file.docId, file.docName)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {userInfo?.role === ROLE.executor && (
                        <div className="mt-2">
                          <div className="delete-doc-others d-flex small">
                            <div className="me-3">
                              <Download
                                onClick={() =>
                                  handleDownload(file.docId, file.docName)
                                }
                                data-testid="download"
                              />
                            </div>
                            {!isVideoFile(file.docName) && (
                              <div className="me-3">
                                <Print
                                  onClick={() =>
                                    handlePrint(file.docId, file.docName)
                                  }
                                  data-testid="print"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {index === documentGroup.length - 1 ? null : <hr />}
                    </div>
                  ))}
              </div>
              <div className="d-none d-md-block">
                {/* <Table className="table table-responsive table-custom-document"> */}
                <table className="table-responsive w-100 new-folder-table-td">
                  <thead className="table-custom-document">
                    <tr>
                      <td style={{ width: "30%" }}>File Name</td>
                      <td style={{ width: "20%" }}>Uploaded by</td>
                      <td style={{ width: "20%" }}>Date and Time</td>
                      <td style={{ width: "15%" }}>Size</td>
                      {userInfo?.role !== ROLE.attorney && (
                        <td style={{ width: "15%" }}></td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {documentGroup &&
                      documentGroup.length > 0 &&
                      documentGroup.map((file, index) => (
                        <tr key={index}>
                          <td>
                            <div className="file-name-others">
                              <div>{file.docName}</div>
                            </div>
                          </td>
                          <td>{file.uploadedBy}</td>
                          <td>
                            {dayjs(file.modifiedAt).format("MM/DD/YYYY h:mm A")}
                          </td>
                          <td>
                            {bytes.format(file.docSize, {
                              unitSeparator: " ",
                            })}
                          </td>
                          {userInfo?.role !== ROLE.attorney && (
                            <td>
                              <div className="d-flex align-items-center icon-document-web-section">
                                {userInfo?.role === ROLE.client && (
                                  <>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip className="tooltip">
                                          Download
                                        </Tooltip>
                                      }
                                    >
                                      <div className="me-4">
                                        <Download
                                          onClick={() =>
                                            handleDownload(
                                              file.docId,
                                              file.docName
                                            )
                                          }
                                          data-testid="download"
                                        />
                                      </div>
                                    </OverlayTrigger>
                                    
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip className="tooltip">
                                            Share
                                          </Tooltip>
                                        }
                                      >
                                        <div className="me-4">
                                          <Share
                                            onClick={() =>
                                              handleShare(file.docId)
                                            }
                                            data-testid="share"
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    
                                    {!isVideoFile(file.docName) && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip className="tooltip">
                                            Print
                                          </Tooltip>
                                        }
                                      >
                                        <div className="me-4">
                                          <Print
                                            onClick={() =>
                                              handlePrint(
                                                file.docId,
                                                file.docName
                                              )
                                            }
                                            data-testid="print"
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip className="tooltip">
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <div className="me-4">
                                        <DeleteAction
                                          onClick={() =>
                                            handleDelete(
                                              file.docId,
                                              file.docName
                                            )
                                          }
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </>
                                )}

                                {userInfo?.role === ROLE.executor && (
                                  <>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip className="tooltip">
                                          Download
                                        </Tooltip>
                                      }
                                    >
                                      <div className="me-4">
                                        <Download
                                          onClick={() =>
                                            handleDownload(
                                              file.docId,
                                              file.docName
                                            )
                                          }
                                          data-testid="download"
                                        />
                                      </div>
                                    </OverlayTrigger>

                                    {!isVideoFile(file.docName) && (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip className="tooltip">
                                            Print
                                          </Tooltip>
                                        }
                                      >
                                        <div className="me-4">
                                          <Print
                                            onClick={() =>
                                              handlePrint(
                                                file.docId,
                                                file.docName
                                              )
                                            }
                                            data-testid="print"
                                          />
                                        </div>
                                      </OverlayTrigger>
                                    )}
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>

                {/* </Table> */}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {(isLoading || docDeleteLoading) && <Loader />}
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId, { progress, docName }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>{docName} downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        setYesCallback={handleViewPageNavigation}
        content={`Are you sure want to delete (${docDetails?.docName}) the  file?`}
        showModal={showConfirmModal}
      />

      <ShareDocumentModalPopup
        showModal={showDocModal}
        setShowCallback={() => setShowDocModal(false)}
        docId={docId}
        selectedDocs={selectedDocs}
        setSuccessCallback={() => {
          setShowDocModal(false);
          setSuccessModal(true);
        }}
      />

      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={sharedDocSuccessText} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handleNavigation}
        animation={false}
      />
    </>
  );
}
