import { ColorRing } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="backdrop-spinner">
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="position-absolute top-50 start-50 translate-middle"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
        data-testid = "loading-state"
      />
    </div>
  );
};

export default Loader;
