import { stackHolderApiSlice } from "./stackHolderApiSlice";

const stackHolderApiManagement= stackHolderApiSlice.injectEndpoints({
    endpoints:(builder) => ({
        getStakeHolderDetails: builder.query({
            query: (clientId) => ({
              url: `/${clientId}`,
              method: "GET",
            }),
            providesTags: ['getStakeHolderDetails'],
          }),
        addPeople:builder.mutation({
            query:(data) => ({
                url:"/",
                method:"POST",
                body:data
            }),
            invalidatesTags: ['getStakeHolderDetails', "getAllDocsByRootId"]            
        }),
        updateStakeHolderDetails:builder.mutation({
            query:({dataPeople,clientId})=>({
                url:`/${clientId}`,
                method:"PUT",
                body:dataPeople
            }),
            invalidatesTags: ['getStakeHolderDetails']
        })
    })
})

export const { 
    useAddPeopleMutation, 
    useUpdateStakeHolderDetailsMutation,
    useLazyGetStakeHolderDetailsQuery,
    useGetStakeHolderDetailsQuery    
} = stackHolderApiManagement;