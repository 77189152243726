import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FormTitle from "../components/form/FormTitle";
import { forgotPasswordSuccessPage } from "../constants/routes";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { passwordRules } from "../constants/regExp";
import { resetPasswordButton } from "../constants/labels";
import Header from "../components/headers/Header";
import { inputFocus } from "../utils/inputMethod";
import PasswordCriteria from "../components/widgets/PasswordCriteria";
import { useResetUserPasswordMutation } from "../slices/loginAuthManagementSlice";
import Loader from "../components/Loader/Loader";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const schema = yup.object().shape({
    newpassword: yup
      .string()
      .required("New Password is Required")
      .matches(passwordRules, { message: "Enter the valid Password" }),
    confirmpassword: yup
      .string()
      .oneOf(
        [yup.ref("newpassword"), null],
        "New Password and Confirm Password should match"
      ),
    photp1: yup.number().required(),
    photp2: yup.number().required(),
    photp3: yup.number().required(),
    photp4: yup.number().required(),
    photp5: yup.number().required(),
    photp6: yup.number().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const [trigger, { isLoading }] = useResetUserPasswordMutation();
  const handleDigitChange = (event, value) => {
    if (event.target.value.length > 1) {
      setValue(value, event.target.value[event.target.value.length - 1]);
    } else {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      setValue(value, numericValue);
    }
  };
  const onSubmit = async (formData) => {
    const otp = Object.values(formData).toString().replaceAll(",", "");
    const transformData = {
      userName: location?.state?.useremail,
      newPassword: formData.newpassword,
      passCode: otp.slice(0, 6).split("").reverse().join(""),
    };
    const data = JSON.stringify(transformData);
    try {
      const res = await trigger(data).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        navigate(forgotPasswordSuccessPage);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.errorMessage);
    }
  };
  return (
    <>
      <Header />
      <div className="otp-forgot-password-container">
        <Container fluid>
          <Row>
            <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="reset-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-forgotpassword-container inner-reset-container">
                  <Form
                    className="custom-form forgot-password-form type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle
                      title={resetPasswordButton}
                      variant="variant-5"
                    />
                    <Form.Group className="pt-3">
                      <Form.Label
                        htmlFor="photp1"
                        className="custom-form-label custom-label-otp"
                      >
                        Enter the <span className="f-w-700">OTP</span> sent to
                        your <span className="f-w-700">Email</span>
                      </Form.Label>
                      <div className="ms-0 d-flex justify-content-between">
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp1"
                          name="photp1"
                          data-testid="photp1-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          autoFocus={true}
                          min={0}
                          max={9}
                          {...register("photp1")}
                          onChange={(e) => handleDigitChange(e, "photp1")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="1"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp2"
                          name="photp2"
                          data-testid="photp2-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp2")}
                          onChange={(e) => handleDigitChange(e, "photp2")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="2"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp3"
                          name="photp3"
                          data-testid="photp3-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp3")}
                          onChange={(e) => handleDigitChange(e, "photp3")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="3"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp4"
                          name="photp4"
                          data-testid="photp4-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp4")}
                          onChange={(e) => handleDigitChange(e, "photp4")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          //isInvalid={!!errors.otp4}
                          tabIndex="4"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp5"
                          name="photp5"
                          data-testid="photp5-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp5")}
                          onChange={(e) => handleDigitChange(e, "photp5")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="5"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp6"
                          name="photp6"
                          data-testid="photp6-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp6")}
                          onChange={(e) => handleDigitChange(e, "photp6")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="6"
                        />
                      </div>
                      {errors &&
                        (errors?.photp1 ||
                          errors?.photp2 ||
                          errors?.photp3 ||
                          errors?.photp4 ||
                          errors?.photp5 ||
                          errors?.photp6) && (
                          <Row>
                            <span className="otp-verify-error">
                              Please Enter 6 Digit OTP
                            </span>
                          </Row>
                        )}
                    </Form.Group>
                    <Form.Group className="pb-1 pt-3">
                      <Form.Label htmlFor="new-password" className="required">
                        Enter the New Password
                      </Form.Label>
                      <Form.Control
                        className="form-input h-3rem"
                        type="password"
                        id="new-password"
                        name="newpassword"
                        autoComplete="off"
                        {...register("newpassword")}
                        isInvalid={!!errors.newpassword}
                        data-testid="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newpassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <PasswordCriteria />
                    <Form.Group className="pb-1 pt-1">
                      <Form.Label
                        htmlFor="confirm-password"
                        className="required"
                      >
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input h-3rem"
                        type="password"
                        id="confirm-password"
                        name="confirmpassword"
                        autoComplete="off"
                        {...register("confirmpassword")}
                        isInvalid={!!errors.confirmpassword}
                        data-testid="confirm-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmpassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-flex justify-content-end  mt-4">
                      <Button
                        className="custom-button btn-standard btn-submit"
                        type="submit"
                        data-testid="submit-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {isLoading && <Loader />}
    </>
  );
}
