import PropTypes from "prop-types";

function FormTitle({ title, variant }) {
  let value;
  if (variant === "variant-1") {
    value = (
      <div className="form-title text-center mb-2">
        <p className="form-text-underline">{title}</p>
      </div>
    );
  }

  if (variant === "variant-2") {
    value = (
      <div className="form-title form-title-space">
        <p className="form-text-underline type-2">{title}</p>
      </div>
    );
  }

  if (variant === "variant-3") {
    value = <div className="form-title-full">{title}</div>;
  }
  if (variant === "variant-4") {
    value = (
      <div className="form-title mb-2 form-title-space chg-password">
        <p className="form-text-underline type-2">{title}</p>
      </div>
    );
  }
  if (variant === "variant-5") {
    value = (
      <div className="form-title f-s-36px form-title-space">
        <p className="form-text-underline type-2">{title}</p>
      </div>
    );
  }
  return <> {value}</>;
}

FormTitle.defaultProps = {
  variant: "variant-1",
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default FormTitle;
