import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormTitle from "../components/form/FormTitle";
import { passwordRules } from "../constants/regExp";
import PasswordCriteria from "../components/widgets/PasswordCriteria";
import { useChangePasswordMutation } from "../slices/superAdminApiManagementSlice";
import { changePasswordSuccessText } from "../constants/firmdmin/labels";
import ModalPopup from "../components/modal/ModalPopup";
import Loader from "../components/Loader/Loader";
import SuccessModalContent from "../components/widgets/SuccessModalContent";
import { ROLE } from "../constants/role";
import {
  // famVault_wesbite,
  // defaultRoute,
  // login,
  sso_login,
  // attorney_LandingPage,
  // client_LandingPage,
  // firmsuperadmin_LandingPage,
  // executor_LandingPage,
  // login,
} from "../constants/routes";
import { useNavigate } from "react-router";

export default function ChangePassword() {
  const [showModal, setModalShow] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [oldpass, setOldPass] = useState("");
  // const identifierID = useSelector(
  //   (state) => state?.shareReceiveBankDetails?.identifierID && state?.shareReceiveBankDetails?.identifierID
  // )
  // const requestType = useSelector(
  //   (state) => state?.shareReceiveBankDetails?.requestType && state?.shareReceiveBankDetails?.requestType
  // )
  const schema = yup.object().shape({
    oldpassword: yup
      .string()
      .required("Old Password is Required")
      .matches(passwordRules, { message: "Enter the valid Password" }),
    newpassword: yup
      .string()
      .required("New Password is Required")
      .matches(passwordRules, { message: "Enter the valid Password" })
      .test({
        name: "is-newpassword",
        skipAbsent: true,
        test: (value, ctx) => {
          if (value === oldpass) {
            return ctx.createError({
              message: "Old and New passwords must be different",
            });
          }
          return true;
        },
      }),
    confirmpassword: yup
      .string()
      .oneOf(
        [yup.ref("newpassword"), null],
        "New Password and Confirm Password should match"
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();
  const onSubmit = async (formData) => {
    let transformData = {
      oldPassword: formData.oldpassword,
      newPassword: formData.newpassword,
    };
    if (userInfo?.role === ROLE.firmsuperadmin) {
      transformData = { ...transformData, accessToken: userInfo?.accessToken };
    }
    if (userInfo?.role === ROLE.attorney) {
      transformData = { ...transformData, accessToken: userInfo?.accessToken };
    }
    if (userInfo?.role === ROLE.client) {
      transformData = { ...transformData, accessToken: userInfo?.accessToken };
    }
    if (userInfo?.role === ROLE.executor) {
      transformData = { ...transformData, accessToken: userInfo?.accessToken };
    }
    const data = JSON.stringify(transformData);
    try {
      const res = await changePassword(data).unwrap();
      if (res?.isSuccessful) {
        setModalShow(true);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.errorMessage);
    }
  };

  const handleOldPassword = (e) => {
    setOldPass(e.target.value);
  };

  const handleNavigation = () => {
    // if (userInfo?.role === ROLE.firmsuperadmin) {
      
    //   if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
    //     navigate(
    //       `${firmsuperadmin_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
    //     );
    //   }else if(identifierID && requestType && (requestType === "addProf")){
    //     navigate(`${firmsuperadmin_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //     else if(identifierID && requestType && (requestType === "deathClaim")){
    //       navigate(`${firmsuperadmin_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
    //     }
    //   else{
    //     navigate(firmsuperadmin_LandingPage);
    //   }
      
    // } else if (userInfo?.role === ROLE.attorney) {
    //   if(identifierID && requestType && (requestType !== "addProf"  || requestType !== "deathClaim")){
    //     navigate(
    //       `${attorney_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
    //     );
    //   }else if(identifierID && requestType && (requestType === "addProf")){
    //     navigate(`${attorney_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
    //   }else if(identifierID && requestType && (requestType === "deathClaim")){
    //     navigate(`${attorney_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //   else{
    //     navigate(attorney_LandingPage);
    //   }
    //   }
    //   else if (userInfo?.role === ROLE.client) {
    //   if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
    //     navigate(
    //       `${client_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
    //     );
    //   }else if(identifierID && requestType && (requestType === "addProf")){
    //     navigate(`${client_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //   else if(identifierID && requestType && (requestType === "deathClaim")){
    //     navigate(`${client_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //   else{
    //     navigate(client_LandingPage);
    //   }
    // } 
    // else if (userInfo?.role === ROLE.executor) {
    //   if(identifierID && requestType && (requestType !== "addProf" && requestType !== "deathClaim")){
    //     navigate(
    //       `${executor_LandingPage}/wire-transfer?identifier=${identifierID}&requestType=${requestType}`
    //     );
    //   }else if(identifierID && requestType && (requestType === "addProf")){
    //     navigate(`${executor_LandingPage}/additionproflanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //   else if(identifierID && requestType && (requestType === "deathClaim")){
    //     navigate(`${executor_LandingPage}/reporterlanding?identifier=${identifierID}&type=${requestType}`);
    //   }
    //   else{
    //     navigate(executor_LandingPage);
    //   }
    // }
    // else{
    //   navigate(login)
    // }
    // console.log(userInfo?.role)
    // window.location.href = `${famVault_wesbite}`
    navigate(sso_login)
  };

  return (
    <>
      <div className="change-password-container">
        <Container fluid>
          <Row>
            <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="change-pwd-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-changepassword-container">
                  <Form
                    className="custom-form w-100 change-password type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle title="Change Password" variant="variant-4" />
                    <Form.Group className="pb-1">
                      <Form.Label htmlFor="old-password" className="required">
                        Enter the Old Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="password"
                        id="old-password"
                        name="oldpassword"
                        autoComplete="off"
                        onChangeCapture={(e) => handleOldPassword(e)}
                        {...register("oldpassword")}
                        isInvalid={!!errors.oldpassword}
                        data-testid="old-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.oldpassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="pb-1">
                      <Form.Label htmlFor="new-password" className="required">
                        Enter the New Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="password"
                        id="new-password"
                        name="newpassword"
                        autoComplete="off"
                        {...register("newpassword")}
                        isInvalid={!!errors.newpassword}
                        data-testid="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newpassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <PasswordCriteria />
                    <Form.Group className="pb-1">
                      <Form.Label
                        htmlFor="confirm-password"
                        className="required"
                      >
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="password"
                        id="confirm-password"
                        name="confirmpassword"
                        autoComplete="off"
                        {...register("confirmpassword")}
                        isInvalid={!!errors.confirmpassword}
                        data-testid="confirm-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmpassword?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-flex justify-content-end  mt-4">
                      <Button
                        className="custom-button btn-standard btn-submit w-100"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {isLoading && <Loader />}
      <ModalPopup
        show={showModal}
        setShow={() => setModalShow(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={changePasswordSuccessText} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handleNavigation}
        animation={false}
      />
    </>
  );
}
