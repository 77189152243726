import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DownloadSvg } from "../../assets/images/download-icon.svg";
import { useLazyDownloadCSVQuery } from "../../slices/bulkUploadApiManagementSlice";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  firmsuperadmin_LandingPage,
  nafirmsuperadmin_BulkUpload,
} from "../../constants/routes";
import { bulkUpload, uploadDetails } from "../../constants/firmdmin/labels";
import UploadFile from "../../components/bulkUpload/UploadFile";
import { ROLE } from "../../constants/role";
import { useSelector } from "react-redux";
import { cliManagement } from "../../constants/attorney/lables";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";

export default function NAFirmAdminBulkUpload() {
  const { userInfo } = useSelector((state) => state.auth);
  const [breadCrumbRoute, setBreadCrumbRoute] = useState(null);

  useEffect(() => {
    if (userInfo?.role === ROLE.firmsuperadmin) {
      setBreadCrumbRoute(firmsuperadmin_LandingPage);
    }
  }, [userInfo]);

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: firmsuperadmin_LandingPage,
      content: cliManagement,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: nafirmsuperadmin_BulkUpload,
      content: bulkUpload,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: breadCrumbRoute,
        content: cliManagement,
        active: false,
        state: { id: userInfo?.firmTypeId },
      },
      {
        id: "item-2",
        link: nafirmsuperadmin_BulkUpload,
        content: bulkUpload,
        active: true,
        state: { id: "" },
      },
    ]);
  }, [breadCrumbRoute, userInfo?.firmTypeId]);
  const [trigger, { isFetching }] = useLazyDownloadCSVQuery();

  const handleDownloadFile = async () => {
    try {
      const result = await trigger("client").unwrap();
      const url = window.URL.createObjectURL(new Blob([result]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "sample_template.xlsx";
      a.setAttribute("id", "download-sample");
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(err?.data?.message || err?.errorMessage || err?.data?.errorMessage);
    }
  };

  return (
    <>
      <div
        className="superadmin-container h-100"
        data-testid="superadmin-bulk-upload"
      >
        <Container fluid className="px-lg-4 pb-2 h-100">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={uploadDetails}
            />
          </div>
          <div className="firm-page-outer-container firm-page-bulk-outer-container">
            <div className="firm-page-container unset-height employee-form-container">
              <Container fluid className="pt-5">
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={8}>
                    <div className="firm-bulkupload-container">
                      <div
                        className="firm-bulk-download"
                        onClick={handleDownloadFile}
                        data-testid="sample-download"
                      >
                        <span className="text-decoration-underline">
                          Download sample format
                        </span>
                        <span className="ps-2">
                          <DownloadSvg />
                        </span>
                      </div>
                      <div className="dropzone-container h-100">
                        <UploadFile role="client" />
                      </div>
                    </div>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </Container>
            </div>
          </div>
        </Container>
        {isFetching && <Loader />}
      </div>
    </>
  );
}
