import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/NotFound.scss";

export default function NotFound() {
  return (
    <>
      <Container className="h-100">
        <Row className="h-100">
          <Col className="d-flex align-items-center justify-content-center p-0 h-100">
            <div className="custom-not-found-container text-center">
              <div className="not-found-number">404</div>
              <div className="not-found-text">Page Not Found</div>
              <div className="not-found-text">
                Sorry, We cant find the page you’re looking for.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="background-mvs-image type-1"></div>
    </>
  );
}
