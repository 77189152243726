import { Container } from "react-bootstrap";
import ReporterLandingPage from "../../components/reporter/ReporterLandingPage"
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { ReactComponent as InfoIcon } from "../../assets/images/VectorInfo-blue.svg";
import { deathclaim_invalid_path } from "../../constants/deathclaim/labels";
import { useSelector } from "react-redux";

export default function FamilyMemberLandingPage() {
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );

  const ssoResponse =
  sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
const isSSOLogin = ssoResponse
  ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
  : false;
  let identifierVal=null;
  if(isSSOLogin){
    if(sessionStorage.ssoIdentifier 
      && JSON.parse(sessionStorage.ssoIdentifier)
      && JSON.parse(sessionStorage.ssoIdentifier)?.identifierID
      && JSON.parse(sessionStorage.ssoIdentifier)?.requestType
    ){
      identifierVal=JSON.parse(sessionStorage.ssoIdentifier)?.identifierID;
    }
  }else{
    identifierVal=identifierID;
  }
return (
  identifierVal ? (
      <>
      <ReporterLandingPage />
      </>
  ):(
      <>
      <div
        className="attorney-client-title-page-container px-lg-4 px-xl-4"
        data-testid="death-claim-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-3 pt-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={false}
              hasButton={false}
              title="Disclosure"
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
        <div className="firm-page-container px-3 px-lg-4 px-md-4 px-xl-4 py-4">
              <div className="deathclaim-message-container px-lg-2 px-md-2 px-sm-2 py-4">
                <span className="info-blue mx-3">
                  <InfoIcon />
                </span>
                <div
                  className="question-container"
                  data-testid="deathclaim-view-page"
                >
                  {deathclaim_invalid_path}
                </div>
              </div>
            </div>
          </Container>
          </div>
      </>
  )
)
}