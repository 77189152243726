import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  name,
  emailId,
  invalidEmailReport,
  validation,
} from "../../constants/labels";
import { alphabetRules, emailRules } from "../../constants/regExp";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { useShareReportDocumentMutation } from "../../slices/reportApiManagementSlice";

export default function ShareReportModalPopup({
  showModal,
  setShowCallback,
  bodyData,
  shareFileName,
}) {
  const schema = yup.object().shape({
    name: yup
      .string().trim()
      .required(`${validation.name.required}`)
      .matches(alphabetRules, `${validation.name.rule}`),
    email: yup
      .string().trim()
      .required(`${invalidEmailReport}`)
      .email(`${invalidEmailReport}`)
      .matches(emailRules, `${invalidEmailReport}`),
  });

  const { userInfo } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const handleClose = () => {
    setShowCallback(false);
    reset();
  };

  const [shareDocument, { isLoading }] = useShareReportDocumentMutation();

  const onSubmit = async (formData) => {
    const data = {
      userId: userInfo?.userId,
      recipientName: formData?.name,
      toAddress: formData?.email,
      fileData: bodyData,
      fileName: shareFileName,
    };
    setShowCallback(false);
    reset();
    try {
      const res = await shareDocument(data).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="share-document-modal"
      >
        <Form
          className="custom-form custom-form-doc-share w-100"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header className="share-doc-modal-report-header" closeButton>
            <Modal.Title className="modal-custom-title-report">
              Enter the details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="share-doc-modal-body">
            <Form.Group className="mb-3">
              <Form.Label className="required" htmlFor={`name`}>
                {name}
              </Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                {...register("name")}
                isInvalid={!!errors.name}
                placeholder="Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required" htmlFor={`email`}>
                {emailId}
              </Form.Label>
              <Form.Control
                className="form-input"
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur = {(e) => e.target.value = e.target.value.trim()}
                placeholder="Email"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="share-doc-modal-footer">
            <Button
              className="custom-button btn-standard doc-share-submit-btn"
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
}
