import React from 'react';
import NewFolderUploadDocument from '../../components/additionalprofessional/NewFolderUploadDocument';

export default function AdditionalProfNewFolderUploadDocuments() {
  return (
    <>
        <NewFolderUploadDocument />
    </>
  )
}
