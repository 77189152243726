import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DownloadSvg } from "../../assets/images/download-icon.svg";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  client_LandingPage,
  client_share_bank_details,
  client_upload_docs_external_user,
} from "../../constants/routes";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { useLazyDownloadWireTransferCSVQuery } from "../../slices/wireTransferDownloadApiManagementSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { clientTitle, shareBankDetails } from "../../constants/client/labels";
import UploadBankDetailsFile from "../../components/bulkUpload/UploadBankDetailsFile";

export default function ClientBankDetailsUpload() {
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: clientTitle,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_upload_docs_external_user,
      content: shareBankDetails,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);
  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: client_LandingPage,
        content: clientTitle,
        active: false,
        state: { id: "" },
      },
      {
        id: "item-2",
        link: client_upload_docs_external_user,
        content: shareBankDetails,
        active: true,
        state: { id: "" },
      },
    ]);
  }, [userInfo?.firmTypeId]);
  const [trigger, { isFetching }] = useLazyDownloadWireTransferCSVQuery();

  const handleDownloadFile = async () => {
    try {
      const result = await trigger().unwrap();
      const url = window.URL.createObjectURL(new Blob([result]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "sample_template.csv";
      a.setAttribute("id", "download-sample");
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(
        err?.data?.message || err?.errorMessage || err?.data?.errorMessage
      );
    }
  };

  useEffect(() => {
    if (location?.state === null) {
      navigate(client_share_bank_details);
    }
  }, [location, navigate]);

  return (
    <>
      <div
        className="superadmin-container h-100"
        // data-testid="client-share-bank-details-upload"
      >
        <Container fluid className="px-lg-4 pb-2 h-100">
          <div
            className="employee-title-page-container px-0 px-lg-4 px-md-4"
            data-testid="client-share-bank-details-upload"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={shareBankDetails}
            />
          </div>
          <div className="firm-page-outer-container firm-page-bulk-outer-container">
            <div className="firm-page-container unset-height employee-form-container">
              <Container fluid className="pt-5">
                <Row>
                  <Col lg={2}></Col>
                  <Col lg={8}>
                    <div className="firm-bulkupload-container">
                      <div
                        className="firm-bulk-download"
                        onClick={handleDownloadFile}
                        data-testid="sample-download"
                      >
                        <span className="text-decoration-underline">
                          Download sample format
                        </span>
                        <span className="ps-2">
                          <DownloadSvg />
                        </span>
                      </div>
                      <div className="dropzone-container h-100">
                        <UploadBankDetailsFile
                          identifier={location?.state?.res?.identifier}
                          senderUserId={userInfo?.userId}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </Container>
            </div>
          </div>
        </Container>
        {isFetching && <Loader />}
      </div>
    </>
  );
}
