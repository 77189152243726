import React, { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../styles/AgGrid.scss";

export default function AgGrid({
  quickFilterText,
  rowData,
  paginationSizeLimit,
  defaultColDef,
  columnDefs,
  getRowStyle,
  rowSelection,
  onSelectionChanged,
}) {
  const gridApi = useRef(null);
  const isRowDataGreaterThan10 = rowData?.length > paginationSizeLimit;

  const onModelUpdated = () => {
    if (gridApi && gridApi.current.api.rowModel.rowsToDisplay.length === 0) {
      gridApi.current.api.showNoRowsOverlay();
    }
    if (gridApi && gridApi.current.api.rowModel.rowsToDisplay.length > 0) {
      gridApi.current.api.hideOverlay();
    }
  };

  return (
    <div className="ag-grid-container ag-theme-alpine">
      {isRowDataGreaterThan10 && (
        <AgGridReact
          ref={gridApi}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          wrapText={true}
          getRowStyle={getRowStyle}
          quickFilterText={quickFilterText}
          domLayout={"autoHeight"}
          pagination={isRowDataGreaterThan10}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 50]}
          overlayNoRowsTemplate={
            '<span aria-live="polite" aria-atomic="true" style="padding: 10px;">No data found.</span>'
          }
          onModelUpdated={onModelUpdated}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          enableCellTextSelection={true}
        />
      )}
      {!isRowDataGreaterThan10 && (
        <AgGridReact
          ref={gridApi}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          wrapText={true}
          getRowStyle={getRowStyle}
          quickFilterText={quickFilterText}
          domLayout={"autoHeight"}
          overlayNoRowsTemplate={
            '<span aria-live="polite" aria-atomic="true" style="padding: 10px;">No data found.</span>'
          }
          onModelUpdated={onModelUpdated}
          rowSelection={rowSelection}
          onSelectionChanged={onSelectionChanged}
          enableCellTextSelection={true}
        />
      )}
    </div>
  );
}
