import React, { useState, useRef, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import { familymember_landingpage } from "../../constants/routes";
import { ChevronDown} from "react-bootstrap-icons";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout_icon.svg";
import { ReactComponent as UserProfile } from "../../assets/images/user-profile.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../../constants/labels";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

export default function HeaderProfileFamilyMember() {
  const dispatch = useDispatch();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const [collapse, setCollapese] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  let media = "lg";

  useEffect(() => {
    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  });
  const handleClickListener = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCollapese(false);
    }
  };
  const handleLogout = async () => {
    setCollapese(true);
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      toast.success(res?.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleLogoNavigation = () => {
    navigate(familymember_landingpage) 
  };
  const imageProfile = (
    <div
      className="custom-navbar-profile"
      data-testid="profile-image"
      onClick={() => setCollapese(!collapse)}
    >
      <UserProfile className="custom-navbar-img" />
    </div>
  );
  return (
    <>
      <Navbar
        key={media}
        expand={media}
        data-bs-theme="dark"
        className={`custom-navbar profile align-items-center`}
        data-testid="header-profile"
      >
        <div className={"container-fluid px-lg-4"}>
          <div className="d-flex align-items-center">
            <div
              className="cursor-point"
              data-testid="header-profile-logo"
              onClick={() => handleLogoNavigation()}
            >
              <HeaderLogo />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="dropdown" ref={dropdownRef}>
              <span className="cursor-point">
                {imageProfile}
                <ChevronDown
                  size={13}
                  className="dropbtn"
                  color="#fff"
                  data-testid="chevronDown-icon"
                  onClick={() => setCollapese(() => !collapse)}
                />
              </span>
              {
                <div className={`dropdown-content`}>
                  <ul className={`${!collapse ? "ul-hidden" : "ul-show"}`}>
                    {/* <li className="disabled">
                      <span className="mx-2">{myProfile}</span>
                    </li> */}
                    <li onClick={handleLogout} data-testid="logout">
                      <LogoutIcon />
                      <span className="mx-2">{logout}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      </Navbar>
      {isLoading && <Loader />}
    </>
  );
}
