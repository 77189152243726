export const attorneyTitle = "Member Management";
export const clientDetails = "Member Details";
export const editClient = "Edit Member";
export const attorneyTitleButton = "Add New Member";
export const attorneyAddClientTitle = "Add Member Information"
export const cliManagement = "Member Management";
export const addClient = "Add Member";
export const searchPlaceholder = "Search by Name, Email & Mobile Phone";
export const documentDetails="Document Details";
export const editClientTitle = "Edit Member Information";
export const viewPeople = "Details";
export const executorDetails="Executor Details";
export const addFamily = "Add Emergency contact";
export const attorneyAddFamilyTitle = "Add Emergency contact Information";
export const attorneyEditFamilyTitle = "Edit Emergency contact Information";
export const shareDownloadMessage = "Your file will download automatically. Please check your Downloads folder";

export const attorneyDetails = {
    fullName: "Member Name",
    email: "Email",
    phone: "Mobile Phone",
    uploadedDocuments: "Uploaded Documents",
    willsUploaded: "Wills Uploaded",
    cliActions: "Actions",
    nextBtn: "Next",
    cancelBtn: "Cancel",
    submitBtm: "Submit"
}

export const clientPlaceholder = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Mobile Phone",
    dob: "DOB",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    zip: "Zip",
    cityList: "City",
    city: "City",
    state: "State",
    country: "Country",
    submitBtnText: "Submit",
    relationship:"Relationship",
    driversLicense:"Driver’s License / Passport"
  };
  export const addPeopleTitle= [
    //"Add Family Information",
    "Add Executor Information",
    "Add Sucessor Information",
    "Add Witness Information",
    "Add Co-Executor Information"
] 