import { documentApiSliceV2 } from "./documentApiSliceV2";
export const documentApiManagementV2 = documentApiSliceV2.injectEndpoints({
  endpoints: (builder) => ({
    shareDocumentV2: builder.mutation({
      query: ({docId, data}) => {     
        return {
          url: `/share/${docId}/share`,
          method: "POST",
          body: data,
        };
      },
    }),
    bulkShareDocument: builder.mutation({
      query: (data) => {        
        return {
          url: `share/bulk-file-transfer`,
          method: "POST",
          body: data,
        };
      },
    }),
    sendOtp: builder.mutation({
        query: (data) => ({
            url: `/share/otp/${data.identifierID}/send?action=${data.requestType}`,
            method: "POST",
        }), 
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
          url: `/share/otp/${data.identifierID}/verify?otp=${data.otp}`,
          method: "POST",
        }), 
    }),
    getBulkshareDocs: builder.query({
      query: (userId) => ({
          url: `/share/bulk-file-transfer?userId=${userId}`,
          method: "GET",
        }), 
    }),
  }),
});

export const {
  useShareDocumentV2Mutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useBulkShareDocumentMutation,
  useGetBulkshareDocsQuery,
 } = documentApiManagementV2;
