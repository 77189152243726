import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
//import { toast } from "react-toastify";
import {
  executor_LandingPage,
  executor_clientdocumentsDetails
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  useGetClientAllDocsByRootIdQuery
} from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
import DocumentDetails from "../../components/Documents/DocumentDetails";
import { useSelector } from "react-redux";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import {  executorDetails } from "../../constants/attorney/lables";
export default function ClientDocumentDetails() {
  const location = useLocation();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: executor_LandingPage,
      content: "--",
      active: true,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: executor_clientdocumentsDetails,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [userDetails, setUserDetails] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const rootDocId = location?.state?.doc?.rootDocId;
  const userId = location?.state?.doc?.userId;
  const { data: allDocumentsData, isLoading: getAllDocsLoading } =
  useGetClientAllDocsByRootIdQuery(
      { rootDocId, userId, role:"exe" },
      {
        skip: rootDocId && userId === undefined,
        refetchOnMountOrArgChange: true,
      }
    );
  const { data, isLoading } = useGetClientQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });
useEffect(() => {
    if (location?.state?.user) {
      setUserDetails(location?.state?.user);
      setItems([
        {
          id: "item-1",
          link: executor_LandingPage,
          content: executorDetails,
          active: false,
          state: { user: location?.state?.user },
        },
        {
          id: "item-2",
          link: executor_clientdocumentsDetails,
          content: location?.state?.doc?.rootDocName,
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);
    }
  }, [location?.state, data?.fullName]);
  return (
    <>
      <div
        className="attorney-client-title-page-container  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={`${location?.state?.doc?.rootDocName}`}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container upload-client-container px-1 px-lg-4 px-xl-4 mt-4">
            <div className="document-list-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <DocumentDetails
                allDocumentsData={allDocumentsData}
                userDetails={userDetails}
                locationState={{
                  doc: location?.state?.doc,
                  user: location?.state?.user,
                }}
                noaction={true}
              />
            </div>
          </div>
        </Container>
      </div>
      {(isLoading || getAllDocsLoading) && (
        <Loader data-testid="loader" />
      )}
    </>
  );
}

