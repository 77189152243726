import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Container,
  Alert,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
// import { ReactComponent as RegenPassword } from "../../assets/images/RegenPassword.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import { clientPlaceholder, firmAttorneylabel } from "../../constants/firm/labels";
import ReactGA from "react-ga4";
import {
  firmsuperadmin_LandingPage,
  firmsuperadmin_EditEmployeePage,
  firmsuperadmin_ViewEmployeePage,
} from "../../constants/routes";
import { empManagement, viewEmp } from "../../constants/firmdmin/labels";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import { useGetEmployeeQuery } from "../../slices/superAdminApiManagementSlice";
import Loader from "../../components/Loader/Loader";
import AgGrid from "../../components/table/AgGrid";
import ModalSearch from "../../components/modal/ModalSearch";
import ModalPopup from "../../components/modal/ModalPopup";
import SuccessModalContent from "../../components/widgets/SuccessModalContent";
import ReassignClientsForm from "../../components/form/firm/ReassignClientsForm";
import { ROLE } from "../../constants/role";
// import { useLazyRegenerateadminpasswordQuery } from "../../slices/userAuthManagementSlice";
// import { toast } from "react-toastify";
export default function FirmAdminViewEmployeePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [cliName, setCliName] = useState(null);
  // const [regenEmail, setRegenEmail] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal]=useState(false);
  // const [showYes, setYesClicked] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedClients, setSelectedClients] =useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  // const [trigger] = useLazyRegenerateadminpasswordQuery();
  const ssoResponse  =  sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse)
  const isSSOLogin = ssoResponse ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful : false
  let empnameWidth = 280;
  let empnameMinWidth = 280;

  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }
  // const handleClickModalPopup = (type, params) => {
  //   setShowModal(true);
  //   setYesClicked(false);
  //   console.log("popup===", params?.fullName);
  //   setCliName(params?.fullName);
  //   setRegenEmail(params?.email);
  // };

  // const getResult = useCallback(async () => {
  //   console.log("toast", regenEmail);
  //   const result = await trigger(regenEmail).unwrap();
  //   if (result?.isSuccessful) {
  //     toast.success("Password Regenerate Successfully!");
  //   } else {
  //     toast.error(result?.message);
  //   }
  // }, [regenEmail, trigger]);

  // useEffect(() => {
  //   if (showYes) {
  //     setShowModal(false);
  //     getResult();
  //   }
  // }, [showYes, getResult]);
  const [breadCrumbRoute, setBreadCrumbRoute] = useState(null);

  useEffect(() => {
    if(userInfo?.role === ROLE.firmsuperadmin){
      setBreadCrumbRoute(firmsuperadmin_LandingPage);
    }
  }, [userInfo]);

  const [items, setItems] = useState([
    {
      id: "item-1",
      link: firmsuperadmin_LandingPage,
      content: empManagement,
      active: false,
      state: { id: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: firmsuperadmin_ViewEmployeePage,
      content: viewEmp,
      active: true,
      state: { id: "", activeTab: "" },
    },
  ]);

  useEffect(() => {
    setItems([
      {
        id: "item-1",
        link: breadCrumbRoute,
        content: empManagement,
        active: false,
        state: { id: userInfo?.firmTypeId},
      },
      {
        id: "item-2",
        link: firmsuperadmin_ViewEmployeePage,
        content: viewEmp,
        active: true,
        state: { id: ""},
      },
    ]);
  }, [breadCrumbRoute, userInfo?.firmTypeId]);

  const { data, error, isLoading } = useGetEmployeeQuery(location?.state?.id, {
    skip: location?.state?.id === undefined,
  });
  const cliData = data?.clients;
  useEffect(() => {
    if(cliData?.length > 0){
      setRowData(cliData);
    } else {
      setRowData([]);
    }
  },[cliData])

  useEffect(() => {
    setSelectedClients([])
  },[dispatch])
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleEditPage = () => {
    navigate(firmsuperadmin_EditEmployeePage, {
      state: {
        page: "saEmployeeEditPage",
        id: location?.state?.id,
        prevPage: "viewpage",
      },
    });
  };

  const GridFullName = (params) => {
    const { value } = params;
    const fullName = value.length > 20 ? value.substring(0, 20) + "..." : value;
  
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
      >
        <div className="truncate-tooltip grid-firm-email-anchor">
          {fullName}
        </div>
      </OverlayTrigger>
    );
  };

  const GridEmailName = (params) => {
    const { value } = params;
    const email = value.length > 40 ? value.substring(0, 40) + "..." : value;
  
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
      >
        <div className="truncate-tooltip grid-firm-email-anchor">
          {email}
        </div>
      </OverlayTrigger>
    );
  };
  const onSelectionChanged = useCallback((event) => {
    const selectedRows = event.api.getSelectedRows();
    let temp =[];
    selectedRows.forEach((ele) => {
      temp.push(ele?.clientId)
    })
    setSelectedClients(temp)
  }, []);
  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  // const renderTooltip = (props) => (
  //   <Tooltip className="tooltip" {...props}>
  //     Change Password
  //   </Tooltip>
  // );
  
  // const GridButton = ({ data, regenButtonClick }) => {
  //   const regenPassword = () => {
  //     console.log("button data", data?.clients);
  //     regenButtonClick(data);
  //     setCliName(data?.fullName);
  //     handleClickModalPopup("regenPassword", data);
  //   };

  //   return (
  //     <div className="d-flex justify-content-center">
  //       <OverlayTrigger
  //         placement="top"
  //         overlay={renderTooltip}
  //         className="sa-tooltip"
  //       >
  //         <div className="sa-action-button">
  //           <div className="action-button" onClick={regenPassword}>
  //             <RegenPassword />
  //           </div>
  //         </div>
  //       </OverlayTrigger>
  //     </div>
  //   );
  // };
  const [columnDefs] = useState([
    {
      headerName: clientPlaceholder.fullName,
      field: "fullName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      headerCheckboxSelection:!isSSOLogin,
      checkboxSelection:!isSSOLogin,
    },
    {
      headerName: clientPlaceholder.email,
      field: "email",
      minWidth: 300,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: clientPlaceholder.phone,
      field: "phone",
    },    
    // {
    //   headerName: clientPlaceholder.clientAction,
    //   field: "clientAction",
    //   cellRenderer: (params) => {
    //     return (
    //       <GridButton
    //         data={params.data}
    //         regenButtonClick={(data) => {
    //           console.log("Regen", data);
    //         }}
    //       />
    //     );
    //   },

    //   getQuickFilterText: () => "",
    //   headerClass: "center-aligned-header",
    // },
  ]);
  const handlePrevPage = () => {
    navigate(firmsuperadmin_LandingPage, {
      state: {
        id : userInfo?.firmTypeId
      },
    });
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 200,
    };
  }, []);
  const paginationSizeLimit = 10;
  // const noOfClients = rowData?.length;
  const handleAttorneySearch = () => {
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 3 Level`,
      action: `Change of Professional ${ROLE.firmsuperadmin} - 3 Level`,
      label: `Change of Professional ${ROLE.firmsuperadmin} - 3 Level`,
    });
    setShowModal(true)
    
  }
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }
  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <span href="#">{children}</span>
    </OverlayTrigger>
  );
  return (
    <>
      <div className="superadmin-container">
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <EmployeeTitleBar
              items={items}
              hasButton={!isSSOLogin}
              buttonText="Edit"
              width="90px"
              title={data?.fullName ? data?.fullName : "--"}
              navigateTo={handleEditPage}
              navigateToPrev={handlePrevPage}
              hasBackButton={true}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container">
        <Container fluid className="px-lg-4">
          <div className="firm-page-container employee-form-container px-3 px-lg-4">
            <div className="employee-view-container pt-3">
              <div className="view-details">
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Name</div>
                    <div className="firm-view-contant">
                      {data?.fullName ? data?.fullName : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Email</div>
                    <div className="firm-view-contant">
                      {data?.email ? data?.email : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Mobile Phone</div>
                    <div className="firm-view-contant">
                      {data?.phone ? data?.phone : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Address</div>
                    <div className="firm-view-contant">
                      {
                      data?.address?.addressLine2
                        ? 
                        `${data?.address?.addressLine1}, ${data?.address?.addressLine2}`.length<=42?
                        `${data?.address?.addressLine1}, ${data?.address?.addressLine2}`
                        :
                        <>{`${data?.address?.addressLine1}, ${data?.address?.addressLine2}`.slice(0,42)}{<Link title={`${data?.address?.addressLine1}, ${data?.address?.addressLine2}`} id="t-3">
                        ...
                      </Link>}</>  
                        : data?.address?.addressLine1 ? 
                        `${data?.address?.addressLine1}`.length<=42?
                        `${data?.address?.addressLine1}`
                        :
                        <>{`${data?.address?.addressLine1}`.slice(0,42)}{<Link title={`${data?.address?.addressLine1}`} id="t-3">
                          ...
                        </Link>}</>  
                        :"--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">City</div>
                    <div className="firm-view-contant">
                      {data?.address?.city ? data?.address?.city : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">State</div>
                    <div className="firm-view-contant">
                      {data?.address?.state ? data?.address?.state : "--"}
                    </div>
                  </div>
                </div>
                <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                  <div className="emp-view-first">
                    <div className="firm-view-label">Zip</div>
                    <div className="firm-view-contant">
                      {data?.address?.zip ? data?.address?.zip : "--"}
                    </div>
                  </div>
                  <div className="firm-view-second px-lg-5">
                    <div className="firm-view-label">Country</div>
                    <div className="firm-view-contant">
                      {data?.address?.country ? data?.address?.country : "--"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider-firm"></div>
              <div className="firm-page-outer-container lg-12"></div>
            </div>
            <div className="divider-firm"></div>
            {/* <div className="firm-page-outer-container"> */}
            {/* <Container fluid> */}
            {/* <div className="firm-page-container  px-2 px-lg-4 px-md-4 px-sm-4"> */}
            <div>
              <Row className="mb-3">
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex justify-content-start mt-3 align-items-center"
                >
                  <div className="sa-table-label">
                    {clientPlaceholder.clientTableLabel}
                  </div>
                </Col>
                <Col
                  xl={6}
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex justify-content-end mt-3"
                >
                  <div className="flex-box">
                  <div className="search-container w-280 pb-2 flex-fill">
                    <div className="search-icon">
                      <AGSearchIcon />
                    </div>
                    <div className="search-input-field">
                      <input
                        type="text"
                        placeholder={clientPlaceholder.cliSearchText}
                        value={searchText}
                        autoComplete="off"
                        onChange={handleSearchChange}
                        id="search-firm-input-field"
                        data-testid="grid-search-input"
                      />
                    </div>
                    {searchText && (
                      <div onClick={handleClearSearch} className="clear-search-button">
                        <SearchXIcon />
                      </div>
                    )}
                  </div>
                  <div className="p-left">
                  {!isSSOLogin && <Button
                    className={`sa-button custom-button btn-standard ${selectedClients.length>0 ? "":"btn-disable"}`}
                    type="button"
                    onClick={handleAttorneySearch}
                    data-testid="new-firm-onboard-button"
                  >
                  {firmAttorneylabel.changeOfProfessional}
                  </Button>
                  }
                  </div>
                  </div>
                </Col>
              </Row>
              <Row className="m-10">
                <Col lg={12} xs={12} id="firm-admin-attorney-view-aggrid">
                  <AgGrid
                    quickFilterText={searchText}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    getRowStyle={getRowStyle}
                    paginationSizeLimit={paginationSizeLimit}
                    rowSelection={"multiple"}
                    onSelectionChanged={onSelectionChanged}
                  />
                  {isLoading && <Loader data-testid="loader" />}
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <ModalSearch
        setShowCallback={() => setShowModal(false)}
        headerTitle={firmAttorneylabel.changeOfProfessional}
        showModal={showModal}
        modalSize="sm"
        modalContentElement={<ReassignClientsForm setSuccessModal={() => {
        setShowModal(false);    
        setSuccessModal(true)
        }}
        backButtonAction={() => {
          navigate(firmsuperadmin_LandingPage, {
            state: { id: userInfo?.firmTypeId},
          });
        }}
        selectedClients={selectedClients}      
        currentProfessionEmail={data?.email? data?.email:null }  
        />}
      />
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={firmAttorneylabel.assignAttorneySuccess} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handlePrevPage}
        animation={false}
      />
      {isLoading && <Loader />}
    </>
  );
}
