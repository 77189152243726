import { bulkUploadApiSlice } from "./bulkUploadApiSlice";

export const bulkUploadApiManagementSlice = bulkUploadApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    downloadCSV: builder.query({
      query: (type) => ({
        url: `/template?templateType=${type}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    uploadCSV: builder.mutation({
      query: ({formData, user}) => ({
        url: `bulkImport?firm_id=${user.firmId}&role_name=${user.role}`,
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    uploadMember: builder.mutation({
      query: ({formData, user}) => ({
        url: `professional/client?firm_id=${user.firmId}&professional_id=${user.professionalId}&role_name=${user.role}`,
        method: "POST",
        body: formData,
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
  }),
});

export const { useLazyDownloadCSVQuery, useUploadCSVMutation, useUploadMemberMutation } =
  bulkUploadApiManagementSlice;
