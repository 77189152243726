
export const clientRelationship=[
    {
        id:1,
        label:"Father"
    },
    {
        id:2,
        label:"Mother"
    },
    {
        id:3,
        label:"Son"
    },
    {
        id:4,
        label:"Daughter"
    },
    {
        id:5,
        label:"Husband"
    },
    {
        id:6,
        label:"Wife"
    },
    {
        id:7,
        label:"Other Relative"
    }
]

export const formType={
    family:"family member",
    executor:"Executor",
    successor:"successor",
    witness:"Witness"
}

export const executorDefaultValue2=[
    {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        driversLicense: "",
        state: "",
        country: "",
        countryCode:""
      },
      {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        driversLicense: "",
        state: "",
        country: "",
        countryCode:""
      }
]

export const executorDefaultValue=[
    {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        driversLicense: "",
        state: "",
        country: "",
        countryCode:""
      }
]
export const witnessDefaultValue2=[
    {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        state: "",
        country: "",
        countryCode:""
      },
      {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        state: "",
        country: "",
        countryCode:""
      }
]

export const witnessDefaultValue=[
    {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        dob: "",
        phone: "",
        email: "",
        zip: "",
        city: "",
        cityList: "",
        driversLicense: "",
        state: "",
        country: "",
        countryCode:""
      }
]

