import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  domainNameRules,
  firmRegisterationNumberRules,
  phoneNumberRules,
  zipRules,
  emailRules,
  alphabetRules,
  usphonNumberRules,
  indphonNumberRules,
} from "../../../constants/regExp";
import { useGetGeoCodesZipCodeQuery } from "../../../slices/ssoManagementSlice";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { plans } from "../../../constants/firm/firmOnboardingValues";
import { formPlaceholder } from "../../../constants/firm/labels";
import { validation } from "../../../constants/firm/validationsMessages";
import {
  useGetemployeerangetypeQuery,
  useGetknowustypeQuery,
  useGetssotypeQuery,
} from "../../../slices/getFirmOnboardingValuesApi";
import { useGetFirmTypesQuery } from "../../../slices/getFirmOnBoardingFirmTypeApi";
import { useCreatefirmMutation } from "../../../slices/firmApiManagement";
import { mvssuperadmin_landingpage } from "../../../constants/routes";
import ModalPopup from "../../modal/ModalPopup";
import SuccessModalContent from "../../widgets/SuccessModalContent";
import { addFirmSuccessText, countryCodeList } from "../../../constants/labels";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function OnBoardingFirmForm() {
  const [cities, setCities] = useState([]);
  const [showModal, setModalShow] = useState(false);
  const [firmSuccessText, setAddFirmSuccessText] = useState("");
  const [isFirmSSOEnabled, setIsFirmSSOEnabled] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saCountryCode, setSaCountryCode] = useState("");
  const [saPhoneNumber, setSaPhoneNumber] = useState("");
  const schema = yup.object().shape({
    firmName: yup.string().trim().required(`${validation.firmName.required}`),
    firmRegNumber: yup
      .string()
      .trim()
      .notRequired()
      .test(
        "is-alpha-numberic",
        `${validation.firmRegNumber.firmRegisterationNumberRules}`,
        function (value) {
          if (!value) return true;
          return firmRegisterationNumberRules.test(value);
        }
      )
      .nullable(true),
    firmType: yup
      .string()
      .notOneOf([""], `${validation.firmType.required}`)
      .required(),
    acctManagerName: yup
      .string()
      .trim()
      .required(`${validation.acctManagerName.required}`)
      .matches(alphabetRules, `${validation.acctManagerName.rule}`),
    noOfEmployess: yup
      .string()
      .notOneOf([""], `${validation.noOfEmployess.required}`)
      .required(),
    haveSSO: yup
      .string()
      .notOneOf([""], `${validation.haveSSO.required}`)
      .required(),
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required("Enter the Last Name")
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string()
      .trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    addressLine1: yup
      .string()
      .trim()
      .required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    zip: yup
      .string()
      .trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: yup.string().required(`${validation.city.required}`).nullable(),
    selectPlan: yup
      .string()
      .notOneOf([""], `${validation.selectPlan.required}`)
      .required(),
    knowAbout: yup
      .string()
      .notOneOf([""], `${validation.knowAbout.required}`)
      .required(),
    saFirstName: yup
      .string()
      .trim()
      .required(`${validation.saFirstName.required}`)
      .matches(alphabetRules, `${validation.saFirstName.rule}`),
    saLastname: yup
      .string()
      .trim()
      .required(`${validation.saLastname.required}`)
      .matches(alphabetRules, `${validation.saLastname.rule}`),
    saEmail: yup
      .string()
      .trim()
      .required(`${validation.saEmail.required}`)
      .matches(emailRules, `${validation.saEmail.emailRules}`),
    saPhone: yup
      .string()
      .test(
        "saphone-validation",
        `${validation.saPhone.phoneNumberRules}`,
        function (value) {
          if (saCountryCode) {
            if (saCountryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (saCountryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.saPhone.phoneNumberRules}`),
    ...(isFirmSSOEnabled
      ? {
          domainName: yup
            .string()
            .required(`${validation.domainName.required}`)
            .matches(domainNameRules, `${validation.domainName.rule}`),
        }
      : {}),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const [createfirm, { isLoading: createFirmLoading }] =
    useCreatefirmMutation();
  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);
  useEffect(() => {
    if (saPhoneNumber && saCountryCode) {
      setValue("saPhone", saPhoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [saPhoneNumber, saCountryCode, setValue]);
  const onSubmit = async (formData) => {
    const transformData = {
      firmName: formData.firmName,
      firmRegistrationNumber: formData.firmRegNumber,
      firmTypeId: parseInt(formData.firmType),
      accountManager: formData.acctManagerName,
      noOfEmployees: parseInt(formData.noOfEmployess),
      ssoEnabled: parseInt(formData.haveSSO),
      domainName: formData.domainName ? formData.domainName : "",
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.email,
      phoneNumber: `+${formData.phone}`,
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2 || "",
        zip: formData.zip,
        city: formData.city === null ? formData.cityList : formData.city,
        state: formData.state,
        country: formData.country,
      },
      superAdmin: {
        firstName: formData.saFirstName,
        lastName: formData.saLastname,
        email: formData.saEmail,
        phoneNumber: `+${formData.saPhone}`,
      },
      planId: parseInt(formData.selectPlan),
      knownBy: parseInt(formData.knowAbout),
    };
    const data = JSON.stringify(transformData);

    try {
      const res = await createfirm(data).unwrap();
      // console.log(res);
      if (res?.isSuccessful) {
        // need to integrate popup notification here and when click close button need to be
        // Modal popup opens, once close the popup redirected to firmDashboard
        // Replace {} with Company name for modal popup success message
        const newText = addFirmSuccessText.replace(/{}/g, formData.firmName);
        setAddFirmSuccessText(newText);
        setModalShow(true);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      // console.log(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "firmRegNumber") {
      event.target.value = value.slice(0, 20);
    }
    if (name === "phone" || name === "saPhone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "zip") {
      event.target.value = value.slice(0, 5);
    }
  };

  const zipCode = watch("zip");

  const { data: geoCodeData, isLoading: geoCodeLoading } =
    useGetGeoCodesZipCodeQuery(zipCode, {
      skip: zipCode?.length !== 5,
    });
  const { data: ssoTypeData, isLoading: ssoLoading } = useGetssotypeQuery();

  const { data: empRangeTypeData, isLoading: empRangeLoading } =
    useGetemployeerangetypeQuery();

  const { data: knowUsTypeData, isLoading: knowUsLoading } =
    useGetknowustypeQuery();
  const { data: firmTypesData, isLoading: firmTypesLoading } =
    useGetFirmTypesQuery();
  const setValueZip = (city, state, country) => {
    setValue("city", city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("state", state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setValue("country", country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const data = geoCodeData;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, state, country);
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States");
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, country, "United States");
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities([]);
        setValueZip("", "", "");
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities([]);
          setValueZip("", "", "");
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities([]);
          setValueZip("", "", "");
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData]);

  // }
  return (
    <>
      {/* <div className="form-row-div px-lg-4 px-xs-4 px-md-3"> */}
      <Row className="form-row">
        <Col className="mb-3">
          <Form
            className="custom-form w-100 type-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col lg={12} md={12} className="mt-2">
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="firm-name" className="required mb-0">
                        {formPlaceholder.firmName}
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="firmName"
                        autoComplete="off"
                        maxLength={255}
                        {...register("firmName")}
                        isInvalid={!!errors.firmName}
                        data-testid="firm-name"
                        id="firm-name"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.firmName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="firm-reg-number" className="mb-0">
                        {formPlaceholder.firmRegNumber}
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="firmRegNumber"
                        autoComplete="off"
                        maxLength={20}
                        onChangeCapture={handleMaxNumber}
                        {...register("firmRegNumber")}
                        isInvalid={!!errors.firmRegNumber}
                        data-testid="firm-reg-number"
                        id="firm-reg-number"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.firmRegNumber?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-firmtype"
                        className="required mb-0"
                      >
                        {formPlaceholder.firmType}
                      </Form.Label>
                      <Form.Select
                        name="firmType"
                        {...register("firmType")}
                        isInvalid={!!errors.firmType}
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-firmtype"
                        id="select-firmtype"
                      >
                        <option value="" hidden></option>
                        {firmTypesData &&
                          firmTypesData.length > 0 &&
                          firmTypesData.map((group) => (
                            <option
                              value={group.firmTypeId}
                              key={group.firmTypeId}
                            >
                              {group.firmTypeName}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.firmType?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-acc-manager-name"
                        className="required mb-0"
                      >
                        {formPlaceholder.acctManagerName}
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="acctManagerName"
                        autoComplete="off"
                        maxLength={255}
                        {...register("acctManagerName")}
                        isInvalid={!!errors.acctManagerName}
                        data-testid="firm-acc-manager-name"
                        id="firm-acc-manager-name"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.acctManagerName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-noofemployees"
                        className="required mb-0"
                      >
                        {formPlaceholder.noOfEmployess}
                      </Form.Label>
                      <Form.Select
                        name="noOfEmployess"
                        {...register("noOfEmployess")}
                        isInvalid={!!errors.noOfEmployess}
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-noofemployees"
                        id="select-noofemployees"
                      >
                        <option value="" hidden></option>
                        {empRangeTypeData &&
                          empRangeTypeData.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.noOfEmployess?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-sso"
                        className="required mb-0"
                      >
                        {formPlaceholder.haveSSO}
                      </Form.Label>
                      <Form.Select
                        name="haveSSO"
                        {...register("haveSSO")}
                        isInvalid={!!errors.haveSSO}
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-sso"
                        id="select-sso"
                        onChange={(e) => {
                          e.target.value === "1"
                            ? setIsFirmSSOEnabled(true)
                            : setIsFirmSSOEnabled(false);
                        }}
                      >
                        <option value="" hidden></option>
                        {ssoTypeData &&
                          ssoTypeData.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.haveSSO?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {isFirmSSOEnabled ? (
                  <Row>
                    <Col lg={12} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          id="firm-domainname"
                          className="required mb-0"
                        >
                          {formPlaceholder.domainName}
                        </Form.Label>
                        <Form.Control
                          className="form-input input-border-radius-unset"
                          type="text"
                          name="domainName"
                          maxLength={255}
                          autoComplete="off"
                          {...register("domainName")}
                          isInvalid={!!errors.domainName}
                          data-testid="firm-domainname"
                          id="firm-domainname"
                        />
                        <Form.Control.Feedback
                          data-testid="invalid-feedback"
                          type="invalid"
                        >
                          {errors.domainName?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label id="firm-firstname" className="required mb-0">
                        {formPlaceholder.firstName}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="firstName"
                        maxLength={30}
                        autoComplete="off"
                        {...register("firstName")}
                        isInvalid={!!errors.firstName}
                        data-testid="firm-firstname"
                        id="firm-firstname"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.firstName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-lastname"
                        className="required mb-0"
                      >
                        {formPlaceholder.lastName}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="lastName"
                        maxLength={30}
                        autoComplete="off"
                        {...register("lastName")}
                        isInvalid={!!errors.lastName}
                        data-testid="firm-lastname"
                        id="firm-lastname"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.lastName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label id="firm-email" className="required mb-0">
                        {formPlaceholder.email}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="email"
                        autoComplete="off"
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        data-testid="firm-email"
                        id="firm-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-phone"
                        className="required mb-0"
                      >
                        {formPlaceholder.phone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                }
                              }}
                              data-testid="firm-phone"
                              id="firm-phone"
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        autoComplete="off"
                        {...register("phone", {
                          required: true,
                        })}
                        isInvalid={!!errors.phone}
                        data-testid="firm-phone"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-addressLine1"
                        className="required mb-0"
                      >
                        {formPlaceholder.addressLine1}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="addressLine1"
                        maxLength={255}
                        autoComplete="off"
                        {...register("addressLine1")}
                        isInvalid={!!errors.addressLine1}
                        data-testid="firm-addressLine1"
                        id="firm-addressLine1"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.addressLine1?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-addressLine2"
                        className="form-label mb-0"
                      >
                        {formPlaceholder.addressLine2}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="addressLine2"
                        maxLength={255}
                        autoComplete="off"
                        {...register("addressLine2")}
                        isInvalid={!!errors.addressLine2}
                        data-testid="firm-addressLine2"
                        id="firm-addressLine2"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.addressLine2?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="firm-zip" className="required mb-0">
                        {formPlaceholder.zip}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="number"
                        name="zip"
                        autoComplete="off"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        {...register("zip")}
                        isInvalid={!!errors.zip}
                        data-testid="firm-zip"
                        id="firm-zip"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.zip?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="firm-city" className="required mb-0">
                        {formPlaceholder.cityList}
                      </Form.Label>
                      {cities?.length > 0 && (
                        <>
                          <Form.Select
                            aria-label="Default select example"
                            className="form-input input-border-radius-unset select-placeholder"
                            name="cityList"
                            data-testid="cityList"
                            isInvalid={!!errors.cityList}
                            {...register("cityList")}
                            id="firm-city"
                          >
                            <option value="" hidden></option>
                            {cities?.length > 0 &&
                              cities.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.cityList?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                      {cities?.length === 0 && (
                        <>
                          <Form.Control
                            className="form-input input-border-radius-unset"
                            type="text"
                            name="city"
                            autoComplete="off"
                            {...register("city")}
                            isInvalid={!!errors.city}
                            disabled
                            data-testid="firm-city"
                            id="firm-city"
                          />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.city?.message}
                          </Form.Control.Feedback>
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-state"
                        className="required mb-0"
                      >
                        {formPlaceholder.state}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="state"
                        autoComplete="off"
                        {...register("state")}
                        isInvalid={!!errors.state}
                        data-testid="firm-state"
                        id="firm-state"
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.state?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="firm-country"
                        className="required mb-0"
                      >
                        {formPlaceholder.country}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="country"
                        autoComplete="off"
                        {...register("country")}
                        isInvalid={!!errors.country}
                        data-testid="firm-country"
                        id="firm-country"
                        disabled
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.country?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-plan"
                        className="required mb-0"
                      >
                        {formPlaceholder.selectPlan}
                      </Form.Label>
                      <Form.Select
                        name="selectPlan"
                        {...register("selectPlan")}
                        isInvalid={!!errors.selectPlan}
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-plan"
                        id="select-plan"
                      >
                        <option value="" hidden></option>
                        {plans.map((item) => (
                          <option key={item.plan_id} value={item.plan_id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.selectPlan?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-knowabout"
                        className="required mb-0"
                      >
                        {formPlaceholder.knowAbout}
                      </Form.Label>
                      <Form.Select
                        name="knowAbout"
                        {...register("knowAbout")}
                        isInvalid={!!errors.knowAbout}
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-knowabout"
                        id="select-knowabout"
                      >
                        <option value="" hidden></option>
                        {knowUsTypeData &&
                          knowUsTypeData.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.knowAbout?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3 mt-3">
                  <div className="super-admin-section">
                    <span className="super-admin-title">
                      {formPlaceholder.superAdminTitle}
                    </span>
                    <span className="super-admin-divider"></span>
                  </div>
                </Row>

                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="sa-firstname"
                        className="required mb-0"
                      >
                        {formPlaceholder.saFirstName}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="saFirstName"
                        maxLength={30}
                        autoComplete="off"
                        {...register("saFirstName")}
                        isInvalid={!!errors.saFirstName}
                        data-testid="sa-firstname"
                        id="sa-firstname"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.saFirstName?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="sa-lastname"
                        className="required mb-0"
                      >
                        {formPlaceholder.saLastname}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="text"
                        name="saLastname"
                        maxLength={30}
                        autoComplete="off"
                        {...register("saLastname")}
                        isInvalid={!!errors.saLastname}
                        data-testid="sa-lastname"
                        id="sa-lastname"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.saLastname?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="sa-email" className="required mb-0">
                        {formPlaceholder.saEmail}
                      </Form.Label>
                      <Form.Control
                        className="form-input input-border-radius-unset"
                        type="email"
                        name="saEmail"
                        autoComplete="off"
                        {...register("saEmail")}
                        isInvalid={!!errors.saEmail}
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        data-testid="sa-email"
                        id="sa-email"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.saEmail?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="sa-phone" className="required mb-0">
                        {formPlaceholder.saPhone}
                      </Form.Label>
                      <Controller
                        control={control}
                        name="saPhone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.saPhone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                setSaPhoneNumber(value);
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setSaCountryCode("+1");
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setSaCountryCode("+91");
                                }
                              }}
                              data-testid="sa-phone"
                              id="sa-phone"
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        autoComplete="off"
                        {...register("saPhone", {
                          required: true,
                        })}
                        isInvalid={!!errors.saPhone}
                        data-testid="sa-phone"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.saPhone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="submit"
                className="custom-button btn-standard btn-reset btn-onboarding zindex-0"
                data-testid="submit-button"
              >
                {formPlaceholder.submitBtnText}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {/* </div> */}
      {geoCodeLoading && <Loader />}
      {(ssoLoading || empRangeLoading || knowUsLoading || firmTypesLoading) && (
        <Loader />
      )}
      {createFirmLoading && <Loader />}
      <ModalPopup
        show={showModal}
        setShow={() => setModalShow(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={<SuccessModalContent text={firmSuccessText} />}
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={mvssuperadmin_landingpage}
        animation={false}
      />
    </>
  );
}
