import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { cliManagement } from "../../constants/attorney/lables";
import {
  attorney_LandingPage,
  attorney_uploadClientDocuments,
  attoney_foldersPage,
  attorney_new_folder_upload_documents
} from "../../constants/routes";
import {
  newFolderDocumentTypes
} from "../../constants/documentFileType";
import {
  useUploadotherdocumentMutation,
  useGetAllDocsByRootIdQuery,
  documentApiManagement,
} from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import NewFolderDocumentDetails from "../../components/Documents/NewFolderDocumentDetails";
export default function ClientNewFolderUploadDocuments() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [maxUploadFiles,] = useState("");
    const [isMultiple,] = useState(true);
    const [progressMap, setProgressMap] = useState({});
    const [isUploadLoading, setLoading] = useState(false);
    const rootDocId = location?.state?.doc?.rootDocId;
    const userId = location?.state?.doc?.userId;
    const { userInfo } = useSelector((state) => state.auth);
    const [items, setItems] = useState([
        {
          id: "item-1",
          link: attorney_LandingPage,
          content: cliManagement,
          active: false,
          state: { id: "", activeTab: "" },
        },
        {
          id: "item-2",
          link: attoney_foldersPage,
          content: "--",
          active: true,
          state: { user: "", activeTab: "" },
        },
        {
          id: "item-3",
          link: attorney_new_folder_upload_documents,
          content: "--",
          active: false,
          state: { id: "", activeTab: "" },
        },
      ]);
      const { data: allDocumentsData, isLoading: getAllDocsLoading } =
    useGetAllDocsByRootIdQuery(
      { rootDocId, userId },
      {
        skip: rootDocId && userId === undefined,
        refetchOnMountOrArgChange: true,
      }
    );
    const [uploadotherdocument, { isLoading: uploadOthersLoading }] =
    useUploadotherdocumentMutation();  
      // const [documentGroup, setDocumentGroup] = useState([
      //   {
      //     docId: "5e962039-684c-4cdf-b4ed-8e172d3bf53a",
      //     rootDocId: "RD_003",
      //     rootDocTypeId: "DT_035",
      //     docName: "divorce.pdf",
      //     version: 1,
      //     docSize: 1352602,
      //     docPath:
      //       "9bd9c320-c868-4657-87cf-77ceca5c83d2/RD_003/DT_035/adadsadasd.pdf",
      //     userId: "9bd9c320-c868-4657-87cf-77ceca5c83d2",
      //     isActive: true,
      //     createdAt: "2024-09-19T11:48:21.411+00:00",
      //     createdBy: "172fd424-ed86-47b8-a4fd-6d55950cdbbe",
      //     modifiedAt: "2024-09-24T09:21:29.743+00:00",
      //     modifiedBy: "172fd424-ed86-47b8-a4fd-6d55950cdbbe",
      //     uploadedBy: "Hari M",
      //     rootDocTypeName: "Mortgage",
      //     originalAvailable: "-",
      //     isPersonal: false,
      //     uploadedById: "172fd424-ed86-47b8-a4fd-6d55950cdbbe",
      //   },
      //   {
      //       "docId": "5e962039-684c-4cdf-b4ed-8e172d3bf53a",
      //       "rootDocId": "RD_003",
      //       "rootDocTypeId": "DT_035",
      //       "docName": "birth-certificate.pdf",
      //       "version": 1,
      //       "docSize": 1352602,
      //       "docPath": "9bd9c320-c868-4657-87cf-77ceca5c83d2/RD_003/DT_035/adadsadasd.pdf",
      //       "userId": "9bd9c320-c868-4657-87cf-77ceca5c83d2",
      //       "isActive": true,
      //       "createdAt": "2024-09-19T11:48:21.411+00:00",
      //       "createdBy": "172fd424-ed86-47b8-a4fd-6d55950cdbbe",
      //       "modifiedAt": "2024-09-24T09:21:29.743+00:00",
      //       "modifiedBy": "172fd424-ed86-47b8-a4fd-6d55950cdbbe",
      //       "uploadedBy": "Prabu S",
      //       "rootDocTypeName": "Mortgage",
      //       "originalAvailable": "-",
      //       "isPersonal": false,
      //       "uploadedById": "172fd424-ed86-47b8-a4fd-6d55950cdbbe"
      //   }
      // ]);  
      const onDropAccepted = async (files) => {
        const data = {
            rootDocId: location?.state?.doc?.rootDocId,
            clientId: location?.state?.doc?.userId,
            userId: userInfo?.userId,
          };
          const fileData = new FormData();
          for (const file of files) {
            fileData.append("documents", file);
          }
          uploaddocumentotherFiles({ fileData, data });
      };
      const onDropRejected = (params) => {
        if (params[0]?.errors[0]?.code === "file-invalid-type") {
          toast.error(
            "This format is not valid! Please upload valid file"
          );
        }
        if (params[0]?.errors[0]?.code === "too-many-files") {
          toast.error("Maximum 10 files are allowed");
        }
      };
      const onFileDialogOpen = () => {
        //setFileContent(null);
      };
      const { getRootProps, getInputProps, open } = useDropzone({
        accept: newFolderDocumentTypes,
        noDrag: true,
        noClick: true,
        maxFiles: maxUploadFiles,
        noKeyboard: true,
        multiple: isMultiple,
        onDropAccepted,
        onDropRejected,
        onFileDialogOpen,
      });
      const uploaddocumentotherFiles = useCallback(
        async ({ fileData, data }) => {
          const fileNames = fileData.getAll("documents").map((file) => file.name);
          const fileSizes = fileData.getAll("documents").map((file) => {
            return file.size;
          });
          const totalFileSize = fileSizes.reduce(
            (acc, fileSize) => acc + fileSize,
            0
          );
          try {
            const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/?rootDocId=${data.rootDocId}&docTypeId=${""}&clientId=${data.clientId}&userId=${data.userId}`;
            const token = userInfo?.accessToken;
            const response = await axios({
              url: baseUrl,
              method: "POST",
              responseType: "json",
              headers: { Authorization: "Bearer " + token },
              onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.total;
                if (totalLength !== null) {
                  setLoading(true);
                  const progress = Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  );
                  // Update progress for this specific file
                  setProgressMap((prevProgressMap) => ({
                    ...prevProgressMap,
                    [data.docTypeId]: { progress, fileNames },
                  }));
                }
              },
              data: fileData,
            });
    
            if (response.status === 200) {
              setLoading(false);
              const firmType = userInfo?.firmTypeId;
              firmType === 1 &&
                ReactGA.event(`upload_other_documents_confirmation`, {
                  category: `${ROLE.attorney} - 3 Level`,
                  action: `Upload other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
                  label: `Upload other Documents Confirmation - ${ROLE.attorney} - 3 Level`,
                  other_docs: fileNames.length,
                  other_filesize: totalFileSize,
                });
    
              firmType === 2 &&
                ReactGA.event(`upload_other_documents_confirmation`, {
                  category: `${ROLE.client} - 2 Level`,
                  action: `Upload other Documents Confirmation - ${ROLE.attorney} - 2 Level`,
                  label: `Upload other Documents Confirmation - ${ROLE.attorney} - 2 Level`,
                  other_docs: fileNames.length,
                  other_filesize: totalFileSize,
                });
              dispatch(
                documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
              );
              dispatch(
                documentApiManagement.util.invalidateTags(["getfoldersByid"])
              );
              toast.success(response?.data?.message);
              setTimeout(() => {
                setProgressMap((prevProgressMap) => {
                  const updatedProgressMap = { ...prevProgressMap };
                  delete updatedProgressMap[data.docTypeId];
                  return updatedProgressMap;
                });
              }, 2000);
            }
          } catch (err) {
            setLoading(false);
            toast.error(
              err?.response?.data?.message || `File uploading failed. Try again!`
            );
            setProgressMap((prevProgressMap) => {
              const updatedProgressMap = { ...prevProgressMap };
              delete updatedProgressMap[data.docTypeId];
              return updatedProgressMap;
            });
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [uploadotherdocument]
      );  
      useEffect(() => {
        if (location?.state?.user) {
          setItems([
            {
              id: "item-1",
              link: attorney_LandingPage,
              content: cliManagement,
              active: false,
            },
            {
              id: "item-2",
              link: attoney_foldersPage,
              content: location?.state?.user?.fullName,
              active: false,
              state: { user: location?.state?.user },
            },
            {
              id: "item-3",
              link: attorney_uploadClientDocuments,
              content: location?.state?.doc?.rootDocName,
              active: true,
              state: { doc: location?.state?.doc, user: location?.state?.user },
            },
          ]);
        }
      }, [location?.state]);  
  return (
    <>
    <div
        className="attorney-client-title-page-container  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={`${location?.state?.doc?.rootDocName} | ${location?.state?.user?.fullName}`}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container upload-client-container px-1 px-lg-4 px-xl-4">
            <div className="document-type-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <Form className="custom-form employee-form type-2 mt-3 new-folder-browse-btn-containter">
                <Row>
                  <Col lg={4} md={4} sm={12} className="browse-upload-button">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} data-testid="dropzone" />
                      <Button
                        className={`custom-button btn-standard custom-file-upload mt-0 mt-lg-3 mt-md-3`}
                        type="button"
                        onClick={open}
                      >
                        Browse & Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
                {
                  allDocumentsData && allDocumentsData.length === 0 &&
                  <div className="upload-document-text mt-3">
                  Please upload your files here
                </div>
                }
              </Form>
            </div>
            {
              allDocumentsData && allDocumentsData.length>0 && 
              <div className="new-folder-document-list pt-4">
                <NewFolderDocumentDetails
                documentGroup={allDocumentsData}
                />
            </div>
            }
          </div>
        </Container>
      </div>
      {(isUploadLoading || getAllDocsLoading || uploadOthersLoading) && (
        <Loader data-testid="loader" />
      )}
      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(
            ([docId, { progress, fileNames }]) => (
              <div key={docId} className="progress-bar-document">
                <div className="d-flex flex-column">
                  <div>
                    <div>
                      <b>
                        {fileNames.length === 1
                          ? `${fileNames.length} document is uploading`
                          : `${fileNames.length} documents are uploading`}
                      </b>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <progress value={progress} max={100} />{" "}
                    <div className="ps-2">{progress}%</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  )
}
