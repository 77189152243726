import { Container, Nav } from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { useState } from "react";
import { reportsPlaceholder } from "../../constants/firmdmin/labels";
import MVSPlatformUtilReport from "../../components/reports/MVSPlatformUtilReport";
import DownloadAndCapacityReport from "../../components/reports/DownloadAndCapacityReport";

export default function FirmAdminReportsPage() {
  const [activeTab, setActiveTab] = useState("platform-utility");

  const handleSelectTab = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="firm-reports-page"
      >
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-2">
            <FirmPageTitleBar title="Reports" hasButton={false} />
          </div>
        </Container>
      </div>
      <div className="px-3 px-lg-5 pb-2 pt-4 firm-reports-page-container">
        <div>
          <div className="firm-report-nav-div">
            <Nav
              variant="underline"
              activeKey={activeTab}
              onSelect={handleSelectTab}
              defaultActiveKey="platform-utility"
            >
              <Nav.Item>
                <Nav.Link
                  className="px-3 text-center align-content-center"
                  eventKey="platform-utility"
                >
                  {reportsPlaceholder.platformUtiReport}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="px-3 text-center align-content-center"
                  eventKey="document-capacity"
                >
                  {reportsPlaceholder.docAndCapacityReport}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
      <div>
        {activeTab === "platform-utility" && <MVSPlatformUtilReport />}
        {activeTab === "document-capacity" && <DownloadAndCapacityReport />}
      </div>
    </>
  );
}
