import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { ReactComponent as EditAction } from "../../assets/images/EditAction.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/CheckIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/CloseIcon.svg";
import AgGrid from "../../components/table/AgGrid";
import Loader from "../../components/Loader/Loader";
import {
  attorneyDetails,
  attorneyTitle,
  searchPlaceholder,
} from "../../constants/attorney/lables";
import { saLandingPlaceholder } from "../../constants/firm/labels";
import {
  nafirmsuperadmin_Addclient,
  nafirmsuperadmin_BulkUpload,
  nafirmsuperadmin_Editclient,
  nafirmsuperadmin_Viewclient,
} from "../../constants/routes";
import {
  useDeleteClientMutation,
  useGetAllClientDetailsNonAttorneyQuery,
} from "../../slices/nonAttorneySuperAdminApiManagementSlice";
import ModalPopupType2 from "../../components/modal/ModalPopupType2";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { ROLE } from "../../constants/role";
import { useGoogleAnalyticsKeyQuery } from "../../slices/gaApiSliceManagement";
import { deleteMemberText } from "../../constants/firmdmin/labels";

export default function NAFirmAdminLandingPage() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [actionType, setActionType] = useState("");
  const [fullName, setFullName] = useState(null);
  const [deleteAttorney] = useDeleteClientMutation();
  const [delMemberText, setDelMemberText] = useState("");
  const ssoResponse =
    sessionStorage.ssoResponse && JSON.parse(sessionStorage.ssoResponse);
  const isSSOLogin = ssoResponse
    ? ssoResponse.isSSOEnabled && ssoResponse.isSuccessful
    : false;

  let location = useLocation();

  let empnameWidth = 200;
  let empnameMinWidth = 250;
  if (window.innerWidth < 576) {
    empnameWidth = 100;
    empnameMinWidth = 140;
  }

  const handleClickModalPopup = (type, params) => {
    const newText = deleteMemberText.replace(/{}/g, params?.fullName);
    setDelMemberText(newText);
    setShowModal(true);
    setActionType(type);
    setDeleteId(params?.clientId);
    setFullName(params?.fullName);
  };

  const addClient = () => {
    navigate(nafirmsuperadmin_Addclient, {
      state: {
        page: "addclient",
        id: userInfo?.userId,
      },
    });
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 2 Level`,
      action: `Add Client ${ROLE.firmsuperadmin} - 2 Level`,
      label: `Add Client ${ROLE.firmsuperadmin} - 2 Level`,
    });
  };

  const viewClient = (event) => {
    navigate(nafirmsuperadmin_Viewclient, {
      state: {
        page: "viewclient",
        id: event?.data?.clientId,
      },
    });
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 2 Level`,
      action: `View Client ${ROLE.firmsuperadmin} - 2 Level`,
      label: `View Client ${ROLE.firmsuperadmin} - 2 Level`,
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleEditPage = (params) => {
    navigate(nafirmsuperadmin_Editclient, {
      state: {
        page: "editclient",
        name: params?.fullName,
        id: params?.clientId,
      },
    });
  };
  const handleBulkUpload = (params) => {
    navigate(nafirmsuperadmin_BulkUpload);
    ReactGA.event({
      category: `${ROLE.firmsuperadmin} - 2 Level`,
      action: `Bulk Upload ${ROLE.firmsuperadmin} - 2 Level`,
      label: `Bulk Upload ${ROLE.firmsuperadmin} - 2 Level`,
    });
  };

  const firmId = userInfo?.firmId;
  const roleName = "client";
  const { data, isLoading } = useGetAllClientDetailsNonAttorneyQuery(
    { firmId, roleName },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: googleAnalyticsKey } = useGoogleAnalyticsKeyQuery({
    skip: data?.length === 0,
  });

  useEffect(() => {
    if (data?.length > 0) {
      setRowData(data);
    } else {
      setRowData([]);
    }
  }, [data]);

  useEffect(() => {
    const googleTrackingId = googleAnalyticsKey?.REACT_APP_GOOGLE_ID;
    if (data && data?.length > 0 && googleTrackingId) {
      const page = location.pathname + location.search;
      const usersId = userInfo?.userId;
      const role = userInfo?.role;
      const firmTypeId = userInfo?.firmTypeId;
      const firmId = userInfo?.firmId;
      ReactGA.initialize(googleTrackingId);

      ReactGA.gtag("set", "user_properties", {
        userRole: role,
        usersId: usersId,
        firmTypeId: firmTypeId,
        firmId: firmId,
      });

      ReactGA.send({
        hitType: "pageview",
        page: `${page}`,
        title: "Firm Admin Landing Page - 2 Level",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, googleAnalyticsKey]);

  const GridFullName = (params) => {
    const { value } = params;
    const fullName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    if (value.length > 20) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor">
            {fullName}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor">{fullName}</div>
      );
    }
  };

  const GridEmailName = (params) => {
    const { value } = params;
    const email = value.length > 40 ? value.substring(0, 40) + "..." : value;

    if (value.length > 40) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-email-anchor">{email}</div>
      );
    }
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return <div className="grid-center-aligned-text">{p.value}</div>;
  };
  const deleteTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      Delete
    </Tooltip>
  );
  const editTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      Edit
    </Tooltip>
  );

  const GridButton = ({ data, deleteButtonClick, editButtonClick }) => {
    const deleteButton = () => {
      deleteButtonClick(data);
      // setFullName(data?.fullName);
    };

    const editPage = () => {
      editButtonClick(data);
    };
    const uniqueKey = data?.attorneyId;
    return (
      <div className="d-flex">
        <OverlayTrigger
          placement="top"
          key={`delete-${uniqueKey}`}
          overlay={deleteTooltip}
        >
          <div className="sa-action-button me-4">
            <div className="action-button" data-testid="delete-button" onClick={deleteButton}>
              <DeleteAction />
            </div>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          key={`edit-${uniqueKey}`}
          overlay={editTooltip}
        >
          <div className="sa-action-button ms-2">
            <div className="action-button" data-testid="edit-button" onClick={editPage}>
              <EditAction />
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  };
  const CheckCloseIcon = ({ value }) => {
    return (
      <div className="grid-center-aligned-text">
        {value ? <CheckIcon /> : <CloseIcon />}
      </div>
    );
  };

  const [columnDefsNonSSO] = useState([
    {
      headerName: attorneyDetails.fullName,
      field: "fullName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => viewClient(event),
    },
    {
      headerName: attorneyDetails.email,
      field: "email",
      minWidth: 300,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: attorneyDetails.phone,
      field: "phone",
      minWidth: 140,
    },
    {
      headerName: attorneyDetails.uploadedDocuments,
      field: "uploadedDocuments",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
      minWidth: 190,
    },
    {
      headerName: attorneyDetails.willsUploaded,
      field: "willsUploaded",
      cellRenderer: CheckCloseIcon,
      headerClass: "center-aligned-header",
    },
    {
      headerName: attorneyDetails.cliActions,
      field: "attorneyAction",
      cellRenderer: (params) => {
        return (
          <GridButton
            data={params.data}
            deleteButtonClick={(data) => {
              handleClickModalPopup("deleteItem", data);
            }}
            editButtonClick={(data) => {
              handleEditPage(data);
            }}
          />
        );
      },
      // headerClass: "center-aligned-header",
    },
  ]);
  const [columnDefsSSO] = useState([
    {
      headerName: attorneyDetails.fullName,
      field: "fullName",
      width: empnameWidth,
      minWidth: empnameMinWidth,
      sortable: true,
      unSortIcon: true,
      pinned: "left",
      cellRenderer: GridFullName,
      onCellClicked: (event) => viewClient(event),
    },
    {
      headerName: attorneyDetails.email,
      field: "email",
      minWidth: 250,
      cellRenderer: GridEmailName,
      headerComponentParams: { "data-testid": "planName" },
    },
    {
      headerName: attorneyDetails.phone,
      field: "phone",
      minWidth: 140,
    },
    {
      headerName: attorneyDetails.uploadedDocuments,
      field: "uploadedDocuments",
      cellRenderer: CenterAligned,
      getQuickFilterText: () => "",
      headerClass: "center-aligned-header",
      minWidth: 190,
    },
    {
      headerName: attorneyDetails.willsUploaded,
      field: "willsUploaded",
      cellRenderer: CheckCloseIcon,
      headerClass: "center-aligned-header",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 140,
    };
  }, []);

  const handleYesClicked = async () => {
    setShowModal(false);
    if (actionType === "deleteItem") {
      try {
        const res = await deleteAttorney(deleteId).unwrap();
        if (res?.isSuccessful) {
          toast.success("Member Deleted Successfully!");
          ReactGA.event({
            category: `${ROLE.firmsuperadmin} - 2 Level`,
            action: `Delete Client Confirmed - ${ROLE.firmsuperadmin} - 2 Level`,
            label: `Delete Client Confirmed - ${ROLE.firmsuperadmin} - 2 Level`,
          });
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error(error?.data?.errorMessage);
      }
    }
  };

  const paginationSizeLimit = 10;
  const noOfEmp = rowData?.length;

  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="non-attorney-firm-admin-landing-page"
      >
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={attorneyTitle}
              hasInpuField={true}
              searchText={searchText}
              handleSearchChange={handleSearchChange}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-sm-4">
            <Row className="mb-3">
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-start align-items-end mt-3"
              >
                <div className="sa-table-label">
                  {noOfEmp === 0 || noOfEmp === 1
                    ? saLandingPlaceholder.saClientLabel
                    : saLandingPlaceholder.saClientLabelPlural}
                  : {noOfEmp}
                </div>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                xs={12}
                className="d-flex justify-content-end mt-3"
              >
                {!isSSOLogin && (
                  <div className="search-container d-flex justify-content-between align-items-center">
                    <Button
                      className={`sa-button custom-button btn-standard btn-bulk-upload`}
                      type="button"
                      onClick={handleBulkUpload}
                      data-testid="new-firm-onboard-button"
                    >
                      {saLandingPlaceholder.saBulkUpload}
                    </Button>
                    <Button
                      className={`sa-button custom-button btn-standard btn-add-employee`}
                      type="button"
                      onClick={addClient}
                      data-testid="new-firm-onboard-button"
                    >
                      {saLandingPlaceholder.saAddClient}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="pb-3 d-block d-md-none ">
              <div className="sa-search-container">
                <div className="search-icon">
                  <AGSearchIcon />
                </div>
                <div className="search-input-field relative-position">
                  <input
                    type="text"
                    placeholder={searchPlaceholder}
                    value={searchText}
                    onChange={handleSearchChange}
                    id="search-firm-input-field-mob"
                    data-testid="grid-search-input"
                  />
                </div>
                {searchText && (
                  <div
                    onClick={handleClearSearch}
                    className="clear-search-button"
                  >
                    <SearchXIcon />
                  </div>
                )}
              </div>
            </Row>
            <Row className="m-10">
              <Col lg={12} xs={12}>
                <AgGrid
                  quickFilterText={searchText}
                  rowData={rowData}
                  columnDefs={isSSOLogin ? columnDefsSSO : columnDefsNonSSO}
                  defaultColDef={defaultColDef}
                  getRowStyle={getRowStyle}
                  paginationSizeLimit={paginationSizeLimit}
                />
                {isLoading && <Loader data-testid="loader" />}
              </Col>
            </Row>
          </div>
        </Container>
        <ModalPopupType2
          setShowCallback={() => setShowModal(false)}
          setYesCallback={handleYesClicked}
          headerTitle={
            actionType === "regenPassword"
              ? "Regenerate Password"
              : "Delete Item"
          }
          content={
            actionType === "regenPassword"
              ? `Are you sure you want to regenerate the password for this Professional(${fullName}) account?`
              : delMemberText
          }
          showModal={showModal}
        />
      </div>
    </>
  );
}
