import "../../styles/ModalPopupType4.scss"

export default function SearchResultsModalContent({ text }) {
    return (
      <div className= 'd-flex justify-content-center'>
        <div>
          <div className="result-text px-lg-1 px-md-2 px-0 pt-2">{text}</div>
        </div>
      </div>
    );
  }