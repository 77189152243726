import { Button } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  alphabetRules,
  indphonNumberRules,
  phoneNumberRules,
  usphonNumberRules,
} from "../../constants/regExp";
import { Controller, useForm } from "react-hook-form";
import { clientsearchPlaceholder } from "../../constants/deathclaim/labels";
import {
  clientsearchtitle,
  familymembersearchtitle,
} from "../../constants/deathclaim/titles";
import { validation } from "../../constants/deathclaim/validationMessages";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { useClientSearchMutation } from "../../slices/loginAuthManagementSlice";
import ModalPopupType5 from "../../components/modal/ModalPopupType5";
import Loader from "../../components/Loader/Loader";
import utc from "dayjs/plugin/utc";
import ClientSearchSuccessModal from "../../components/modal/ClientSearchSuccessModal";
import FamilyMemberModal from "../../components/modal/FamilyMemberModal";
import { useFamilyMemberSearchMutation } from "../../slices/deathClaimApiManagementSlice";
import { SearchFailureText } from "../../constants/deathclaim/labels";
import SearchResultsModalContent from "../../components/widgets/SearchResultsModalContent";
import WillUploadedModalContent from "../../components/widgets/WillUploadedModalContent";
import { famVault_wesbite } from "../../constants/routes";
import { countryCodeList } from "../../constants/labels";

dayjs.extend(utc);

function DeathClaimPage() {
  const [clientSearch, { isLoading: clientSearchLoading }] =
    useClientSearchMutation();
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
  const [searchResultText, setSearchResultText] = useState("");
  const [clientSearchSuccessModal, setClientSearchSuccessModal] =
    useState(false);
  const [familyMemberModal, setFamilyMemberModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(true);
  const [familySearchSuccessModal, setFamilySearchSuccessModal] =
    useState(false);
  const [familyMemberSearch, { isLoading: familyMemberSearchLoading }] =
    useFamilyMemberSearchMutation();
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string()
      .trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    dob: yup
      .string()
      .required(`${validation.dob.required}`)
      .test("valid-date", "Invalid date of birth", (value) => {
        if (!value) return true;
        return !isNaN(Date.parse(value));
      })
      .test("max-date", "Date of birth cannot be in the future", (value) => {
        if (!value) return true;
        const newtoday = dayjs().utc();
        const dob = dayjs(value).utc();
        return dob <= newtoday;
      })
      .test(
        "min-date",
        "Date of birth should be after January 1, 1924",
        (value) => {
          if (!value) return true;
          // const today = dayjs().utc();
          const minDate = dayjs().year(1924).startOf("year");
          const dob = dayjs(value).utc();
          return dob >= minDate;
        }
      ),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
  });

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const today = dayjs();
  const yesterday = dayjs()
    .year(today.year() - 100)
    .startOf("year");
  const [selectedDate, setSelectedDate] = useState("");
  const [shouldValidate, setShouldValidate] = useState(true);

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setValue("dob", formattedDate, { shouldValidate: true });
  };

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
    if (name === "firstName" || name === "lastName") {
      event.target.value = value.slice(0, 255);
    }
  };

  const handleClickSubmit = async (formData) => {
    const transformData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: dayjs(formData.dob).format("YYYY-MM-DD"),
      phoneNumber: `+${formData.phone}`,
    };
    const data = JSON.stringify(transformData);
    try {
      const res = await clientSearch(data).unwrap();
      if (res?.isSuccessful) {
        setIsError(false);
        setClientId(res?.clientIdentifier);
        const newText = res?.message;
        setClientName(newText);
        setSearchResultText(
          `Thank you! ${newText} is on the FamVault Platform`
        );
        setClientSearchSuccessModal(true);
      } else if (res === null) {
        setIsError(true);
        setSearchResultText(SearchFailureText);
        setClientSearchSuccessModal(true);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (err) {
      if (err?.data?.message === "User does not exist") {
        setIsError(true);
        setSearchResultText(SearchFailureText);
        setClientSearchSuccessModal(true);
      } else {
        toast.error(
          err?.data?.message ||
            err?.data?.errorMessage ||
            err.error ||
            err?.errorMessage ||
            err
        );
      }
    }
  };
  useEffect(() => {
    if (phoneNumber && countryCode && shouldValidate) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
    if (selectedDate) {
      setValue("dob", selectedDate, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue, shouldValidate, selectedDate]);

  const handleNoClick = () => {
    setClientSearchSuccessModal(false);
  };
  const handleCloseMember = () => {
    setClientSearchSuccessModal(false);
    setFamilyMemberModal(true);
  };
  const onSubmitFamilyDetails = async (transformData) => {
    try {
      transformData.encryptedClientId = clientId;
      const data = JSON.stringify(transformData);
      const res = await familyMemberSearch(data).unwrap();
      if (res?.isSuccessful) {
        setIsError(false);
        res?.userCreated === false
          ? setIsRegistered(true)
          : setIsRegistered(false);
        const newText = res?.message;
        setSearchResultText(newText);
        setFamilySearchSuccessModal(true);
      } else {
        setIsError(true);
        setSearchResultText(SearchFailureText);
        setFamilySearchSuccessModal(true);
      }
    } catch (err) {
      toast.error(
        err?.data?.message ||
          err?.data?.errorMessage ||
          err.error ||
          err?.errorMessage ||
          err
      );
    }
  };
  const handleCloseFamilyDetails = () => {
    window.location.href = `${famVault_wesbite}`;
  };
  const handleClear = useCallback(() => {
    reset();
    setPhoneNumber(countryCode);
    setShouldValidate(false);
    setSelectedDate(null);
  }, [reset, setPhoneNumber, countryCode]);
  return (
    <>
      <div className="medical-professional-container">
        <div className="centered-box">
          <Form
            className="custom-form w-100 type-2"
            onSubmit={handleSubmit(handleClickSubmit)}
          >
            <div className="px-2">
              <div className="modal-content-section">
                <h6 className="description mb-3">
                  <b>{clientsearchtitle} </b>
                </h6>
                <Row className="form-row">
                  <Col>
                    <Row>
                      <Col lg={12} md={12} className="mt-2">
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firstName"
                                className="required mb-1"
                              >
                                {clientsearchPlaceholder.firstName}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                autoComplete="off"
                                {...register("firstName", {
                                  required: true,
                                })}
                                className="form-input-med input-border-radius-unset"
                                maxLength={30}
                                isInvalid={!!errors.firstName}
                                data-testid="firstName"
                                id="firstName"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="input-feedback"
                              >
                                {errors.firstName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="lastName"
                                className="required mb-1"
                              >
                                {clientsearchPlaceholder.lastName}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                autoComplete="off"
                                {...register("lastName", {
                                  required: true,
                                })}
                                className="form-input-med input-border-radius-unset"
                                maxLength={30}
                                isInvalid={!!errors.lastName}
                                data-testid="lastName"
                                id="lastName"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="input-feedback"
                              >
                                {errors.lastName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="" className="required mb-1">
                                {clientsearchPlaceholder.dob}
                              </Form.Label>
                              <Controller
                                name="dob"
                                control={control}
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <div
                                      className={
                                        errors.dob
                                          ? "mui-date-picker-invalid-container-med"
                                          : "mui-date-picker-container-med"
                                      }
                                    >
                                      <DesktopDatePicker
                                        {...field}
                                        name="dob"
                                        type="date"
                                        value={
                                          dayjs(selectedDate).isValid()
                                            ? dayjs(selectedDate)
                                            : null
                                        }
                                        onChange={handleDateChange}
                                        maxDate={today}
                                        minDate={yesterday}
                                      />
                                    </div>
                                  </LocalizationProvider>
                                )}
                              />
                              <Form.Control
                                type="hidden"
                                value={selectedDate || ""}
                                autoComplete="off"
                                {...register("dob", {
                                  required: true,
                                })}
                                isInvalid={!!errors.dob}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.dob?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="phone"
                                className="required mb-1"
                              >
                                {clientsearchPlaceholder.phone}
                              </Form.Label>
                              <Controller
                                control={control}
                                name="phone"
                                render={({ field }) => (
                                  <div
                                    className={
                                      errors.phone
                                        ? "phone-input-invalid-container-med"
                                        : "phone-input-valid-container-med"
                                    }
                                  >
                                    <PhoneInput
                                      defaultCountry="us"
                                      onlyCountries={countryCodeList}
                                      placeholder="Enter the phone number"
                                      title=""
                                      data-testid="med-phone"
                                      value={phoneNumber}
                                      country={"us"}
                                      defaultValue=""
                                      inputProps={{
                                        required: true,
                                        autoFocus: false,
                                        title: "",
                                      }}
                                      countryCodeEditable={false}
                                      onChangeCapture={handleMaxNumber}
                                      onChange={(value, phone) => {
                                        setShouldValidate(true);
                                        if (
                                          phone.dialCode === "1" &&
                                          phone.countryCode === "us"
                                        ) {
                                          setCountryCode("+1");
                                          setPhoneNumber(value);
                                        }
                                        if (
                                          phone.dialCode === "91" &&
                                          phone.countryCode === "in"
                                        ) {
                                          setCountryCode("+91");
                                          setPhoneNumber(value);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              />
                              <Form.Control
                                className="form-input-med input-border-radius-unset input_number"
                                type="hidden"
                                name="phone"
                                value={phoneNumber}
                                autoComplete="off"
                                {...register("phone")}
                                isInvalid={!!errors.phone}
                                data-testid="med-phone"
                                id="med-phone"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.phone?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center mt-lg-2 mt-md-2 mt-4 px-2">
                      <Button
                        type="submit"
                        className="custom-button btn-standard next-button mx-2"
                        data-testid="submit-button"
                        placeholder="submit-button"
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        className="custom-button btn-nobg back-btn mx-2"
                        data-testid="clear-button"
                        placeholder="clear-button"
                        onClick={handleClear}
                      >
                        Clear All
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {clientSearchLoading && <Loader />}
      <ClientSearchSuccessModal
        show={clientSearchSuccessModal}
        hasCloseButton={true}
        setShow={handleNoClick}
        modalContentElement={
          <SearchResultsModalContent text={searchResultText} />
        }
        isSuccess={!isError}
        setYesCallback={handleCloseMember}
        animation={false}
        onClose={handleNoClick}
        text={clientName}
      />
      <FamilyMemberModal
        setShowCallback={() => setFamilyMemberModal(false)}
        setSubmitCallback={onSubmitFamilyDetails}
        showModal={familyMemberModal}
        headerTitle={familymembersearchtitle}
        onClose={() => {
          setFamilyMemberModal(false);
        }}
      />
      {familyMemberSearchLoading && <Loader />}
      <ModalPopupType5
        show={familySearchSuccessModal}
        setShow={() => setFamilySearchSuccessModal(false)}
        hasFooter={isRegistered}
        modalClass={
          isRegistered
            ? "modal-container modal-text px-3"
            : "modal-container modal-text px-3 pb-4"
        }
        modalContentElement={
          <WillUploadedModalContent
            text={searchResultText}
            isError={isRegistered}
          />
        }
        onClickCloseNavigation={handleCloseFamilyDetails}
      />
    </>
  );
}

export default DeathClaimPage;
