// breadcrumb labels
export const empManagement = "Professional Management";
export const addemp = "Add Professional";
export const viewEmp = "View Professional";
export const editEmp = "Edit Professional";
export const createEmpSuccessText = "Professional added successfully.";
export const createEmpFailedText = "Professional addition has failed.";
export const editEmpSuccessText = "Professional details successfully edited.";
export const editEmpFailedText = "The professional edit has failed.";
export const changePasswordSuccessText =
  "You have changed your password successfully";
export const changePasswordFailedText = "The password change has failed.";
export const bulkUpload = "Bulk Upload";
export const uploadDetails = "Upload Member Details";
export const professionalnotFound = "Professional data not found";
export const memberPlaceholder = {
  memberName: "Member Name",
  email: "Email",
  phone: "Mobile Phone",
  actions: "Actions",
};
export const deleteMemberText="Are you sure you want to delete the member {} ?";

export const reportsPlaceholder = {
  platformUtiReport: "Platform Utilization Report",
  docAndCapacityReport: "Document and Capacity Report",
  totalUsers: "Total Users",
  monthlySessionPerUser: "Monthly Session Per User",
  willsUploaded: "Wills Uploaded",
  deviceOfChoice: "Device Of Choice",
  desktop: "Desktop",
  tablet: "Tablet",
  mobile: "Mobile",
  mostActiveUser: "Most Active User",
  userName: "User Name",
  avgMonthlySession: "Session",
  totalTimeSpent: "Total Time Spent",
  timeLoggedInPerSess: "Time Logged In Per Session",
  TotalDocs: "Total Documents",
  AvgMonthlyUpload: "Upload",
  avgMonthlyDownload: "Download",
  docsPerUser: "Documents Per User",
  greatThan90Per: "Greater than 90% Capacity",
  capacityConsump: "Capacity Consumption",
  noOfDocs: "No of Documents",
};
