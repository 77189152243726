import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLazyDownloadShareBankDetailsQuery } from "../../slices/wireTransferUploadApiManagementSlice";
import Loader from "../Loader/Loader";
import ModalPopupType7 from "../modal/ModalPopupType7";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

export default function ShareUserLandingPage() {
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [trigger, { isLoading }] = useLazyDownloadShareBankDetailsQuery();

  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );

  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );

  useEffect(() => {
    if (identifierID !== "null") {
      setShowModal(true);
    } else {
      setShowMessage(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleConfirm = async () => {
    setShowModal(false);
    try {
      const result = await trigger({
        identifier: identifierID,
        requestType,
      }).unwrap();
      const url = window.URL.createObjectURL(new Blob([result]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "Bank_details.csv";
      a.setAttribute("id", "download-sample");
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded successfully");
    } catch (err) {
      toast.error(
        err?.data?.message ||
          err?.result?.message ||
          "Parameter identifier is invalid! expected type UUID"
      );
    }
    setShowMessage(true);
  };

  return (
    <>
      <div
        className="superadmin-container attorney-edit-client-page"
        data-testid="share-user-landing-page"
      >
        <Container fluid className="px-lg-4">
          {showMessage && (
            <div className="firm-page-outer-container firm-page-add-outer-container">
              <div className="p-4 py-5">
                <div className="px-4 py-2 external-user-message">
                  Thanks you very much for using FamVault. <br />
                  Please visit us on{" "}
                  <a href="https://www.famvault.com" className="anchor-tag">
                    www.famvault.com
                  </a>{" "}
                  to know more about us.
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      <ModalPopupType7
        // setShowCallback={handleCancel}
        setHideCallback={() => setShowModal(false)}
        setShowCallback={handleConfirm}
        content={
          <span>
            Thanks for validationg yourself. Your file will download
            automatically.
            <br />
            <br />
            To know more about us, please visit
            <br />
            www.familyvault.com
          </span>
        }
        showModal={showModal}
      />
      {isLoading && <Loader />}
    </>
  );
}
