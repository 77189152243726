export const inputFocus = (elmnt, min, max) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      const backIndex = min - 2;
      if (next > backIndex) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < max) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  export const handleMaxNumber = (event) => {
    const limit = event.target.name === "phone" ? 10 : 5;
    const val = event.target.value;
    event.target.value = val.slice(0, limit);
  };