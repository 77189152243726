import React from "react";
import { Tab, Row, Nav, Container } from "react-bootstrap";
import {firmLandingPlaceholder} from "../../constants/firm/labels";

const TabButton = ({ activeTab, setActiveTab }) => {
  return (
    <Container className="tab-container-ag-grid">
      <Tab.Container
        fluid
        id="firmTabs"
        activeKey={activeTab}
        onSelect={(tabKey) => setActiveTab(tabKey)}
        data-testid="tab-container1"
      >
        <Row className="ag-tab-button">
          <Nav
            variant="pills"
            data-testid="tab-container"
          >
            <Nav.Item className="tab-nav-item w-100">
              <Nav.Link
                eventKey="attorneyBased"
                className={activeTab === "attorneyBased" ? "active-tab" : ""}
              >
                {firmLandingPlaceholder.threeTierOrgButtonText}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-nav-item w-100">
              <Nav.Link
                eventKey="nonAttorneyBased"
                className={activeTab === "nonAttorneyBased" ? "active-tab" : ""}
              >
                {firmLandingPlaceholder.twoTierOrgButtonText}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default TabButton;
