import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import FormTitle from "../components/form/FormTitle";
import { passwordRules } from "../constants/regExp";
import PasswordCriteria from "../components/widgets/PasswordCriteria";
import Loader from "../components/Loader/Loader";
import { useNavigate, useLocation } from "react-router";
import { useResettemppasswordMutation } from "../slices/loginAuthManagementSlice";
import { login } from "../constants/routes";

export default function ResetTemporaryPassword() {
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const schema = yup.object().shape({
    newpassword: yup
      .string()
      .required("New Password is Required")
      .matches(passwordRules, { message: "Enter the valid Password" })
      .test('not-temporary-password', 'New password should not match temporary password', function(value) {
        return value !== location?.state?.password;
      }),
    confirmpassword: yup
      .string()
      .oneOf(
        [yup.ref("newpassword"), null],
        "New Password and Confirm Password should match"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const [resettemppassword, { isLoading }] = useResettemppasswordMutation();
  
  const onSubmit = async (formData) => {
    let transformData = {
      newPassword: formData.confirmpassword,
      userName: location?.state?.userName,
      sessionId: location?.state?.sessionId,
    };

    const data = JSON.stringify(transformData);
    try {
      const res = await resettemppassword(data).unwrap();
      if (res?.isSuccessful) {
        navigate(login);
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.errorMessage);
    }
  };

  return (
    <>
      <div className="change-password-container">
        <Container fluid>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col className="pe-lg-5 pe-md-5" lg={6}>
              <div className="d-flex justify-content-lg-end justify-content-sm-center mt-4">
                <Form
                  className="custom-form change-password type-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormTitle title="Reset Password" variant="variant-4" />
                  <Form.Group className="pb-1">
                    <Form.Label htmlFor="new-password" className="required">
                      Enter the New Password
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="password"
                      id="new-password"
                      name="newpassword"
                      autoComplete="off"
                      {...register("newpassword")}
                      isInvalid={!!errors.newpassword}
                      data-testid="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newpassword?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <PasswordCriteria />
                  <Form.Group className="pb-1">
                    <Form.Label htmlFor="confirm-password" className="required">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      className="form-input"
                      type="password"
                      id="confirm-password"
                      name="confirmpassword"
                      autoComplete="off"
                      {...register("confirmpassword")}
                      isInvalid={!!errors.confirmpassword}
                      data-testid="confirm-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmpassword?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end  mt-4">
                    <Button
                      className="custom-button btn-standard btn-submit w-100"
                      type="submit"
                      data-testid="submit-button"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {isLoading && <Loader />}
    </>
  );
}
