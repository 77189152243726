import { additionalProfApiSlice } from "./additionalProfApiSlice";

export const additionalProfApiManagementSlice =
additionalProfApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getClientProfessionalAccess: builder.query({
        query: (clientId) => ({
          url: `/professional-access/${clientId}`,
          method: "GET",
        }),
      }),
      getAdditionalProfRootDocs: builder.query({
        query: (identifierId) => ({
          url: `/root-doc/${identifierId}`,
          method: "GET",
        }),
      }),
      getAdditionalProfDocTypes: builder.query({
        query: ({identifierId, rootDocId}) => ({
          url: `/doc-type/${identifierId}?rootDocId=${rootDocId}`,
          method: "GET",
        }),
      }),
      getAdditionalProfAllRootDocsByIdentifier: builder.query({
        query: ({identifierId, rootDocId}) => ({
          url: `/documents/${identifierId}?rootDocId=${rootDocId}`,
          method: "GET",
        }),
        providesTags: ["getAdditionalProfAllDocsByIdentifier"],
      }),
      deleteAdditionalProfDocument: builder.mutation({
        query: ({docId, identifierId}) => ({
          url: `${docId}?identifier=${identifierId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["getAdditionalProfAllDocsByIdentifier"],
      })
    }),    
  });

export const {
  useGetClientProfessionalAccessQuery,
  useLazyGetAdditionalProfRootDocsQuery,
  useLazyGetAdditionalProfDocTypesQuery,
  useGetAdditionalProfAllRootDocsByIdentifierQuery,
  useDeleteAdditionalProfDocumentMutation
} = additionalProfApiManagementSlice;
