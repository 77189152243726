import { loginApiSlice } from "./loginApiSlice";

export const loginAuthManagementSlice = loginApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signinwithsso: builder.query({
      query: (data) => ({
        url: `/sso/status?email=${data}`,
        method: "GET",
      }),
    }),
    signinmvs: builder.mutation({
      query: (data) => ({
        url: `/sign-in`,
        method: "POST",
        body: data,
      }),
    }),
    signinwithmfa: builder.mutation({
      query: (data) => ({
        url: `/mfa/signIn`,
        method: "POST",
        body: data,
      }),
    }),
    signoutsuperuser: builder.mutation({
      query: (token) => ({
        url: `/signOut?accesstoken=${token}`,
        method: "POST",
        body: {},
      }),
    }),
    forgotpasswordsendotp: builder.mutation({
      query: (emailid) => ({
        url: `/forgot-password?user=${emailid}`,
        method: "POST",
      }),
    }),
    signinsuperuser: builder.mutation({
      query: (data) => ({
        url: `/signInMvsSuperAdmin`,
        method: "POST",
        body: data,
      }),
    }),
    resettemppassword: builder.mutation({
      query: (data) => ({
        url: `/change-password`,
        method: "POST",
        body: data,
      }),
    }),
    resetUserPassword: builder.mutation({
      query: (data) => ({
        url: "/reset-password",
        method: "POST",
        body:data
      }),
    }),
    clientSearch: builder.mutation({
      query: (data) => ({
        url: "/clients/search",
        method: "POST",
        body:data
      }),
    }),
    medicalProfessionalLog : builder.mutation({
      query: (data) => ({
        url: "/medicalProfessional",
        method: "POST",
        body:data
      }),
    }),
  }),
});

export const {
  useLazySigninwithssoQuery,
  useSigninmvsMutation,
  useSigninwithmfaMutation,
  useSignoutsuperuserMutation,
  useForgotpasswordsendotpMutation,
  useSigninsuperuserMutation,
  useResettemppasswordMutation,
  useResetUserPasswordMutation,
  useClientSearchMutation,
  useMedicalProfessionalLogMutation
} = loginAuthManagementSlice;
