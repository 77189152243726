import { Container, Nav } from "react-bootstrap";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { useState } from "react";
import FirmDetailsReport from "../../components/reports/FirmDetailsReport";
import UsageReport from "../../components/reports/UsageReport";

export default function FirmReportsPage() {
  const [activeTab, setActiveTab] = useState("company-details");

  const handleSelectTab = (selectedKey) => {
    setActiveTab(selectedKey);
  };

  return (
    <>
      <div
        className="firm-title-page-container"
        data-testid="firm-reports-page"
      >
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-2">
            <FirmPageTitleBar title="Reports" hasButton={false} />
          </div>
        </Container>
      </div>
      <div className="px-3 px-lg-5 py-4 firm-reports-page-container">
        <div>
          <div className="firm-report-nav-div">
            <Nav
              variant="underline"
              activeKey={activeTab}
              onSelect={handleSelectTab}
              defaultActiveKey="company-details"
            >
              <Nav.Item>
                <Nav.Link
                  className="px-3 text-center align-content-center"
                  eventKey="company-details"
                >
                  Firm Details Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="px-3 text-center align-content-center"
                  eventKey="usage-report"
                >
                  Usage Report
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
      <div>
        {activeTab === "company-details" && <FirmDetailsReport />}
        {activeTab === "usage-report" && <UsageReport />}
      </div>
    </>
  );
}
