import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down-doc-part.svg";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useLocation } from "react-router";
import { ReactComponent as DeleteAction } from "../../assets/images/DeleteAction.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useDeleteAdditionalProfDocumentMutation } from "../../slices/additionalProfApiManagementSlice";
import ModalPopupType2 from "../modal/ModalPopupType2";
import { useDispatch, useSelector } from "react-redux";
import { signOutApiCall } from "../../slices/authSlice";

export default function AdditionalProfDocumentDetails({
  allDocumentsData,
  userDetails,
  noaction,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [progressMap, setProgressMap] = useState({});
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [docDetails, setDocDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [activeKey, setActiveKey] = useState(["0"]);
  const [deleteDocument, { isLoading: docDeleteLoading }] =
    useDeleteAdditionalProfDocumentMutation();
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    return <div onClick={decoratedOnClick}>{children}</div>;
  };

  const handleDownload = async (docId, docName) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/professional/${docId}?identifier=${location?.state?.doc?.identifier}`;
    const token = userInfo?.accessToken;
    try {
      const response = await axios({
        url: baseUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: "Bearer " + token },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;

          if (totalLength !== null) {
            setLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docId]: { progress, docName },
            }));
          }
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${docName}`;
        a.setAttribute("id", "download-doc");
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // Handle downloaded file data
        toast.success(`${docName} has been downloaded successfully!`);
        setTimeout(() => {
          setProgressMap((prevProgressMap) => {
            const updatedProgressMap = { ...prevProgressMap };
            delete updatedProgressMap[docId];
            return updatedProgressMap;
          });
        }, 2000);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      setLoading(false);
      console.error("Error downloading file:", error);
      toast.error(`${docName} downloading failed. Try again!`);
      setProgressMap((prevProgressMap) => {
        const updatedProgressMap = { ...prevProgressMap };
        delete updatedProgressMap[docId];
        return updatedProgressMap;
      });
    }
  };

  const handleViewPageNavigation = async () => {
    const { docId } = docDetails;
    try {
      const res = await deleteDocument({
        docId,
        identifierId: location?.state?.doc?.identifier,
      }).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        setConfirmModal(false);
      } else {
        setConfirmModal(false);
        toast.error(res?.message);
      }
    } catch (err) {
      setConfirmModal(false);
      console.error("Error deleting document:", err);
      toast.error(err?.error || err?.data?.error);
    }
  };

  const handleDelete = (docId, docName) => {
    setConfirmModal(true);
    setDocDetails({ docId, docName });
  };
  useEffect(() => {
    const type = location?.state?.docType;
    if (allDocumentsData && allDocumentsData.length > 0 && type) {
      let uniqueDocsMap = new Map();
      uniqueDocsMap = Object.values(
        allDocumentsData.reduce((acc, file) => {
          if (!acc[file.rootDocTypeId]) {
            acc[file.rootDocTypeId] = [];
          }
          acc[file.rootDocTypeId].push(file);
          return acc;
        }, {})
      )
        .filter((group) => group && group.length > 0)
        .sort((a, b) => {
          const rootIdA = a[0] ? a[0].rootDocTypeId : "";
          const rootIdB = b[0] ? b[0].rootDocTypeId : "";
          return rootIdA.localeCompare(rootIdB);
        });

      const getActiveKey = (docGroups, type) => {
        if (!type?.startsWith("Will has been")) {
          const index = docGroups.findIndex(
            (group) =>
              group[0]?.rootDocTypeName !== "Will" &&
              type?.includes(group[0]?.rootDocTypeName)
          );
          return index.toString();
        }
        return ["0"];
      };
      const key = getActiveKey(uniqueDocsMap, type);
      setActiveKey([key]);
    }
  }, [location?.state?.docType, allDocumentsData]);
  function isVideoFile(filename) {
    // Define a regular expression pattern to match video file extensions
    const videoFileExtensions =
      /\.(mp4|m4v|mkv|mov|avi|wmv|flv|webm|mpeg|mpg|3gp|3g2)$/i;

    // Test the filename against the pattern
    return videoFileExtensions.test(filename);
  }
  return (
    <>
      <Accordion
        //defaultActiveKey={findDefault}
        className="mb-1"
        activeKey={activeKey}
        alwaysOpen
        onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
      >
        {allDocumentsData &&
          allDocumentsData.length > 0 &&
          Object.values(
            allDocumentsData.reduce((acc, file) => {
              if (!acc[file.rootDocTypeId]) {
                acc[file.rootDocTypeId] = [];
              }
              acc[file.rootDocTypeId].push(file);
              return acc;
            }, {})
          )
            .filter((group) => group && group.length > 0)
            .sort((a, b) => {
              const rootIdA = a[0] ? a[0].rootDocTypeId : "";
              const rootIdB = b[0] ? b[0].rootDocTypeId : "";
              return rootIdA.localeCompare(rootIdB);
            })
            .map((documentGroup, index) => (
              <Card
                key={documentGroup[0].rootDocTypeId}
                className="custom-card mb-1"
              >
                <Card.Header className="custom-card-header">
                  <CustomToggle eventKey={`${index}`}>
                    <div className="d-flex">
                      <div>
                        <ArrowDown />
                      </div>
                      <div
                        className="ps-3"
                        data-testid={`doctype-${documentGroup[0].rootDocTypeId}`}
                      >
                        {documentGroup[0].rootDocTypeName}
                      </div>
                    </div>
                  </CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey={`${index}`}>
                  <Card.Body>
                    {documentGroup.length >= 1 && (
                      <div>
                        <div className="doc-section">
                          <div className="doc-inner-section multiple d-flex justify-content-between d-block d-md-none ">
                            {documentGroup.map((file, index) => (
                              <div key={index} className="position-relative">
                                <div>
                                  <div
                                  //className="file-part"
                                  >
                                    File Name
                                  </div>
                                  <div
                                    className="file-name-others"
                                    data-testid={
                                      "filename-mb-" +
                                      file.rootDocTypeId +
                                      index
                                    }
                                  >
                                    {file.docName}
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <div className="delete-doc-others d-flex small">
                                    {
                                      !isVideoFile(file.docName) && (
                                        <div className="me-3">
                                            <Download
                                              onClick={() =>
                                                handleDownload(
                                                  file.docId,
                                                  file.docName
                                                )
                                              }
                                              data-testid="download"
                                            />
                                          </div>
                                      )
                                    }
                                    <div>
                                      <DeleteAction
                                        data-testid={
                                          "filename-delete-mb" +
                                          file.rootDocTypeId +
                                          index
                                        }
                                        onClick={() =>
                                          handleDelete(file.docId, file.docName)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                {index === documentGroup.length - 1 ? null : (
                                  <hr />
                                )}
                              </div>
                            ))}
                          </div>
                          <div className="d-none d-md-block">
                            <Table className="table table-responsive table-custom-document">
                              <thead>
                                <tr>
                                  <td style={{ width: "30%" }}>File Name</td>
                                  <td style={{ width: "20%" }}></td>
                                  <td style={{ width: "20%" }}></td>
                                  <td style={{ width: "15%" }}></td>
                                  <td style={{ width: "15%" }}></td>
                                  <td style={{ width: "15%" }}></td>
                                </tr>
                              </thead>
                              <tbody>
                                {documentGroup.map((file, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="file-name-others">
                                          <div
                                            //className="file-part"
                                            data-testid={
                                              "filename-" +
                                              file.rootDocTypeId +
                                              index
                                            }
                                          >
                                            {file.docName}
                                          </div>
                                        </div>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <div className="d-flex align-items-center icon-document-web-section">
                                          {
                                            !isVideoFile(file.docName) && (
                                              <OverlayTrigger
                                                  placement="top"
                                                  overlay={
                                                    <Tooltip className="tooltip">
                                                      Download
                                                    </Tooltip>
                                                  }
                                                >
                                                <div className="me-4">
                                                  <Download
                                                    onClick={() =>
                                                      handleDownload(
                                                        file.docId,
                                                        file.docName
                                                      )
                                                    }
                                                    data-testid="download"
                                                  />
                                                </div>
                                              </OverlayTrigger>
                                          )
                                          }
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip className="tooltip">
                                                Delete
                                              </Tooltip>
                                            }
                                          >
                                            <div className="me-4">
                                              <DeleteAction
                                                onClick={() =>
                                                  handleDelete(
                                                    file.docId,
                                                    file.docName
                                                  )
                                                }
                                                data-testid={
                                                  "filename-delete-" +
                                                  file.rootDocTypeId +
                                                  index
                                                }
                                              />
                                            </div>
                                          </OverlayTrigger>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
      </Accordion>

      {(isLoading || docDeleteLoading) && <Loader />}

      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId, { progress, docName }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>{docName} downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ModalPopupType2
        setShowCallback={() => setConfirmModal(false)}
        setYesCallback={handleViewPageNavigation}
        content={`Are you sure want to delete (${docDetails?.docName}) the  file?`}
        showModal={showConfirmModal}
      />
    </>
  );
}
