import { documentApiManagement } from "./documentApiManagement";
import { userApiSlice } from "./userApiSlice";

export const nonAttorneySuperAdminApiManagementSlice =
  userApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getAllClientDetailsNonAttorney: builder.query({
        query: ({ firmId, roleName }) => ({
          url: `/clients/non-attorney?firmId=${firmId}&roleName=${roleName}`,
          method: "GET",
        }),
        providesTags: ["getAllClientDetails"],
      }),
      createClient: builder.mutation({
        query: (data) => ({
          url: "/clients",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["getClientbyid", "getAllClientDetails"],
      }),
      getClient: builder.query({
        query: (clientId) => ({
          url: `/clients/${clientId}`,
          method: "GET",
        }),
        providesTags: ["getClientbyid"],
      }),
      editClient: builder.mutation({
        query: (data) => ({
          url: "/clients",
          method: "PUT",
          body: data,
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled;
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
        },
        invalidatesTags: ["getClientbyid", "getAllClientDetails"],
      }),
      deleteClient: builder.mutation({
        query: (clientId) => ({
          url: `/${clientId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["getAllClientDetails"],
      }),
    }),
  });

export const {
  useGetAllClientDetailsNonAttorneyQuery,
  useCreateClientMutation,
  useGetClientQuery,
  useEditClientMutation,
  useDeleteClientMutation,
} = nonAttorneySuperAdminApiManagementSlice;
