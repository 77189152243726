import React from "react";
import { Modal, Button, Container, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { useUpdatePersonalDocsMutation } from "../../slices/personalUseApiManagementSlice";
import {ReactComponent as Info} from "../../assets/images/Info-Personal-Use.svg"

export default function PersonalUseModalPopup({
  showModal,
  setShowCallback,
  setSuccessCallback,
  selectedFolders,
}) {
  const { userInfo } = useSelector((state) => state.auth);

  const handleClose = () => {
    setShowCallback(false);
  };

  const [updateDocs, { error, isLoading }] = useUpdatePersonalDocsMutation();
  const onSubmit = async () => {
    try {
      const res = await updateDocs({
        userId: userInfo.userId,
        docIds: selectedFolders,
      }).unwrap();
      if (res?.isSuccessful) {
        setSuccessCallback();
      } else {
        toast.error(res?.message || res.error || res.errorMessage);
      }
    } catch (err) {
      toast.error(
        err?.data?.message || err?.errorMessage || err?.data?.errorMessage || err.error
      );
    }
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data && error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="personal-use-modal"
        data-testid="personal-use-modal-container"
      >
        <Modal.Header
          className="share-doc-modal-header receive-file-header"
          closeButton
        >
        </Modal.Header>
        <Modal.Body className="share-doc-modal-body">
        <div className="d-flex justify-content-center">
        <div className="px-3">
            <div className="d-flex justify-content-center pb-3 pt-2">
                <Info />
            </div>
            {selectedFolders && selectedFolders.length === 0 ? 
            <div className="text-align-center is-personal-use my-3 mb-5">
            Are you sure you want to change the folder/file settings?              
            </div>
            :
            <>
              <div className="text-align-center is-personal-use">
            By marking these files, you ensure they will be deleted upon your death. However, you retain the ability to change this setting as long as you have access to this platform.
            </div>
            <div className="my-3 mb-4">
            <b>Are you certain you want to designate these folders/files for personal use only?</b>
            </div>
            </>
              
            }
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0 pb-4">
          <Button
            className="custom-button btn-standard doc-share-submit-btn"
            type="button"
            onClick={onSubmit}
            data-testid="personal-use-submit-btn"
          >
            Yes
          </Button>
          <Button
            className="custom-button doc-share-submit-btn-nobg"
            type="button"
            onClick={handleClose}
            data-testid="personal-use-no-btn"
          >
            No
          </Button>
        </Modal.Footer>
        {isLoading && <Loader />}
      </Modal>
    </>
  );
}
