import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/headers/Header";
import FormTitle from "../components/form/FormTitle";
import {login} from "../constants/routes";
import { resetPassword, forgotPasswordSuccessText } from "../constants/labels";
export default function ForgotPasswordSuccessPage() {
    const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="otp-forgot-password-container">
        <Container fluid>
          <Row>
            <Col
              lg={{ span: 4, offset: 7 }}
              md={{ span: 6, offset: 5 }}
              xl={{ span: 3, offset: 8 }}
              xxl={{ span: 3, offset: 8 }}
            >
              <div className="custom-form d-flex justify-contant-lg-end justify-content-md-end justify-content-sm-center mt-5">
                <div className="forgot-password-success">
                  <div>
                    <FormTitle title={resetPassword} variant="variant-2" />
                  </div>
                  <div className="success-text">
                   {forgotPasswordSuccessText}     
                </div>
                  <div className="d-flex justify-content-end  mt-3">
                    <Button
                      className="custom-button btn-standard btn-submit go-login-btn"
                      onClick={() => navigate(login)}
                      data-testid="goto-login-btn"
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
