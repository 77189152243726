import React, { useState, useEffect, useCallback} from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Offcanvas, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRules } from "../../constants/regExp";
import Loader from "../Loader/Loader";
import { changeAttorney } from "../../constants/labels";
import { formPlaceholder } from "../../constants/firm/labels";
import { validation } from "../../constants/firm/validationsMessages";
import { changeAttorneyLabels } from "../../constants/client/labels";
//import {setUserNotification} from "../../slices/authSlice";
import { useLazyGetAttorneyByEmailQuery, useLazyGetAttorneyByClientIdQuery } from "../../slices/userAuthManagementSlice";
import { useReassignProfessionalMutation } from "../../slices/superAdminApiManagementSlice";
import {
  professionalnotFound,
} from "../../constants/firmdmin/labels";
import TooltipPopover from "../tooltip/TooltipPopover";
export default function ClientChangeAttorney({
  visible,
  onClose,
  state,
  setSuccessModal,
}) {
  const [professionalData, setProfessionalData] = useState(null);
  const [currentProfessionalData, setCurrentProfessional]=useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const schema = yup.object().shape({
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`)
      .test({
        name: "new-professional-email",
        skipAbsent: true,
        test: (value, ctx) => {
          if(currentProfessionalData?.email && (value === currentProfessionalData?.email)){
            return ctx.createError({
              message: validation.currentProfessional.message,
            });
          }
          return true;
        }
      }),
    
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [trigger, { isLoading }] = useLazyGetAttorneyByEmailQuery();
  const [reassignProfessional, { isLoading: reassignProfessionalLoading }] =
    useReassignProfessionalMutation();
  const [getCurrentAttorney, {isLoading:currentAttorneyLoading}] = useLazyGetAttorneyByClientIdQuery();  
  const getCurrentAttorneyDetails = useCallback(async () => {
    if (userInfo?.userId && userInfo?.userId!== undefined) {
      try{
        const res = await getCurrentAttorney(userInfo?.userId).unwrap();
        if (res?.attorneyId) {
          setCurrentProfessional(res);
        } else {
          toast.error(res?.errorMessage);
        }
      }catch(error){
        toast.error(error?.errorMessage);
      }
      
    }
  }, [userInfo?.userId, getCurrentAttorney]);
  useEffect(() => {
    if (userInfo?.userId) {
      getCurrentAttorneyDetails();
    }
  }, [userInfo?.userId, getCurrentAttorneyDetails]);
  const handleBackButton = () => {
    setProfessionalData(null);
    setValue("email", "");
  };
  const onSubmit = async (formData) => {
    try {
      if (professionalData === null) {        
        const res = await trigger({
          email: formData.email,
          firmId: userInfo?.firmId,
        }).unwrap();
        if(res?.data && res?.data?.length>0){
          setProfessionalData(res?.data[0]);
          toast.success(res?.message);
        }else{
          if(res === null){
            toast.error(professionalnotFound);
            return;
          }
          toast.error(res?.errorMessage);
        }
      } else {
        const transformData = {
          newProfessionalUserId: professionalData?.attorneyId,
          clientUserIds: [userInfo?.userId],
        };
        const data = JSON.stringify(transformData);
        const res = await reassignProfessional(data).unwrap();
        if (res?.isSuccessful) {
          setSuccessModal(true);
        } else {
          toast.error(res?.errorMessage);
        }
      }
      //}
    } catch (err) {
      if (err?.status === 404) {
        toast.error(professionalnotFound);
      } else {
        toast.error(err?.errorMessage);
      }
    }
  };
  return (
    <>
      <Offcanvas
        className="offcanvas-change-attorney"
        show={visible}
        onHide={onClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="change-attorney-title d-flex">
            {changeAttorney}
            <div className="ps-2">
            <TooltipPopover
              content={
                <>
                  You can change the name of the professional linked to your account
                </>
              }
              placement="bottom"
            />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="px-3">
          <div className="divider-firm"></div>
        </div>
        <Offcanvas.Body>
          <div className="current-attorney-div d-flex flex-column">
            <span className="current-attr-text mt-2">
              {changeAttorneyLabels.currentProfessional}: <span className="ps-2">{currentProfessionalData!== null && currentProfessionalData?.fullName? currentProfessionalData?.fullName:"--" }</span>
            </span>
            <span className="current-attr-text mt-2">
              {changeAttorneyLabels.mobileNo}: <span className="ps-2">{currentProfessionalData!== null && currentProfessionalData?.phone? currentProfessionalData?.phone:"--" }</span>
            </span>
            <span className="current-attr-text mt-2 mb-2">
              {changeAttorneyLabels.emailId}: <span className="ps-2">{currentProfessionalData!== null && currentProfessionalData?.email? currentProfessionalData?.email:"--" }</span>
            </span>
          </div>
          <Form
            className="custom-form w-100 type-2 mt-3"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input input-border-radius-unset"
                type="email"
                name="email"
                placeholder={formPlaceholder.enterNewProfessional}
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur = {(e) => e.target.value = e.target.value.trim()}
                data-testid="firm-email"
                id="firm-email"
                disabled={professionalData === null?  false:true}
              />
              {
                <Form.Control.Feedback
                  data-testid="invalid-feedback"
                  type="invalid"
                >
                  {errors.email?.message}
                </Form.Control.Feedback>
              }
            </Form.Group>
            {professionalData && Object.keys(professionalData).length > 0 && (
              <div className="new-attorney-title d-flex mb-2">
                {changeAttorneyLabels.newAttorneyDetails}
              </div>
            )}
            {professionalData && Object.keys(professionalData).length > 0 && (
              <div className="overflow-div">
                <div>
                  <div className="attorney-details d-flex pb-2">
                    <div className="new-attorney-text d-flex flex-column align-items-start">
                      <div className="">
                        <span title={professionalData?.fullName}>
                          {changeAttorneyLabels.attorneyName}:{" "}
                          {professionalData?.fullName}
                        </span>
                      </div>
                      <div className="mt-2">
                        <span>
                          {changeAttorneyLabels.mobileNo}:{" "}
                          {professionalData?.phone}
                        </span>
                      </div>
                      <div className="mt-2">
                        <span title={professionalData?.email}>
                          {changeAttorneyLabels.emailId}:{" "}
                          {professionalData?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end mt-4">
              {professionalData && Object.keys(professionalData).length > 0 && (
                <Button
                  className="custom-button btn-nobg mx-4"
                  data-testid="cancel-button"
                  onClick={handleBackButton}
                >
                  {formPlaceholder.backButton}
                </Button>
              )}
              {
                currentProfessionalData!== null && currentProfessionalData?.attorneyId
                &&
                <Button
                type="submit"
                className="custom-button btn-standard"
                data-testid="submit-button"
              >
                {professionalData === null ? formPlaceholder.searchBtnText:formPlaceholder.submitBtnText}
              </Button>
              }
              
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      {(isLoading || reassignProfessionalLoading || currentAttorneyLoading) && <Loader />}
    </>
  );
}
