import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import FormTitle from "../components/form/FormTitle";
import { emailRules } from "../constants/regExp";
import Header from "../components/headers/Header";
import {
  sploginEmailReqValidation,
  sploginEmailValidation,
  forgotPasswordText,
  forgotPassword,
} from "../constants/labels";
import { forgotpasswordPage } from "../constants/routes";
import Loader from "../components/Loader/Loader";
import { useForgotpasswordsendotpMutation } from "../slices/loginAuthManagementSlice";
export default function ForgotPasswordEmail() {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    email: yup
      .string().trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const [trigger, { isLoading }] = useForgotpasswordsendotpMutation();
  const onSubmit = async (formData) => {
    try {
      const res = await trigger(formData.email).unwrap();
      if (res?.isSuccessful) {
        toast.success(res?.message);
        navigate(forgotpasswordPage, {
          state: {
            useremail: formData.email,
          },
        });
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.errorMessage);
    }
  };
  return (
    <>
      <Header />
      <div className="otp-forgot-password-container">
        <Container fluid>
          <Row>
            <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="login-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-forgotpassword-container">
                  <Form
                    className="custom-form w-100 forgot-password-email type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle title={forgotPassword} variant="variant-5" />
                    <Form.Group className="pb-1 max-width pt-2">
                      <Form.Label
                        htmlFor="forgot-password-email"
                        className="required f-w-400 f-s-1rem"
                      >
                        {forgotPasswordText}
                      </Form.Label>
                      <Form.Control
                        className="form-input h-3rem"
                        type="text"
                        id="forgot-password-email"
                        name="email"
                        autoComplete="off"
                        placeholder="Email"
                        //onChangeCapture={(e) => handleOldPassword(e)}
                        {...register("email")}
                        isInvalid={!!errors.email}
                        onBlur = {(e) => e.target.value = e.target.value.trim()}
                        data-testid="forgot-password-email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-flex justify-content-end  mt-3 max-width">
                      <Button
                        className="custom-button btn-standard btn-w50"
                        type="submit"
                        data-testid="submit-button"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="background-mvs-image type-1"></div>
      {isLoading && <Loader />}
    </>
  );
}
