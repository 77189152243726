import { documentApiManagement } from "./documentApiManagement";
import { userApiSlice } from "./userApiSlice";

export const attorneyApiManagementSlice = userApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClientDetails: builder.query({
      query: (attorneyId) => ({
        url: `/attorneys/${attorneyId}/clients`,
        method: "GET",
      }),
      providesTags : ['getAllClientDetails']
    }),
    createClient: builder.mutation({
      query: (data) => ({
        url: "/clients",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['getClientbyid', 'getAllClientDetails']
    }),
    getClient: builder.query({
      query:(clientId) => ({
        url: `/clients/${clientId}`,
        method: "GET",
      }),
      providesTags : ['getClientbyid']
    }),
    editClient: builder.mutation({
      query: (data) => ({
        url: "/clients",
        method: "PUT",
        body: data,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
        );
      },
      invalidatesTags: ['getClientbyid','getAllClientDetails']
    }),
    editExecutor: builder.mutation({
      query: (data) => ({
        url: "/executor/profile",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getClientbyid"],
    }),
  }),
});

export const { 
  useGetAllClientDetailsQuery,
  useCreateClientMutation,
  useGetClientQuery,
  useLazyGetClientQuery,
  useEditClientMutation,
  useEditExecutorMutation
 } = attorneyApiManagementSlice;
