import { Check2Circle } from "react-bootstrap-icons";
import {ReactComponent as ErrorInfo} from "../../assets/images/ErrorInfo.svg"

export default function WillUploadedModalContent({ text, isError = false }) {
  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center pb-3 pt-0">
          {isError ? <ErrorInfo className="mb-2"/> :<Check2Circle size={54} className="mt-4" fill="#20AE5C" />}
        </div>
        <div className="text-align-center is-will-uploaded">
          {text}
        </div>
      </div>
    </div>
  );
}
