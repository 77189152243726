import { Navbar, Container } from "react-bootstrap";
import { ReactComponent as HeaderLogo } from "../../assets/images/mvs-logo.svg";
import { famVault_wesbite } from "../../constants/routes";

export default function Header() {
  return (
    <Navbar
      className="w-100 custom-navbar p-0"
      style={{ zIndex: 1 }}
      expand="lg"
      collapseOnSelect
    >
      <Container fluid className="mx-3">
        <Navbar.Brand as="a" href={famVault_wesbite} className="custom-brand-name">
          <HeaderLogo />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
