/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import {
  useGetfirmbyidQuery,
  useEditfirmMutation,
} from "../../slices/firmApiManagement";
import Loader from "../../components/Loader/Loader";
import { useGetGeoCodesZipCodeQuery } from "../../slices/ssoManagementSlice";
import { useGetemployeerangetypeQuery } from "../../slices/getFirmOnboardingValuesApi";
import {
  mvssuperadmin_firmEditDetails,
  mvssuperadmin_firmViewDetails,
  mvssuperadmin_landingpage,
} from "../../constants/routes";
//import {useEditfirmMutation} from "../../slices/firmApiManagement";
import { countryCodeList, editFirmSuccessText } from "../../constants/labels";
import { 
  phoneNumberRules, 
  zipRules, 
  emailRules, 
  alphabetRules, 
  indphonNumberRules,
  usphonNumberRules,
 } from "../../constants/regExp";
import { validation } from "../../constants/firm/validationsMessages";
import { formPlaceholder } from "../../constants/firm/labels";
import PhoneInput from "react-phone-input-2";

const FirmDetailsEditPage = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: mvssuperadmin_landingpage,
      content: "Firm Details",
      active: false,
    },
    {
      id: "item-2",
      link: mvssuperadmin_firmViewDetails,
      content: `Firm: --`,
      active: false,
    },
    {
      id: "item-3",
      link: mvssuperadmin_firmEditDetails,
      content: `Edit Firm --`,
      active: true,
    },
  ]);
  const [cities, setCities] = useState([]);
  const [noofemployees, setNoofEmployees] = useState("");
  const [firmIdName, setFirmIdName] = useState({});
  const [zipCode, setZipCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saCountryCode, setSaCountryCode] = useState("");
  const [saPhoneNumber, setSaPhoneNumber] = useState("");
  const location = useLocation();
  const isMobile = window.innerWidth < 600 ? true : false;
  const saSchema = {
    saFirstName: yup
      .string().trim()
      .required(`${validation.saFirstName.required}`)
      .matches(alphabetRules, `${validation.saFirstName.rule}`),
    saLastname: yup
      .string().trim()
      .required(`${validation.saLastname.required}`)
      .matches(alphabetRules, `${validation.saLastname.rule}`),
    saEmail: yup
      .string().trim()
      .required(`${validation.saEmail.required}`)
      .matches(emailRules, `${validation.saEmail.emailRules}`),
    saPhone: yup
      .string()
      .test(
        "sa-phone-validation",
        `${validation.saPhone.phoneNumberRules}`,
        function (value) {
          if (saCountryCode) {
            if (saCountryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (saCountryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.saPhone.phoneNumberRules}`),
  };
  const saMbSchema = {
    saFirstNameMb: yup
      .string().trim()
      .required(`${validation.saFirstName.required}`)
      .matches(alphabetRules, `${validation.saFirstName.rule}`),
    saLastnameMb: yup
      .string().trim()
      .required(`${validation.saLastname.required}`)
      .matches(alphabetRules, `${validation.saLastname.rule}`),
    saEmailMb: yup
      .string().trim()
      .required(`${validation.saEmail.required}`)
      .matches(emailRules, `${validation.saEmail.emailRules}`),
    saPhoneMb: yup
      .string()
      .test(
        "mb-sa-phone-validation",
        `${validation.saPhone.phoneNumberRules}`,
        function (value) {
          if (saCountryCode) {
            if (saCountryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (saCountryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.saPhone.phoneNumberRules}`),
  };
  const schema = yup.object().shape({
    noOfEmployess: yup
      .string()
      .notOneOf([""], `${validation.noOfEmployess.required}`)
      .required(),
    firstName: yup
      .string().trim()
      .required(`${validation.firstName.required}`)
      .matches(alphabetRules, `${validation.firstName.rule}`),
    lastName: yup
      .string().trim()
      .required(`${validation.lastName.required}`)
      .matches(alphabetRules, `${validation.lastName.rule}`),
    email: yup
      .string().trim()
      .required(`${validation.email.required}`)
      .matches(emailRules, `${validation.email.emailRules}`),
    phone: yup
      .string()
      .test(
        "phone-validation",
        `${validation.phone.phoneNumberRules}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${validation.phone.phoneNumberRules}`),
    addressLine1: yup.string().trim().required(`${validation.addressLine1.required}`),
    addressLine2: yup.string().trim(),
    zip: yup
      .string().trim()
      .matches(zipRules, { message: `${validation.zip.zipRules}` }),
    state: yup.string().required(`${validation.state.required}`),
    country: yup.string().required(`${validation.country.required}`),
    cityList: yup.mixed().when("city", {
      is: null,
      then: () =>
        yup
          .string()
          .notOneOf([""], `${validation.cityList.required}`)
          .required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    city: yup.string().required(`${validation.city.required}`).nullable(),
    isMobile: yup.boolean(),
    superadmin: yup.object().when("isMobile", {
      is: true,
      then: () => yup.object().shape(saMbSchema),
      otherwise: () => yup.object().shape(saSchema),
    }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const [editfirm, { isLoading: editfirmLoading }] = useEditfirmMutation();

  const { data, isLoading, error } = useGetfirmbyidQuery(location?.state?.id, {
    skip: location?.state?.id === undefined,
  });

  const { data: geoCodeData, isLoading: geoCodeLoading } =
    useGetGeoCodesZipCodeQuery(zipCode, { skip: zipCode?.length !== 5 });

  const { data: empRangeTypeData, isLoading: empRangeLoading } =
    useGetemployeerangetypeQuery();

  useEffect(() => {
    if (data?.firmName) {
      setNoofEmployees(data.noOfEmployees);

      setItems([
        {
          id: "item-1",
          link: mvssuperadmin_landingpage,
          content: "Firm Details",
          state: {
            id: location?.state?.id,
            activeTab: location?.state?.activeTab,
          },
          active: false,
        },
        {
          id: "item-2",
          link: mvssuperadmin_firmViewDetails,
          content: `Firm: ${data?.firmName}`,
          state: {
            id: location?.state?.id,
            activeTab: location?.state?.activeTab,
          },
          active: false,
        },
        {
          id: "item-3",
          link: mvssuperadmin_firmEditDetails,
          content: `Edit Firm ${data?.firmName}`,
          active: true,
        },
      ]);
      setFirmIdName({
        firmId: data.firmId,
        firmName: data.firmName,
      });
      setValue("noOfEmployess", data.noOfEmployees);
      setValue("firstName", data.firstName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("lastName", data.lastName, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("email", data.emailId, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("addressLine1", data.address.addressLine1, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("addressLine2", data.address.addressLine2, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("zip", data.address.zip, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("city", data.address.city, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("state", data.address.state, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      setValue("country", data.address.country, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      const mbSufix = isMobile ? "Mb" : "";
      setValue(`superadmin.saFirstName${mbSufix}`, data.superAdmin.firstName);

      setValue(`superadmin.saLastname${mbSufix}`, data.superAdmin.lastName);
      setValue(`superadmin.saEmail${mbSufix}`, data.superAdmin.email);
    }
  }, [data, setValue, location?.state?.id, isMobile]);
  useEffect(() => {
    if (data?.firmName) {
      //const mbSufix = isMobile ? "Mb" : "";
      if (data?.firmId && data?.phoneNumber) {
        if (data?.phoneNumber.length === 10) {
          setCountryCode("+1");
          setPhoneNumber(`1${data?.phoneNumber}`);
        } else {
          const firstDigit = data?.phoneNumber.charAt(1);
          const lasttendigit = data?.phoneNumber.slice(-10);

          if (firstDigit === "1") {
            setCountryCode("+1");
            setPhoneNumber(`1${lasttendigit}`);
          } else {
            setCountryCode("+91");
            setPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
      if (data?.firmId && data?.superAdmin?.phoneNumber) {
        if (data?.superAdmin?.phoneNumber.length === 10) {
          setSaCountryCode("+1");
          setSaPhoneNumber(`1${data?.superAdmin?.phoneNumber}`);
        } else {
          const firstDigit = data?.superAdmin?.phoneNumber.charAt(1);
          const lasttendigit = data?.superAdmin?.phoneNumber.slice(-10);

          if (firstDigit === "1") {
            setSaCountryCode("+1");
            setSaPhoneNumber(`1${lasttendigit}`);
          } else {
            setSaCountryCode("+91");
            setSaPhoneNumber(`91${lasttendigit}`);
          }
        }
      }
    }
  }, [data]);
  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);
  useEffect(() => {
    if (saPhoneNumber && saCountryCode) {
      isMobile?
      setValue("superadmin.saPhoneMb", saPhoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      :setValue("superadmin.saPhone", saPhoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [saPhoneNumber, saCountryCode, setValue]);
  useEffect(() => {
    const data = geoCodeData;
    if (data && data?.status === "OK") {
      let usResult = data?.results.find((result) => {
        let country = result.address_components.find((ac) =>
          ac.types.includes("country")
        )?.long_name;
        return (
          country === "United States" ||
          country === "Puerto Rico" ||
          country === "U.S. Virgin Islands" ||
          country === "American Samoa" ||
          country === "Northern Mariana Islands" ||
          country === "Guam"
        );
      });

      let multipleCities = usResult?.postcode_localities;

      let city = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("locality")
      )[0]?.long_name;

      let city1 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("sublocality")
      )[0]?.long_name;

      let city2 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("neighborhood")
      )[0]?.long_name;

      let city3 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_3")
      )[0]?.long_name;

      let city4 = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_2")
      )[0]?.long_name;

      let state = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("administrative_area_level_1")
      )[0]?.long_name;

      let country = usResult?.address_components.filter(
        (ac) => ~ac.types.indexOf("country")
      )[0]?.long_name;

      if (country === "United States") {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, state, country);
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, state, country);
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country === "Puerto Rico" ||
        country === "U.S. Virgin Islands" ||
        country === "American Samoa" ||
        country === "Northern Mariana Islands" ||
        country === "Guam"
      ) {
        let allCities = [
          city,
          city1,
          city2,
          city3,
          city4,
          state,
          ...(multipleCities || []).flatMap((city) =>
            city.split(",").map((c) => c.trim())
          ),
        ];

        allCities = allCities.filter(Boolean);

        allCities = [...new Set(allCities)];

        allCities.sort((a, b) => a.localeCompare(b));

        if (allCities && allCities?.length === 1) {
          setCities([]);
          const filteredCity = allCities[0];
          setValueZip(filteredCity, country, "United States");
        } else if (allCities && allCities?.length > 0) {
          setCities(allCities);
          setValueZip(null, country, "United States");
        } else {
          setCities([]);
          setValueZip("", "", "");
          toast("Please provide the valid zip code");
        }
      }

      if (
        country !== "United States" &&
        country !== "Puerto Rico" &&
        country !== "U.S. Virgin Islands" &&
        country !== "Northern Mariana Islands" &&
        country !== "Guam" &&
        country !== "American Samoa"
      ) {
        toast("Please provide the US zip code");
        setCities([]);
        setValueZip("", "", "");
      }
    } else {
      if (data !== undefined) {
        if (data && data?.status === "ZERO_RESULTS") {
          toast("Please provide the valid zip code");
          setCities([]);
          setValueZip("", "", "");
        } else {
          data?.error_message
            ? toast(`${data?.error_message}`)
            : toast("somthing went wrong");
          setCities([]);
          setValueZip("", "", "");
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoCodeData]);

  const handleViewPageNavigation = () => {
    navigate(mvssuperadmin_firmViewDetails, {
      state: {
        page: "firmViewDetailsPage",
        id: firmIdName.firmId,
        activeTab: location?.state?.activeTab,
      },
    });
  };

  const onSubmit = async (formData) => {
    const saSufix = isMobile ? "Mb" : "";
    const transformData = {
      firmId: firmIdName.firmId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.email,
      mobileNumber: `+${formData.phone}`,
      noOfEmployees: parseInt(formData.noOfEmployess),
      address: {
        addressLine1: formData.addressLine1,
        addressLine2: formData.addressLine2 || "",
        zip: formData.zip,
        city: formData.city === null ? formData.cityList : formData.city,
        state: formData.state,
        country: formData.country,
      },
      superAdmin: {
        firstName: formData.superadmin[`saFirstName${saSufix}`],
        lastName: formData.superadmin[`saLastname${saSufix}`],
        email: formData.superadmin[`saEmail${saSufix}`],
        phoneNumber: `+${formData.superadmin[`saPhone${saSufix}`]}`,
      },
    };

    const data = JSON.stringify(transformData);

    try {
      const res = await editfirm(data).unwrap();
      if (res?.isSuccessful) {
        toast.success(editFirmSuccessText);
        handleViewPageNavigation();
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (
      name === "phone" ||
      name === "superadmin.saPhone" ||
      name === "superadmin.saPhoneMb"
    ) {
      event.target.value = value.slice(0, 10);
    }
    if (name === "zip") {
      event.target.value = value.slice(0, 5);
      setZipCode(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const setValueZip = (city, state, country) => {
    setValue("city", city, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("state", state, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("country", country, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div className="firm-title-page-container" data-testid="firm-view-page">
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={`Edit: Firm ${data?.firmName ? data?.firmName : " --"}`}
              hasButton={false}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container firm-edit-page-outer-container px-lg-4">
        <Container fluid className="pb-1 h-100">
          <div className="firm-page-container full-height px-0 px-lg-4 px-md-1 px-sm-3 pt-3">
            <div className="ps-lg-0 ps-3">
              <BreadCrumb items={items} />
            </div>
            <div className="pt-2 ps-lg-0 ps-3">
              <div className="pb-3 d-lg-flex d-md-flex align-items-start">
                <div className="firm-view-first">
                  <div className="firm-view-label">
                    {formPlaceholder.firmTypeEdit}
                  </div>
                  <div className="firm-view-contant">
                    {data?.firmTypeName ? data?.firmTypeName : "--"}
                  </div>
                </div>
              </div>
            </div>
            <Form
              className="custom-form w-100 type-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Row className="form-row-edit">
                  <Col className="mb-3">
                    <Row>
                      <Col lg={12} md={12} className="">
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Control
                              className="form-input"
                              type="hidden"
                              name="isMobile"
                              value={isMobile}
                              {...register("isMobile")}
                            />
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-firstname"
                                className="required mb-0"
                              >
                                {formPlaceholder.firstName}
                              </Form.Label>
                              <Form.Control
                                className="form-input"
                                type="text"
                                name="firstName"
                                autoComplete="off"
                                maxLength={255}
                                {...register("firstName")}
                                isInvalid={!!errors.firstName}
                                data-testid="firm-firstname"
                                id="firm-firstname"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.firstName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-lastname"
                                className="required mb-0"
                              >
                                {formPlaceholder.lastName}
                              </Form.Label>
                              <Form.Control
                                className="form-input"
                                type="text"
                                name="lastName"
                                autoComplete="off"
                                maxLength={255}
                                // onChangeCapture={handleMaxNumber}
                                {...register("lastName")}
                                isInvalid={!!errors.lastName}
                                data-testid="firm-lastname"
                                id="firm-lastname"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.lastName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-email"
                                className="required mb-0"
                              >
                                {formPlaceholder.email}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="email"
                                name="email"
                                autoComplete="off"
                                {...register("email")}
                                isInvalid={!!errors.email}
                                onBlur = {(e) => e.target.value = e.target.value.trim()}
                                data-testid="firm-email"
                                id="firm-email"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.email?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-phone"
                                className="required mb-0"
                              >
                                {formPlaceholder.phone}
                              </Form.Label>
                              <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : ""
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder=""
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                  setPhoneNumber(value);
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                  setPhoneNumber(value);
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        type="hidden"
                        autoComplete="off"
                        {...register("phone", {
                          required: true,
                        })}
                        isInvalid={!!errors.phone}
                        data-testid="firm-phone"
                      />
                          <Form.Control.Feedback
                            data-testid="invalid-feedback"
                            type="invalid"
                          >
                            {errors.phone?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="select-noofemployees"
                                className="required mb-0"
                              >
                                {formPlaceholder.noOfEmployess}
                              </Form.Label>
                              <Form.Select
                                name="noOfEmployess"
                                {...register("noOfEmployess")}
                                isInvalid={!!errors.noOfEmployess}
                                className="form-input input-border-radius-unset select-placeholder"
                                data-testid="select-noofemployees"
                                id="select-noofemployees"
                                value={noofemployees}
                                onChange={(e) =>
                                  setNoofEmployees(e.target.value)
                                }
                              >
                                <option value="" hidden></option>
                                {empRangeTypeData &&
                                  empRangeTypeData.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Form.Select>
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.noOfEmployess?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-addressLine1"
                                className="required mb-0"
                              >
                                {formPlaceholder.addressLine1}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="addressLine1"
                                maxLength={255}
                                autoComplete="off"
                                {...register("addressLine1")}
                                isInvalid={!!errors.addressLine1}
                                data-testid="firm-addressLine1"
                                id="firm-addressLine1"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.addressLine1?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-addressLine2"
                                className="form-label mb-0"
                              >
                                {formPlaceholder.addressLine2}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="addressLine2"
                                maxLength={255}
                                autoComplete="off"
                                {...register("addressLine2")}
                                isInvalid={!!errors.addressLine2}
                                data-testid="firm-addressLine2"
                                id="firm-addressLine2"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.addressLine2?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-zip"
                                className="required mb-0"
                              >
                                {formPlaceholder.zip}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset input_number"
                                type="number"
                                name="zip"
                                autoComplete="off"
                                onKeyDown={handleKeyPress}
                                onChangeCapture={handleMaxNumber}
                                {...register("zip")}
                                isInvalid={!!errors.zip}
                                data-testid="firm-zip"
                                id="firm-zip"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.zip?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-city"
                                className="required mb-0"
                              >
                                {formPlaceholder.city}
                              </Form.Label>
                              {cities?.length > 0 && (
                                <>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="form-input input-border-radius-unset select-placeholder"
                                    name="cityList"
                                    isInvalid={!!errors.cityList}
                                    {...register("cityList")}
                                    id="firm-city"
                                  >
                                    <option value="" hidden></option>
                                    {cities?.length > 0 &&
                                      cities.map((item) => (
                                        <option key={item} value={item}>
                                          {item}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  <Form.Control.Feedback
                                    data-testid="invalid-feedback"
                                    type="invalid"
                                  >
                                    {errors.cityList?.message}
                                  </Form.Control.Feedback>
                                </>
                              )}
                              {cities?.length === 0 && (
                                <>
                                  <Form.Control
                                    className="form-input input-border-radius-unset"
                                    type="text"
                                    name="city"
                                    autoComplete="off"
                                    {...register("city")}
                                    isInvalid={!!errors.city}
                                    disabled
                                    data-testid="firm-city"
                                    id="firm-city"
                                  />
                                  <Form.Control.Feedback
                                    data-testid="invalid-feedback"
                                    type="invalid"
                                  >
                                    {errors.city?.message}
                                  </Form.Control.Feedback>
                                </>
                              )}
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-state"
                                className="required mb-0"
                              >
                                {formPlaceholder.state}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="state"
                                autoComplete="off"
                                {...register("state")}
                                isInvalid={!!errors.state}
                                data-testid="firm-state"
                                id="firm-state"
                                disabled
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.state?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="firm-country"
                                className="required mb-0"
                              >
                                {formPlaceholder.country}
                              </Form.Label>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="country"
                                autoComplete="off"
                                {...register("country")}
                                isInvalid={!!errors.country}
                                data-testid="firm-country"
                                id="firm-country"
                                disabled
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                              >
                                {errors.country?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <OnBoardingFirmForm />             */}
                <div className="divider-firm"></div>
                <div className="firm-super-admin-section mb-5">
                  <div className="firm-super-admin-title pt-2 pb-2 ps-2 ps-md-3 ps-lg-0">
                    Super Admin Details
                  </div>
                  <div className="d-none d-sm-none d-md-block d-lg-block px-0 px-lg-0 px-md-3">
                    <Table responsive className="custom-table-firm-superadmin">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{formPlaceholder.saFirstName}</th>
                          <th>{formPlaceholder.saLastname}</th>
                          <th>{formPlaceholder.saEmail}</th>
                          <th>{formPlaceholder.saPhone}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td width="20%">
                            <Form.Group>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="saFirstName"
                                maxLength={30}
                                autoComplete="off"
                                {...register("superadmin.saFirstName")}
                                isInvalid={!!errors?.superadmin?.saFirstName}
                                data-testid="sa-firstname"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="text-start"
                              >
                                {errors?.superadmin?.saFirstName?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td width="20%">
                            <Form.Group>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="text"
                                name="saLastname"
                                maxLength={30}
                                autoComplete="off"
                                {...register("superadmin.saLastname")}
                                isInvalid={!!errors?.superadmin?.saLastname}
                                data-testid="sa-lastname"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="text-start"
                              >
                                {errors?.superadmin?.saLastname?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td width="30%">
                            <Form.Group>
                              <Form.Control
                                className="form-input input-border-radius-unset"
                                type="email"
                                name="saEmail"
                                autoComplete="off"
                                {...register("superadmin.saEmail")}
                                onBlur = {(e) => e.target.value = e.target.value.trim()}
                                isInvalid={!!errors?.superadmin?.saEmail}
                                data-testid="sa-email"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="text-start"
                              >
                                {errors?.superadmin?.saEmail?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td width="20%" className="sa-phone-mvs-desktop">
                            <Form.Group>                              
                              <Controller
                                control={control}
                                name="superadmin.saPhone"
                                render={({ field }) => (
                                  <div
                                    className={
                                      errors?.superadmin?.saPhone
                                        ? "phone-input-invalid-container"
                                        : ""
                                    }
                                  >
                                  <PhoneInput
                                    {...field}
                                    defaultCountry="us"
                                    onlyCountries={countryCodeList}
                                    placeholder=""
                                    title=""
                                    country={"us"}
                                    inputProps={{
                                      required: true,
                                      autoFocus: false,
                                      title: "",
                                    }}
                                    countryCodeEditable={false}
                                    onChangeCapture={handleMaxNumber}
                                    onChange={(value, phone) => {
                                      if (
                                        phone.dialCode === "1" &&
                                        phone.countryCode === "us"
                                      ) {
                                        setSaCountryCode("+1");
                                        setSaPhoneNumber(value);
                                      }
                                      if (
                                        phone.dialCode === "91" &&
                                        phone.countryCode === "in"
                                      ) {
                                        setSaCountryCode("+91");
                                        setSaPhoneNumber(value);
                                      }
                                    }}
                                    data-testid="sa-phone"
                                    id="sa-phone"
                                  />
                                    </div>
                                  )}
                                />
                              <Form.Control
                                type="hidden"
                                autoComplete="off"
                                {...register(`superadmin.saPhone`, {
                                  required: true,
                                })}
                                isInvalid={!!errors?.superadmin?.saPhone}
                                data-testid="sa-phone"
                              />
                              <Form.Control.Feedback
                                data-testid="invalid-feedback"
                                type="invalid"
                                className="text-start"
                              >
                                {errors?.superadmin?.saPhone?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {
                    <div className="d-lg-none d-md-none d-sm-block d-md-block">
                      <Row className="form-row-edit">
                        <Col className="mb-3">
                          <Row>
                            <Col lg={12} md={12} className="">
                              <Row>
                                <Col lg={6} md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      htmlFor="sa-firstname-mb"
                                      className="required mb-0"
                                    >
                                      {formPlaceholder.saFirstName}
                                    </Form.Label>
                                    <Form.Control
                                      className="form-input input-border-radius-unset"
                                      type="text"
                                      name="saFirstNameMb"
                                      maxLength={30}
                                      autoComplete="off"
                                      {...register("superadmin.saFirstNameMb")}
                                      isInvalid={
                                        !!errors?.superadmin?.saFirstNameMb
                                      }
                                      data-testid="sa-firstname-mb"
                                      id="sa-firstname-mb"
                                    />
                                    <Form.Control.Feedback
                                      data-testid="invalid-feedback"
                                      type="invalid"
                                    >
                                      {
                                        errors?.superadmin?.saFirstNameMb
                                          ?.message
                                      }
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      htmlFor="sa-lastname-mb"
                                      className="required mb-0"
                                    >
                                      {formPlaceholder.saLastname}
                                    </Form.Label>
                                    <Form.Control
                                      className="form-input input-border-radius-unset"
                                      type="text"
                                      name="saLastnameMb"
                                      maxLength={30}
                                      autoComplete="off"
                                      {...register("superadmin.saLastnameMb")}
                                      isInvalid={
                                        !!errors?.superadmin?.saLastnameMb
                                      }
                                      data-testid="sa-lastname-mb"
                                      id="sa-lastname-mb"
                                    />
                                    <Form.Control.Feedback
                                      data-testid="invalid-feedback"
                                      type="invalid"
                                    >
                                      {
                                        errors?.superadmin?.saLastnameMb
                                          ?.message
                                      }
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      htmlFor="sa-email-mb"
                                      className="required mb-0"
                                    >
                                      {formPlaceholder.saEmail}
                                    </Form.Label>
                                    <Form.Control
                                      className="form-input input-border-radius-unset"
                                      type="email"
                                      name="saEmailMb"
                                      autoComplete="off"
                                      {...register("superadmin.saEmailMb")}
                                      isInvalid={
                                        !!errors?.superadmin?.saEmailMb
                                      }
                                      onBlur = {(e) => e.target.value = e.target.value.trim()}
                                      data-testid="sa-email-mb"
                                      id="sa-email-mb"
                                    />
                                    <Form.Control.Feedback
                                      data-testid="invalid-feedback"
                                      type="invalid"
                                    >
                                      {errors?.superadmin?.saEmailMb?.message}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label
                                      htmlFor="sa-phone-mb"
                                      className="required mb-0"
                                    >
                                      {formPlaceholder.saPhone}
                                    </Form.Label>
                                    
                                    <Controller
                                      control={control}
                                      name="superadmin.saPhoneMb"
                                      render={({ field }) => (
                                        <div
                                          className={
                                            errors?.superadmin?.saPhoneMb
                                              ? "phone-input-invalid-container"
                                              : ""
                                          }
                                        >
                                        <PhoneInput
                                          {...field}
                                          defaultCountry="us"
                                          onlyCountries={countryCodeList}
                                          placeholder=""
                                          title=""
                                          country={"us"}
                                          inputProps={{
                                            required: true,
                                            autoFocus: false,
                                            title: "",
                                          }}
                                          countryCodeEditable={false}
                                          onChangeCapture={handleMaxNumber}
                                          onChange={(value, phone) => {
                                            if (
                                              phone.dialCode === "1" &&
                                              phone.countryCode === "us"
                                            ) {
                                              setSaCountryCode("+1");
                                              setSaPhoneNumber(value);
                                            }
                                            if (
                                              phone.dialCode === "91" &&
                                              phone.countryCode === "in"
                                            ) {
                                              setSaCountryCode("+91");
                                              setSaPhoneNumber(value);
                                            }
                                          }}
                                          data-testid="sa-phone-mb"
                                          id="sa-phone-mb"
                                        />
                                        </div>
                                      )}
                                    />
                                    <Form.Control
                                      type="hidden"
                                      autoComplete="off"
                                      {...register(`superadmin.saPhoneMb`, {
                                        required: true,
                                      })}
                                      isInvalid={!!errors?.superadmin?.saPhoneMb}
                                      data-testid="sa-phone-mb"
                                    />
                                    <Form.Control.Feedback
                                      data-testid="invalid-feedback"
                                      type="invalid"
                                    >
                                      {errors?.superadmin?.saPhoneMb?.message}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  }
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      className="custom-button btn-standard btn-update mx-2"
                      data-testid="update-button"
                    >
                      {formPlaceholder.updateBtnText}
                    </Button>
                    <Button
                      className="custom-button btn-nobg mx-2"
                      data-testid="cancel-button"
                      onClick={handleViewPageNavigation}
                    >
                      {formPlaceholder.cancelBtnText}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Container>
      </div>
      {(isLoading || geoCodeLoading || empRangeLoading || editfirmLoading) && (
        <Loader />
      )}
    </>
  );
};

export default FirmDetailsEditPage;
