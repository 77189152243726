import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
  family: {},
};

const attorneyAddClientSlice = createSlice({
  name: "addClientDetails",
  initialState,
  reducers: {
    getClientDetails: (state) => {
      return state.client;
    },
    getFamilyDetails: (state) => {
      return state.family;
    },
    setClientDetails: (state, action) => {
      return { ...state, client: { ...state.client, ...action.payload } };
    },
    setFamilyDetails: (state, action) => {
      return { ...state, family: { ...state.family, ...action.payload } };
    },
    setEmptyDetails: (state) => {
      return { ...state, client: {}, family: {} };
    },
  },
});

export const {
  getClientDetails,
  getFamilyDetails,
  setClientDetails,
  setFamilyDetails,
  setEmptyDetails,
} = attorneyAddClientSlice.actions;

export default attorneyAddClientSlice.reducer;
