import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ViewFamilyDetails from "../../components/attorney/viewPeopleInformation/ViewFamilyDetails";
import ViewWitnessDetails from "../../components/attorney/viewPeopleInformation/ViewWitnessDetails";
import NotFound from "../../components/NotFound/NotFound";
import ViewSuccessorDetails from "../../components/attorney/viewPeopleInformation/ViewSuccessorDetails";
import ViewExecutorsDetails from "../../components/attorney/viewPeopleInformation/ViewExecutorsDetails";
import { useGetStakeHolderDetailsQuery } from "../../slices/stackHolderApiManagmentSlice";
import {
  //client_editPeoplePage,
  //editPeoplePage,
  executor_clientdocumentsDetails,
  executor_LandingPage,
  executor_viewPeoplePage
} from "../../constants/routes";
import { executorDetails, viewPeople } from "../../constants/attorney/lables";
import AttorneyTitleBar from "../../components/attorney/AttorneyTitleBar";
import { Alert, Container } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
//import { useSelector } from "react-redux";
//import { ROLE } from "../../constants/role";

export default function ExecutorViewPeopleDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [titleFullName, setTitleFullName] = useState("");
  //const { userInfo } = useSelector((state) => state.auth);
  const { data, error, isLoading } = useGetStakeHolderDetailsQuery(
    location?.state?.id?.doc?.userId,
    {
      refetchOnMountOrArgChange: true,
      skip: location?.state?.id === undefined,
    }
  );
  useEffect(() => {
    if (data) {
      let fullName = "";
      switch (location?.state?.viewType) {
        case "family member":
          fullName = data?.familyMember?.name;
          break;
        case "witness":
          fullName = data?.witness[location?.state?.index]?.name;
          break;
        case "executor":
          fullName = data?.executorList[location?.state?.index]?.name;
          break;
        case "successor":
          fullName = data?.successor?.name;
          break;
        default:
          fullName = "";
          break;
      }
      setTitleFullName(fullName);
      setItems([
        {
          id: "item-1",
          link: executor_LandingPage,
          content: executorDetails,
          active: false,
        },
        {
          id: "item-2",
          link: executor_clientdocumentsDetails,
          content: location?.state?.id?.doc?.rootDocName,
          active: false,
          state: {
            doc: location?.state?.id?.doc,
            user: location?.state?.id?.user,
          },
        },
        {
          id: "item-3",
          link: executor_viewPeoplePage,
          content: `${fullName} ${viewPeople}`,
          state: {
            page: "View Family",
            id: location?.state?.id,
            viewType: location?.state?.viewType,
          },
          active: true,
        },
      ]);
    } else {
      setItems([
        {
          id: "item-1",
          link: executor_LandingPage,
          content: executorDetails,
          active: false,
        },
        {
          id: "item-2",
          link: executor_clientdocumentsDetails,
          content: "--",
          active: true,
        },
        {
          id: "item-3",
          link: executor_viewPeoplePage,
          content: "--",
          active: false,
        },
      ]);
    }
  }, [data, location?.state?.id?.doc, location?.state?.id?.user, location?.state?.viewType, location?.state?.id, location?.state?.index, location?.state?.noaction]);

  // const handleEditPage = () => {
  //   navigate(
  //     userInfo?.role === ROLE.client ? editPeoplePage : client_editPeoplePage,
  //     {
  //       state: {
  //         page: "viewFamilyPage",
  //         id: location?.state,
  //         prevPage: "viewpage",
  //       },
  //     }
  //   );
  // };

  const handlePrevPage = () => {
    navigate(executor_clientdocumentsDetails,
      {
        state: {
          page: "viewFamilyPage",
          doc: location?.state?.id?.doc,
          user: location?.state?.id?.user,
        },
      }
    );
  };

  let renderComponet;
  switch (location?.state?.viewType) {
    case "family member":
      renderComponet = (
        <ViewFamilyDetails
          //handleEditPage={handleEditPage}
          handlePrevPage={handlePrevPage}
        />
      );
      break;
    case "witness":
      renderComponet = (
        <ViewWitnessDetails
          //handleEditPage={handleEditPage}
          handlePrevPage={handlePrevPage}
        />
      );
      break;
    case "executor":
      renderComponet = (
        <ViewExecutorsDetails
          //handleEditPage={handleEditPage}
          handlePrevPage={handlePrevPage}
        />
      );
      break;
    case "successor":
      renderComponet = (
        <ViewSuccessorDetails
          //handleEditPage={handleEditPage}
          handlePrevPage={handlePrevPage}
        />
      );
      break;
    default:
      renderComponet = <NotFound />;
      break;
  }

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div className="superadmin-container view-people-info-container" data-testid="executor-client-view-people-details-page">
        <Container fluid className="px-lg-4">
          <div
            className="employee-title-page-container px-3 px-lg-4"
            data-testid="sa-addemployee-page"
          >
            <AttorneyTitleBar
              items={items}
              hasButton={false}
              buttonText="Edit"
              width="90px"
              title={titleFullName}
              navigateTo={() => {}}
              navigateToPrev={handlePrevPage}
              hasBackButton={true}
            />
          </div>
        </Container>
      </div>
      <div className="view-people-info-container">{renderComponet}</div>
      {isLoading && <Loader />}
    </>
  );
}
