import { reportApiSlice } from "./reportApiSlice";

export const reportApiManagementSlice = reportApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFirmReportDetails: builder.query({
      query: () => ({
        url: `/firm-details`,
        method: "GET",
      }),
      providesTags: ["getFirmReport"],
    }),
    getUsageReportDetails: builder.query({
      query: () => ({
        url: `/capacity-usage`,
        method: "GET",
      }),
      providesTags: ["getUsageReport"],
    }),
    getFADocCapacityUsageReportDetails: builder.query({
      query: (firmId) => ({
        url: `/doc-capacity-usage?firmId=${firmId}`,
        method: "GET",
      }),
      providesTags: ["getFADocCapacityUsageReport"],
    }),
    getFAPlatformUtilDetails: builder.query({
      query: (firmId) => ({
        url: `/platform-utilization?firmId=${firmId}`,
        method: "GET",
      }),
      providesTags: ["getPlatformUtilReport"],
    }),
    downloadUsageReport: builder.mutation({
      query: ({ reportTitle, data, type }) => ({
        url: `/export/${type}/capacity-usage-report?reportTitle=${encodeURI(
          reportTitle
        )}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
      }),
    }),
    downloadFirmDetailsReport: builder.mutation({
      query: ({ reportTitle, data, type }) => ({
        url: `/export/${type}/firm-detail-report?reportTitle=${encodeURI(
          reportTitle
        )}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
      }),
    }),
    downloadFirmAdminDocandCapacityDetailsReport: builder.mutation({
      query: ({ reportTitle, data, type, firmId }) => ({
        url: `/export/${type}/doc-capacity-report?reportTitle=${encodeURI(
          reportTitle
        )}&firmId=${firmId}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
      }),
    }),
    downloadPlatformUtilDetailsReport: builder.mutation({
      query: ({ reportTitle, data, type, firmId }) => ({
        url: `/export/${type}/platform-utilization-report?reportTitle=${encodeURI(
          reportTitle
        )}&firmId=${firmId}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
      }),
    }),
    shareReportDocument: builder.mutation({
      query: (data) => ({
        url: `/share/${data.fileName}/reports?userId=${data.userId}&toAddress=${data.toAddress}&recipientName=${data.recipientName}`,
        method: "POST",
        body: data.fileData,
      }),
    }),
  }),
});

export const {
  useGetFirmReportDetailsQuery,
  useGetUsageReportDetailsQuery,
  useGetFADocCapacityUsageReportDetailsQuery,
  useGetFAPlatformUtilDetailsQuery,
  useShareReportDocumentMutation,
  useDownloadUsageReportMutation,
  useDownloadFirmDetailsReportMutation,
  useDownloadFirmAdminDocandCapacityDetailsReportMutation,
  useDownloadPlatformUtilDetailsReportMutation,
} = reportApiManagementSlice;
