import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

function ModalPopupType7({
  showModal,
  setShowCallback,
  content,
  headerTitle,
  setHideCallback,
}) {
  const handleClose = () => setShowCallback(false);
  return (
    <>
      <Modal
        id="modal-popup-container-type-6"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={setHideCallback ? setHideCallback : handleClose}
        data-testid="modal-popup-type7"
      >
        <Modal.Header className="modal-custom-header">
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-4 p-md-4 p-sm-4 p-2">
            <div className="modal-content-section">{content}</div>
            <div className="modal-button-section mt-4">
              <Button
                onClick={handleClose}
                className="custom-popup-button btn-yes left-right-radius me-2"
              >
                Ok
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalPopupType7.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  headerTitle: PropTypes.string,
  content: PropTypes.node.isRequired,
};

export default ModalPopupType7;
