import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

function ModalPopupType3({ showModal, setShowCallback, setYesCallback, content, headerTitle, setHideCallback }) {
  const handleClose = () => setShowCallback(false);
  const handleClickYes = () => setYesCallback(true)
  return (
    <>
      <Modal
        id="modal-popup-container-type-2"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={setHideCallback? setHideCallback:handleClose}
        data-testid="modal-popup-type3"
      >
        <Modal.Header className="modal-custom-header" closeButton>
          <Modal.Title className="modal-custom-title">
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content-container text-center p-lg-5 p-md-5 p-sm-5 p-2">
            <div className="modal-content-section">{content}</div>
            <div className="modal-button-section mt-3">
              <Button data-testid="yes-button" onClick={handleClickYes} className="custom-popup-button btn-yes left-radius me-2">
                Yes
              </Button>
              <Button
                onClick={handleClose}
                className="custom-popup-button btn-no right-radius"
                 data-testid="no-button"
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalPopupType3.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowCallback: PropTypes.func,
  content: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default ModalPopupType3;
