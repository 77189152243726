import React from "react";
import { Modal, Button} from "react-bootstrap";

export default function OtpExpiredModal({
  showModal,
  setShowCallback,
}) {

  const handleClose = () => {
    setShowCallback(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="personal-use-modal"
        data-testid="personal-use-modal-container"
      >
        <Modal.Header
          className="share-doc-modal-header receive-file-header"
          closeButton
        >
        </Modal.Header>
        <Modal.Body className="share-doc-modal-body">
        <div className="d-flex justify-content-center">
        <div className="px-3">
            <div className="text-align-center is-personal-use my-3 mb-3 pt-4">
           <b>This link has expired. Access period for this document has ended. 
           Please contact the sender for further assistance.</b>              
            </div>
        </div>
        </div>
        </Modal.Body>
        <Modal.Footer className="share-doc-modal-footer justify-content-center pt-0 pb-4 mb-2">
          <Button
            className="custom-button btn-standard doc-share-submit-btn"
            type="button"
            onClick={setShowCallback}
            data-testid="personal-use-submit-btn"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
