import { wireTransferDownloadApiSlice } from "./wireTransferDownloadApiSlice";

export const wireTransferDownloadApiManagementSlice =
  wireTransferDownloadApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      downloadWireTransferCSV: builder.query({
        query: (type) => ({
          url: `/template`,
          method: "GET",
          responseHandler: (response) => response.blob(),
        }),
      }),
    }),
  });

export const { useLazyDownloadWireTransferCSVQuery } =
  wireTransferDownloadApiManagementSlice;
