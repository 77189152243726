import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Alert, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { countryCodeList, enterdetails, sploginDurationValiation, sploginEmailReqValidation, sploginEmailValidation, sploginNameRegValidation, sploginNameValidation, sploginphoneNumberValidation } from "../../constants/labels";
import { alphabetRules, emailRules, indphonNumberRules, phoneNumberRules, usphonNumberRules } from "../../constants/regExp";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useBulkShareDocumentMutation } from "../../slices/documentApiManagementV2";
import BulkShareConfirmationPopup from "./BulkShareConfirmationPopup";
import { ROLE } from "../../constants/role";

export default function BulkFileTransferModal({
  showModal,
  setShowCallback,
  setSuccessCallback,
  selectedFolders,
}) {
  const { userInfo } = useSelector((state) => state.auth);

  const [updateDocs, { error, isLoading }] = useBulkShareDocumentMutation();
  const [selRadio, setRadio] = useState(1)
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [formValues, setFormValues] = useState(null)
  const Id = userInfo?.role === ROLE.executor ? userInfo?.clientId : userInfo?.userId

  const schema = yup.object().shape({
    name: yup
      .string().trim()
      .required(`${sploginNameRegValidation}`)
      .matches(alphabetRules, `${sploginNameValidation}`),
    email: yup
      .string().trim()
      .required(`${sploginEmailReqValidation}`)
      .email(`${sploginEmailValidation}`)
      .matches(emailRules, `${sploginEmailValidation}`),
      phone: yup
      .string()
      .test(
        "phone-validation",
        `${sploginphoneNumberValidation}`,
        function (value) {
          if (countryCode) {
            if (countryCode === "+91") {
              return indphonNumberRules.test(value);
            } else if (countryCode === "+1") {
              return usphonNumberRules.test(value);
            } else {
              return phoneNumberRules.test(value);
            }
          }
        }
      )
      .required(`${sploginphoneNumberValidation}`),
    duration: yup.string().required(`${sploginDurationValiation}`),
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const handleClose = () => {
    setShowCallback(false);
    reset();
    setPhoneNumber("")
    setCountryCode("")
    setValue("phone", "", {
      shouldValidate: false,
      shouldTouch: true,
      shouldDirty: true,
    });
    setRadio(1)
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
    setShowCallback(true)
  };

  const handleConfirmationPopup = (formData) => {
    setFormValues(formData)
    setShowCallback(false)
    setShowConfirmation(true)
  }

  useEffect(() => {
    if (phoneNumber && countryCode) {
      setValue("phone", phoneNumber, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [phoneNumber, countryCode, setValue]);

  const handleMaxNumber = (event) => {
    let { value, name } = event.target;
    if (name === "phone") {
      event.target.value = value.slice(0, 10);
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9\b]+$/;
    const isNumericKey = numericRegex.test(keyValue);
    const isNumericKeypad = keyCode >= 96 && keyCode <= 105;
    const isTabKey = keyCode === 9;

    if (!(isNumericKey || isNumericKeypad || isTabKey)) {
      event.preventDefault();
    }
  };

  const onSubmit = async () => {
    try {
      const res = await updateDocs({
        userId: Id,
        toAddress: formValues?.email,
        recipientName: formValues?.name,
        duration: parseInt(formValues?.duration),
        phoneNumber: `+${formValues.phone}`,
        docIdList: selectedFolders,
      }).unwrap();
      if (res?.isSuccessful) {
        setShowConfirmation(false)
        setSuccessCallback();
      } else {
        toast.error(res?.message || res.error || res.errorMessage);
      }
    } catch (err) {
      toast.error(
        err?.data?.message || err?.errorMessage || err?.data?.errorMessage || err.error
      );
    }
  };

  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data && error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="share-document-modal"
        data-testid = "bulkshare-document-modal"
      >
        <Form
          className="custom-form custom-form-doc-share w-100"
          onSubmit={handleSubmit(handleConfirmationPopup)}
        >
          <Modal.Header className="share-doc-modal-header" closeButton>
            <Modal.Title className="header-title">{enterdetails}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="share-doc-modal-body">
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input"
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                {...register("name")}
                isInvalid={!!errors.name}
                placeholder="Name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                className="form-input"
                type="email"
                id="email"
                name="email"
                autoComplete="off"
                {...register("email")}
                isInvalid={!!errors.email}
                onBlur = {(e) => e.target.value = e.target.value.trim()}
                placeholder="Email"
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                          <div
                            className={
                              errors.phone
                                ? "phone-input-invalid-container"
                                : "phone-input-valid-container"
                            }
                          >
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              onlyCountries={countryCodeList}
                              placeholder="Mobile Phone"
                              title=""
                              country={"us"}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                                title: "",
                              }}
                              countryCodeEditable={false}
                              onChangeCapture={handleMaxNumber}
                              onChange={(value, phone) => {
                                if (
                                  phone.dialCode === "1" &&
                                  phone.countryCode === "us"
                                ) {
                                  setCountryCode("+1");
                                  setPhoneNumber(value);
                                }
                                if (
                                  phone.dialCode === "91" &&
                                  phone.countryCode === "in"
                                ) {
                                  setCountryCode("+91");
                                  setPhoneNumber(value);
                                }
                              }}
                            />
                          </div>
                        )}
                      />
                      <Form.Control
                        className="form-input input-border-radius-unset input_number"
                        type="hidden"
                        name="phone"
                        onKeyDown={handleKeyPress}
                        onChangeCapture={handleMaxNumber}
                        autoComplete="off"
                        {...register("phone")}
                        isInvalid={!!errors.phone}
                        data-testid="emp-phone"
                        id="emp-phone"
                      />
                      <Form.Control.Feedback
                        data-testid="invalid-feedback"
                        type="invalid"
                      >
                        {errors.phone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
            <Form.Group className="mb-3 radio-button-container">
              <Form.Label htmlFor="comments" className="radio-button-label">
                Duration of Access
              </Form.Label>
              <div className="radio-buttons-wrapper">
                <Form.Check
                  inline
                  label="1 day"
                  name="group1"
                  type="radio"
                  id={`inline-radio-1`}
                  value={1}
                  checked={selRadio === 1 ? true:false}
                  onClick={() => setRadio(1)}
                  data-testid="radio-button-1day"
                  {...register("duration")}
                />
                <Form.Check
                  inline
                  label="3 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  value={3}
                  checked={selRadio === 3 ? true:false}
                  onClick={() => setRadio(3)}
                  data-testid="radio-button-3days"
                  {...register("duration")}
                />
                <Form.Check
                  inline
                  label="5 days"
                  name="group1"
                  type="radio"
                  id={`inline-radio-2`}
                  value={5}
                  checked={selRadio ===  5? true:false}
                  onClick={() => setRadio(5)}
                  data-testid="radio-button-5days"
                  {...register("duration")}
                />
              </div>
              {errors?.duration && (
                <p className="error-message">
                  {errors?.duration?.message}
                </p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="share-doc-modal-footer pt-0">
            <Button
              className="custom-button btn-standard doc-share-submit-btn"
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
        
      </Modal> 
      {showConfirmation &&
      <BulkShareConfirmationPopup
      showModal={showConfirmation}
      setShowCallback={handleCloseConfirmation}
      setSuccessCallback={onSubmit}
      formData={formValues}
    />} 
    {isLoading && <Loader />}    
    </>
  );
}
