import React, { useState } from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Card,
} from "react-bootstrap";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";
import { ChevronDown } from "react-bootstrap-icons";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down-doc-part.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

import { shareReceiveBtn } from "../../constants/client/labels";
import { executor_bulk_file_share } from "../../constants/routes";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

export default function ExecutorTitleBar({
  items,
  title,
  navigateToPrev,
  hasBackButton,
  isMobile,
}) {
  const [activeKey, setActiveKey] = useState();

  const CustomToggle = ({ children, eventKey, activeKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);
    const isActive = activeKey && activeKey.toString() === eventKey;

    return (
      <div
        onClick={decoratedOnClick}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center">{children}</div>
        <div className="arrow-icon" style={{ width: "20px" }}>
          {isActive ? (
            <ArrowDown data-testid={`arrow-down-${eventKey}`} />
          ) : (
            <ArrowRight data-testid={`arrow-right-${eventKey}`} />
          )}
        </div>
      </div>
    );
  };

  const dropdownComp = (
    <div>
      <span className="me-1">{shareReceiveBtn} </span>
      <ChevronDown
        size={16}
        className="dropbtn-bulkshare"
        color="#fff"
        data-testid="share-rec-chevronDown-icon"
      />
    </div>
  );

  return (
    <div className="d-flex justify-content-between align-items-center pb-2 flex-column flex-md-row flex-lg-row">
      <div>
        {items !== false && <BreadCrumb items={items} />}
        {hasBackButton && (
          <span className="keyboard-left-arrow pe-2" onClick={navigateToPrev}>
            <KeyboardLeftArrow />
          </span>
        )}
        <span className="page-title">{title}</span>
      </div>
      <div className="d-flex justify-content-between flex-column flex-md-row flex-lg-row client-title-bar-buttons">
        <DropdownButton
          className="ps-lg-2"
          id="dropdown-basic-button"
          title={dropdownComp}
          data-testid="dropdown-share-rec-button"
          style={{ width: isMobile ? "12.1875rem" : "8.5rem" }}
        >
          <Accordion
            activeKey={activeKey}
            onSelect={(selectedIndex) => setActiveKey(selectedIndex)}
          >
            {/* <Card key="0" className="custom-card-filetransfer">
              <Card.Header className="custom-card-header-filetransfer">
                <CustomToggle eventKey="0" activeKey={activeKey}>
                  <div className="d-flex">
                    <div className="ps-1 pe-4">Bank Transactions</div>
                  </div>
                </CustomToggle>
              </Card.Header>
            </Card> */}
            <Card key="1" className="custom-card-filetransfer">
              <Card.Header className="custom-card-header-filetransfer">
                <CustomToggle eventKey="1" activeKey={activeKey}>
                  <div className="d-flex">
                    <div className="ps-1 pe-4">File Transfer</div>
                  </div>
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`1`}>
                <Card.Body className="custom-card-body-filetransfer">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip
                        id="tooltip-share-bank-details"
                        className="custom-tooltip-bulkshare"
                      >
                        This feature allows you to transfer files/folders in
                        bulk with anyone within & outside the platform.
                      </Tooltip>
                    }
                  >
                    <Dropdown.Item href={executor_bulk_file_share}>
                      Bulk File transfer
                    </Dropdown.Item>
                  </OverlayTrigger>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </DropdownButton>
      </div>
    </div>
  );
}
