export const validation = {
  firstName: {
    required: "Enter the First Name",
    rule: "First Name must be alphabets",
  },
  lastName: {
    required: "Enter the Last Name",
    rule: "Last Name must be alphabets",
  },
  email: {
    required: "Email is Required",
    emailRules:
      "Please enter a valid email address. The format should be user@example.com",
  },
  phone: {
    phoneNumberRules: "Enter the 10 digit Mobile Phone",
  },
  dob: {
    required: "Enter Date of Birth",
  },
  addressLine1: {
    required: "Enter Address Line 1",
  },
  addressLine2: {
    required: "Enter Address Line 2",
  },
  zip: {
    zipRules: "Enter 5 Digit zip code",
  },
  state: {
    required: "Enter the State",
  },
  country: {
    required: "Enter the Country",
  },
  cityList: {
    required: "Select City",
  },
  city: {
    required: "Enter the City",
  },
  relationship: {
    required: "Select Relationship",
  },
  driversLicense:{
    required:"Enter the Driver’s License / Passport",
    rule:"Driver’s License / Passport must not have special characters"
  }
};
