import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { signOutApiCall } from "./authSlice";
import {setEmptyDetails} from "./shareReceiveBankDetailsSlice";
import { toast } from "react-toastify";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL_USERSERVICE}/api/v1/stakeholder`,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    headers.set("Access-Control-Allow-Credentials", "true");
   
    return headers;
  },
});
const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(signOutApiCall());
    api.dispatch(setEmptyDetails());
    toast.error(`Signed out successfully due to token expiration!`);
  }
  return result;
};
export const deathClaimApiSlice = createApi({
  reducerPath: "deathClaimApiSlice",
  baseQuery:baseQueryWithAuth,
  tagTypes: ["DeathClaim"],
  endpoints: (builder) => ({}),
});
