import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useUploadWireTransferCSVMutation } from "../../slices/wireTransferUploadApiManagementSlice";
import Loader from "../Loader/Loader";
import { client_LandingPage } from "../../constants/routes";
import ModalPopup from "../modal/ModalPopup";
import SuccessModalContent from "../widgets/SuccessModalContent";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "rgba(168, 184, 192, 1)",
  borderStyle: "dashed",
  backgroundColor: "rgba(232, 232, 237, 1)",
  color: "rgb(33, 37, 41)",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadBankDetailsFile(props) {
  const [uploadcsv, { isLoading }] = useUploadWireTransferCSVMutation();
  const identifier = props?.identifier;
  const senderUserId = props?.senderUserId;
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState("");

  const onDropAccepted = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    try {
      const res = await uploadcsv({
        formData,
        user: { senderUserId: senderUserId, identifier: identifier },
      }).unwrap();
      if (res?.isSuccessful) {
        // toast.success(res.message);
        setShowModalMessage(res?.message);
        setShowModal(true);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error(
        err?.data?.message || err?.errorMessage || err?.data?.errorMessage
      );
    }
  };

  const onDropRejected = (params) => {
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      toast.error(`This format is not valid! Please upload CSV format only`);
    }
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".csv",
      ],
    },
    maxFiles: 1,
    // maxSize: 10 * 1024 * 1024,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="drag-container pt-4" style={{ fontWeight: 400 }}>
        <div {...getRootProps({ style })} data-testid="upload-file">
          <input {...getInputProps()} />
          <div className="w-100 text-center p-4">
            <div className="mt-3">Drag and Drop Files Here</div>
            <div className="d-flex align-items-center mt-4">
              <div
                style={{
                  flex: "1",
                  borderBottom: "2px dashed rgb(33, 37, 41)",
                }}
              />
              <div style={{ margin: "0 10px", fontWeight: "400" }}>Or</div>
              <div
                style={{
                  flex: "1",
                  borderBottom: "2px dashed rgb(33, 37, 41)",
                }}
              />
            </div>
            <Button
              className="custom-button btn-standard mt-4"
              style={{ fontSize: "18px" }}
              onClick={open}
            >
              Browse Files
            </Button>
            <div className="pt-3">Allowed File Formats: .CSV</div>
          </div>
        </div>
      </div>
      <div className="external-user-success-message">
        <ModalPopup
          show={showModal}
          setShow={() => setShowModal(false)}
          hasCloseButton={true}
          hasBottomCloseButton={false}
          modalSize="md"
          modalClass="modal-container success-alert"
          titleClass="header-title"
          headerClass="no-border"
          modalContentElement={<SuccessModalContent text={showModalMessage} />}
          hasFooter={false}
          footerClass="no-border pt-0"
          footerButtonText="Add"
          footerButtonClass="custom-button btn-standard btn-add-modal"
          onClickCloseNavigation={client_LandingPage}
          animation={false}
        />
      </div>
      {isLoading && <Loader />}
    </>
  );
}
