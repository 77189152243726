import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { signOutApiCall } from "./authSlice";
import { toast } from "react-toastify";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents`,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Access-Control-Allow-Origin", "*");
    const token = getState()?.auth?.userInfo?.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(signOutApiCall());
    toast.error(`Signed out successfully due to token expiration!`);
  }
  return result;
};

export const documentApiSlice = createApi({
  reducerPath: "documentApiSlice",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Document"],
  endpoints: (builder) => ({}),
});
