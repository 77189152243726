export const firmOptions = [
  {
    label: "Attorney",
    options: [
      { value: "Attorney", text: "Attorney" },
      { value: "Insurance", text: "Insurance" },
      { value: "Finance", text: "Finance" },
    ],
  },
  {
    label: "Non Attorney",
    options: [{ value: "Non Attorney", text: "Non Attorney" }],
  },
];

export const noOfEmployess = [
  {
    id: 3,
    name: "1-500",
    code: "LT500",
    description: "Number of employees",
    type: "EmployeeRange",
    parentId: null,
  },
  {
    id: 4,
    name: "501-1000",
    code: "LT1000",
    description: "Number of employees",
    type: "EmployeeRange",
    parentId: null,
  },
  {
    id: 5,
    name: "1001-5000",
    code: "LT5000",
    description: "Number of employees",
    type: "EmployeeRange",
    parentId: null,
  },
  {
    id: 6,
    name: "5001-10000",
    code: "LT10000",
    description: "Number of employees",
    type: "EmployeeRange",
    parentId: null,
  },
  {
    id: 7,
    name: "10001 & Above",
    code: "GT10000",
    description: "Number of employees",
    type: "EmployeeRange",
    parentId: null,
  },
];

export const haveSso = [
  {
    id: 1,
    name: "Yes",
    code: "Y",
    description: "SSO enabled",
    type: "SSO",
    parentId: null,
  },
  {
    id: 2,
    name: "No",
    code: "N",
    description: "SSO disabled",
    type: "SSO",
    parentId: null,
  },
];

export const plans = [
  {
    plan_id: 1,
    name: "FamVault1",
    description: "Standard Plan",
    price: 5.0,
    currency: "USD",
  },
];

export const knownSource = [
  {
    id: 8,
    name: "Search Engine (Google, Bing)",
    code: "SE",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 9,
    name: "Social Media",
    code: "SM",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 10,
    name: "Recommended by a Friend",
    code: "RBF",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 11,
    name: "Advertisement",
    code: "ADV",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 12,
    name: "Event or Conference",
    code: "EOC",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 13,
    name: "Word of Mouth",
    code: "WOM",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 14,
    name: "Website",
    code: "WEB",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
  {
    id: 15,
    name: "Other",
    code: "OTH",
    description: "How did you know about us?",
    type: "HowKnowUs",
    parentId: null,
  },
];
