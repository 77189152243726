import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    stackHolders:{
        buttonEvent:false,        
    },
    executors:{},
    executorlen:1,
    witnessors:{},
    witnessorlen:1
}
const stackHolderSlice = createSlice({
    name:"stackholder",
    initialState,
    reducers:{
        setStackHolderDetails: (state, action) => {
            state={...state, stackHolders:{...state.stackHolders,...action.payload}}
            return state;
        },
        setStackHolderEmpty:(state)=>{
            state={...state, stackHolders:{buttonEvent:false}, executors:{}, witnessors:{}}
            return state;
        },
        getStackHolderDetails:(state) => {
            return state.stackHolders
        },
        setButtonEvent: (state, action) => {
            state={...state, stackHolders:{...state.stackHolders, buttonEvent:action.payload}}
            return state;
        },
        setExecutors: (state, action)=>{
            state={...state, executors:{...state.executors,...action.payload}}
            return state
        },
        setExecutorlen:(state, action)=>{

            return {...state, executorlen:action.payload}
        },
        setWitnessors: (state, action)=>{
            state={...state, witnessors:{...state.witnessors,...action.payload}}
            return state
        },
        setWitnessorlen:(state, action)=>{
            return {...state, witnessorlen:action.payload}
        },
        setExecutorRemove:(state, action)=>{
            return {...state, executorlen:action.payload.len,executors:action.payload.executor}
        },
        setWitnessorRemove:(state, action)=>{
            return {...state, witnessorlen:action.payload.len,witnessors:action.payload.witness}
        },         
    }
})

export const {
    setStackHolderDetails, 
    getStackHolderDetails, 
    setButtonEvent, 
    setStackHolderEmpty, 
    setExecutors, 
    setStepCompleted,
    setExecutorlen,
    setWitnessors,
    setWitnessorlen,
    setExecutorRemove,
    setWitnessorRemove
} = stackHolderSlice.actions;

export default stackHolderSlice.reducer;