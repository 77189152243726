import {ReactComponent as ErrorInfo} from "../../assets/images/ErrorInfoClientSearch.svg"
import { client_not_found } from "../../constants/deathclaim/labels";

export default function ClientSearchFailureModal() {
  return (
    <div className="d-flex justify-content-center pt-4">
      <div>
        <div className="d-flex justify-content-center pb-4 pt-0">
        <ErrorInfo />
        </div>
        <div className="client-not-found-text px-4 pb-5">{client_not_found}</div>
      </div>
    </div>
  );
}