import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  identifierID: null,
  requestType: null,
};

const shareReceiveBankDetailsSlice = createSlice({
  name: "shareReceiveBankDetails",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.identifierID = action.payload.identifierID;
      state.requestType = action.payload.requestType;
    },
    setEmptyDetails: (state) => {
      window.history.replaceState(null, null, "/login");
      sessionStorage.removeItem("ssoResponse");
      sessionStorage.removeItem("ssoIdentifier");
      state.identifierID = null;
      state.requestType = null;
    },
  },
});

export const { setEmptyDetails, getDetails, setDetails } =
  shareReceiveBankDetailsSlice.actions;

export default shareReceiveBankDetailsSlice.reducer;
