import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import AccessDenied from "../AccessDenied/AccessDenied";
import { useEffect } from "react";
import { defaultRoute, famVault_wesbite, otpverification } from "../../constants/routes";
import Loader from "../Loader/Loader";

const PrivateRoute = ({ children, roles }) => {
  let location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search)

  const identifier = queryParameters.get("identifier");
  const type = queryParameters.get("type");

  const { userInfo } = useSelector((state) => state.auth);
  const userHasRequiredRole =
    userInfo && roles.includes(userInfo.role) ? true : false;
  useEffect(() => {
    const handleTabClose = (event) => {
      if (userInfo?.accessToken && userInfo?.accessToken !== null) {
        const url = `${process.env.REACT_APP_BASE_URL_USERSERVICE}/api/v1/login/signOut?accesstoken=${userInfo?.accessToken}`;
        navigator.sendBeacon(url);
      }
    };
    window.addEventListener("unload", handleTabClose);

    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, [userInfo?.accessToken]);

  if (!userInfo && !identifier && !type ) {
    window.location.href = `${famVault_wesbite}`;
    return <Loader />;
  }

  if (!userInfo && identifier && type ) {

    if(type==="SHARE"){
      return <Navigate to={otpverification} state={{from: location}} />;
    }
    else{
      return <Navigate to={defaultRoute} state={{ from: location }} />;
    }
  }
  
  if (userInfo && !userHasRequiredRole) {
    return <AccessDenied />; // build your won access denied page (sth like 404)
  }

  return children;
};

export default PrivateRoute;
