import { wireTransferUploadApiSlice } from "./wireTransferUploadApiSlice";

export const wireTransferUploadApiManagementSlice =
  wireTransferUploadApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      uploadWireTransferCSV: builder.mutation({
        query: ({ formData, user }) => ({
          url: `?senderUserId=${user.senderUserId}&identifier=${user.identifier}`,
          method: "POST",
          body: formData,
          prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
        }),
      }),
      downloadShareBankDetails: builder.query({
        query: ({ identifier, requestType }) => ({
          url: `/bank-details?identifier=${identifier}&requestType=${requestType}`,
          method: "GET",
          responseHandler: (response) => response.blob(),
        }),
      }),
    }),
  });

export const {
  useUploadWireTransferCSVMutation,
  useLazyDownloadShareBankDetailsQuery,
} = wireTransferUploadApiManagementSlice;
