import { firmApiSlice } from "./firmApiSlice";

export const firmApiManagement = firmApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createfirm: builder.mutation({
      query: (data) => ({
        url: `/firmSignUp`,
        method: "POST",
        body: data,
      }),
    }),
    getallfirms: builder.query({
      query: () => ({
        url: `/{firmId}/attorneys`,
        method: "GET",
      }),
    }),
    getfirmbyid: builder.query({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
      providesTags : ['getfirmbyid']
    }),
    getfirmbyname: builder.query({
      query: (firmName) => ({
        url: `/name?name=${firmName}`,
        method: "GET",
      }),
    }),
    editfirm: builder.mutation({
      query: (data) => ({
        url:"/edit-firm",
        method:"PUT",
        body:data
      }),
      invalidatesTags :['getfirmbyid']
    }),
    getAllAttorneyDetails: builder.query({
       query: (firmId) => ({
          url: `/${firmId}/attorneys`,
          method: "GET",
       }),
       providesTags : ['getAllAttorneyDetails']
    }),
  }),
});

export const {
  useCreatefirmMutation,
  useEditfirmMutation,
  useGetallfirmsQuery,
  useGetfirmbyidQuery,
  useGetAllAttorneyDetailsQuery,
} = firmApiManagement;
