import React, {useEffect, useState} from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  name,
  //invalidEmailReport,
  validation,
} from "../../constants/labels";
import {ROLE} from "../../constants/role";
import { folderNameRules } from "../../constants/regExp";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
//import { useShareReportDocumentMutation } from "../../slices/reportApiManagementSlice";
import { useCreateFolderMutation, useUpdateFolderMutation } from "../../slices/folderApiManagementSlice";
//import { useUpdateFolderMutation } from "../../slices/folderApiManagementSlice";
export default function NewFolderModal({ showModal, setShowCallback, folderData, clientId}) {
  const [selRadio, setRadio] = useState("color-template-1");
  const [checkedRadio, setCheckRadio]= useState(false);
  const [folderName, setFolderName] = useState("");
  const schema = yup.object().shape({
    foldername: yup
      .string()
      .trim()
      .required(`${validation.foldername.required}`)
      .matches(folderNameRules, `${validation.foldername.rule}`),
    foldercolor: yup.string().required(`${validation.foldercolor.required}`),  
  });

  const { userInfo } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({ resolver: yupResolver(schema), mode: "all" });

  const handleClose = () => {
    setShowCallback(false);
    reset();
  };

  //const [shareDocument, { isLoading }] = useShareReportDocumentMutation();
  const [createFolder, {isLoading}] = useCreateFolderMutation();
  const [updateFolder, {isLoading: updateLoading}] = useUpdateFolderMutation();
  const onSubmit = async (formData) => {
  let data = {
      userId:userInfo?.userId,
      clientId: userInfo?.role === ROLE.attorney ? clientId: userInfo?.userId,
      color: formData?.foldercolor,
      name:encodeURIComponent(formData?.foldername)
    }
    try {
      if(folderData === null){
        const res = await createFolder(data).unwrap();
        if (res?.isSuccessful && (res?.key && res?.key === "INFO")) {
          toast.success(res?.message)
          setValue("foldername",null);
          setRadio("color-template-1")
          setShowCallback({name:folderName, act:"add"});
        } else {
          toast.error(res?.message);
        }
      }else{
        let color= checkedRadio ? formData?.foldercolor: folderData?.folderColor;
        const res = await updateFolder({...data, color:color,entityId:folderData?.rootDocId}).unwrap();
        if (res?.isSuccessful && (res?.key && res?.key === "INFO")) {
          toast.success(res?.message);
          setShowCallback({name:formData?.foldername, act:"edit"});
        } else {
          toast.error(res?.message);
        } 
      }
      
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };
  useEffect(() => {
    
    if(folderData !== null){
      setValue("foldername", folderData?.rootDocName);
      setRadio(folderData?.folderColor);
    }
    return () => {
      setValue("foldername", null);
      setRadio("color-template-1");
      setCheckRadio(false);
    }
  },[folderData, setValue]);
  const handleRadio = (val) => {
    setCheckRadio(true);
    setRadio(val);
  }
  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        className="new-folder-modal"
        data-testid="create-new-folder-modal"
      >
        <Form
          className="custom-form custom-form-doc-share w-100"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header className="share-doc-modal-report-header" closeButton>
            <Modal.Title className="modal-custom-title-report">
              {folderData !== null ? "Edit Folder":"Create Folder"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="new-folder-modal-body">
            <Form.Group className="mb-3">
              <Form.Label className="required" htmlFor={`name`}>
                {name}
              </Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                id="foldername"
                name="foldername"
                autoComplete="off"
                {...register("foldername")}
                isInvalid={!!errors.foldername}
                placeholder="New Folder"
                data-testid="new-folder-name"
                onKeyDown={(e) => setFolderName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.foldername?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 radio-button-container">
              <Form.Label
                htmlFor="inline-radio-1"
                className="radio-button-label"
              >
                Choose your folder color
              </Form.Label>
              <div className="new-folder-radio-buttons-wrapper">
                <Form.Check
                  inline
                  label=""
                  name="group1"
                  type="radio"
                  id={`inline-radio-1`}
                  value={"color-template-1"}
                  checked={selRadio === "color-template-1" ? true:false}
                  onClick={() => handleRadio("color-template-1")}
                  data-testid="radio-button-color-1"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group2"
                  type="radio"
                  id={`inline-radio-2`}
                  value={"color-template-7"}
                  checked={selRadio === "color-template-7" ? true:false}
                  onClick={() => handleRadio("color-template-7")}
                  data-testid="radio-button-color-2"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group3"
                  type="radio"
                  id={`inline-radio-3`}
                  value={"color-template-3"}
                  checked={selRadio === "color-template-3" ? true:false}
                  onClick={() => handleRadio("color-template-3")}
                  data-testid="radio-button-color-3"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group4"
                  type="radio"
                  id={`inline-radio-4`}
                  value={"color-template-4"}
                  checked={selRadio === "color-template-4" ? true:false}
                  onClick={() => handleRadio("color-template-4")}
                  data-testid="radio-button-color-4"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group5"
                  type="radio"
                  id={`inline-radio-5`}
                  value={"color-template-5"}
                  checked={selRadio === "color-template-5" ? true:false}
                  onClick={() => handleRadio("color-template-5")}
                  data-testid="radio-button-color-5"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group6"
                  type="radio"
                  id={`inline-radio-6`}
                  value={"color-template-2"}
                  checked={selRadio === "color-template-2" ? true:false}
                  onClick={() => handleRadio("color-template-2")}
                  data-testid="radio-button-color-6"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group7"
                  type="radio"
                  id={`inline-radio-7`}
                  value={"color-template-9"}
                  checked={selRadio === "color-template-9" ? true:false}
                  onClick={() => handleRadio("color-template-9")}
                  data-testid="radio-button-color-7"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group8"
                  type="radio"
                  id={`inline-radio-8`}
                  value={"color-template-6"}
                  checked={selRadio === "color-template-6" ? true:false}
                  onClick={() => handleRadio("color-template-6")}
                  data-testid="radio-button-color-8"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group9"
                  type="radio"
                  id={`inline-radio-9`}
                  value={"color-template-8"}
                  checked={selRadio === "color-template-8" ? true:false}
                  onClick={() => handleRadio("color-template-8")}
                  data-testid="radio-button-color-9"
                  {...register("foldercolor")}
                />
                <Form.Check
                  inline
                  label=""
                  name="group10"
                  type="radio"
                  id={`inline-radio-10`}
                  value={"color-template-10"}
                  checked={selRadio === "color-template-10" ? true:false}
                  onClick={() => handleRadio("color-template-10")}
                  data-testid="radio-button-color-10"
                  {...register("foldercolor")}
                />
              </div>
              {errors?.foldercolor && (
                <p className="error-message pt-3">
                  {errors?.foldercolor?.message}
                </p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="new-folder-modal-footer">
            <Button
              className="custom-button btn-standard new-folder-submit-btn"
              type="submit"
              data-testid="create-update-button"
            >
              {folderData ===  null? "Create":"Update"}
            </Button>
            <Button className="custom-button new-folder-btn-nobg" onClick={handleClose} data-testid="new-folder-cancel-button">
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {(isLoading || updateLoading) && <Loader />}
    </>
  );
}
