import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "../../styles/modalPopupType2.scss"
function ModalPopupType5({
  show,
  modalClass,
  modalContentElement,
  hasFooter,
  onClickCloseNavigation,
}) {

  const handleClose = () => {
    onClickCloseNavigation()
  };
  return (
    <>
      <Modal
        centered
        contentClassName={modalClass}
        show={show}
        onHide={handleClose}
        data-testid="modal-type-5"
        className="modal-popup-type-5"
      >
          <Modal.Header closeButton className={hasFooter ? "modal-header border-0": "border-0"} data-testid="modal-header-close-button">
          </Modal.Header>
        <Modal.Body className={hasFooter ? "modal-text mb-1" : "modal-text"}>{modalContentElement}</Modal.Body>
        {hasFooter && (
            <div className="modal-footer-willupload border-0 align-self-center mt-2 pb-4">
            <div className="d-flex justify-content-center" >
            Email: info@famvault.com
            </div>
            </div>
        )}
      </Modal>
    </>
  );
}

export default ModalPopupType5;

ModalPopupType5.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  modalClass: PropTypes.string,
  modalContentElement: PropTypes.object.isRequired,
  onClickCloseNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};
