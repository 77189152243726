import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/medical-professional`,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set(
      "Access-Control-Allow-Methods",
      "GET, POST, OPTIONS, PUT, PATCH, DELETE"
    );
    headers.set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    headers.set("Access-Control-Allow-Credentials", "true");
    return headers;
  },
});

const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export const medicalProfessionalApiSlice = createApi({
  reducerPath: "medicalProfessionalApiSlice",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["User", "MedicalProfessional"],
  endpoints: (builder) => ({}),
});
