export const clientsearchPlaceholder = {
    firstName: "First Name",
    lastName: "Last Name",
    dob: "DOB",
    phone: "Mobile Phone",
  }

export const familysearchPlaceholder = {
  firstName: "First Name",
  lastName: "Last Name",
  dob: "DOB",
  phone: "Mobile Phone",
  email: "Email"
}

export const medicalPractioner = {
  name: "Physician / Medical Center's name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Mobile Phone",
  npi: "NPI Number",
  dob: "DOB"
}

export const medTable = {
  file: "File Name",
  uploadedby: "Uploaded By",
  last_modified: "Last Modified",
  size: "Size"
}

export const SearchSuccessText =  "Thank you! we have found the details of the {} ."
export const SearchFailureText = "Sorry! we couldn't find the details."

export const condolenceText = "We are sorry to hear the news. Appreciate the information provided. We will get back to you with the next steps."
export const relogin_message = "The death certificate has already been uploaded successfully!"
export const upload_question = "Is Mr/Mrs.{} alive?"

export const client_not_found = "Sorry but the member does not exist in our database, please check the details again!"
export const medical_professional_popup = "This feature enables users to search for a living will stored in FamVault, with the help of a medical professional"
export const deathclaim_invalid_path = "If you’re here to report a member death, please check your mail and initiate the process through the link provided."
export const noclaim_invalid_path ="If you’re here to confirm that client is alive, please check your mail and initiate the process through the link provided."
