import React from "react";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import { Button } from "react-bootstrap";
import { ReactComponent as KeyboardLeftArrow } from "../../assets/images/keyboard_arrow_left.svg";

export default function AttorneyTitleBar({
  items,
  title,
  hasButton,
  navigateTo,
  buttonText,
  width,
  navigateToPrev,
  hasBackButton,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center pt-2 pb-1">
      <div>
        <BreadCrumb items={items} />
        <div>
          {hasBackButton && (
            <span
              className="keyboard-left-arrow pe-2"
              onClick={navigateToPrev}
              data-testid="prev-page-arrow"
            >
              <KeyboardLeftArrow />
            </span>
          )}
          <span className="page-title">{title}</span>
        </div>
      </div>
      {hasButton && (
        <Button
          style={{ width: `${width}` }}
          className={`custom-button btn-standard btn-new-firm`}
          type="button"
          onClick={navigateTo}
          data-testid="new-firm-onboard-button"
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}
