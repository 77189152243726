import React, { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import { useNavigate } from "react-router";
import throttle from "lodash/throttle";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { ReactComponent as LeftArrow } from "../../assets/images/pdf_arrow_left_page.svg";
import { ReactComponent as RightArrow } from "../../assets/images/pdf_arrow_right_page.svg";
import { ReactComponent as ZoomIn } from "../../assets/images/zoom-in-pdf.svg";
import { ReactComponent as ZoomOut } from "../../assets/images/zoom-out-pdf.svg";
import { ReactComponent as Print } from "../../assets/images/print.svg";
import { ReactComponent as Share } from "../../assets/images/sharedocument.svg";
import { ReactComponent as Download } from "../../assets/images/download-icon.svg";
import ShareDocumentModalPopup from "../modal/ShareDocumentModalPopup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";
import ModalPopup from "../modal/ModalPopup";
import SuccessModalContent from "../widgets/SuccessModalContent";
import { sharedDocSuccessText } from "../../constants/labels";
import { attorney_LandingPage, client_LandingPage, executor_LandingPage } from "../../constants/routes";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const options = {
  cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// const options = {
//   cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
// };

export default function ReactPdf({ selectedDocs, fileName, mode, docId }) {
  // console.log(selectedDocs);
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberInput, setPageNumberInput] = useState(pageNumber);
  const [error, setError] = useState(null);
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [zoomInput, setZoomInput] = useState(100);

  const pdfContainerRef = useRef(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal]= useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setError(null);
  };
  const onDocumentLoadError = (error) => {
    // console.error("Error while loading PDF:", error);
    setError("Error loading PDF. Please try again later.");
  };
  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      setPageNumberInput(pageNumber + 1);
    }
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setPageNumberInput(pageNumber - 1);
    }
  };

  const handlePageInputChange = (e) => {
    e.preventDefault();
    let assignValue;
    if (e.target.value > numPages) {
      assignValue = numPages;
    } else if (e.target.value === "0") {
      assignValue = 1;
    } else {
      assignValue = e.target.value;
    }
    setPageNumberInput(assignValue);
    const value = parseInt(assignValue, 10);
    if (!isNaN(value) && value > 0 && value <= numPages) {
      setPageNumber(value);
    }
  };

  const setPdfSize = () => {
    if (pdfContainerRef && pdfContainerRef.current) {
      setInitialWidth(pdfContainerRef.current.getBoundingClientRect().width);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - mousePosition.x;
    const dy = e.clientY - mousePosition.y;
    const container = pdfContainerRef.current;
    if (zoomPercentage > 100) {
      const domElement = document.getElementsByClassName("react-pdf__Document");
      domElement[0].classList.add("remove-justify");
    }
    container.scrollTop -= dy;
    container.scrollLeft -= dx;
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomChange = (e) => {
    let assignValue;
    const domElement = document.getElementsByClassName("react-pdf__Document");
    domElement && domElement[0].classList.remove("remove-justify");
    if (e.target.value > 200) {
      assignValue = 200;
    } else if (e.target.value === "") {
      assignValue = 10;
    } else {
      assignValue = e.target.value;
    }
    setZoomInput(assignValue);
    const parsedZoom = parseInt(assignValue);
    if (!isNaN(parsedZoom) && parsedZoom >= 10 && parsedZoom <= 200) {
      setZoomPercentage(parsedZoom);
    }
  };

  const zoomIn = () => {
    if (zoomPercentage < 200) {
      setZoomPercentage(zoomPercentage + 10);
      setZoomInput(zoomPercentage + 10);
    }
    const domElement = document.getElementsByClassName("react-pdf__Document");
    domElement && domElement[0]?.classList?.remove("remove-justify");
  };

  const zoomOut = () => {
    if (zoomPercentage > 10) {
      setZoomPercentage(zoomPercentage - 10);
      setZoomInput(zoomPercentage - 10);
    }
    const domElement = document.getElementsByClassName("react-pdf__Document");
    domElement && domElement[0]?.classList?.remove("remove-justify");
  };

  const fileToBlob = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(new Blob([reader.result], { type: file.type }));
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  };

  const handlePrint = async () => {
    const blob = await fileToBlob(selectedDocs);
    if (blob) {
      const url = URL.createObjectURL(blob);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);

      iframe.onload = function () {
        iframe.contentWindow.print();
      };
    }
    if (userInfo?.role === ROLE.attorney) {
      ReactGA.event({
        category: `${ROLE.attorney} - 3 Level`,
        action: `Print Will Document - ${ROLE.attorney} - 3 Level`,
        label: `Print Will Document - ${ROLE.attorney} - 3 Level`,
      });
    }
    if (userInfo?.role === ROLE.client) {
      const firmType = userInfo?.firmTypeId;
      firmType === 1 &&
        ReactGA.event({
          category: `${ROLE.client} - 3 Level`,
          action: `Print Will Document - ${ROLE.client} - 3 Level`,
          label: `Print Will Document - ${ROLE.client} - 3 Level`,
        });
      firmType === 2 &&
        ReactGA.event({
          category: `${ROLE.client} - 2 Level`,
          action: `Print Will Document - ${ROLE.client} - 2 Level`,
          label: `Print Will Document - ${ROLE.client} - 2 Level`,
        });
    }
  };

  const handleShare = () => {
    setShowModal(true);
  };

  const handleDownload = async () => {
    try {
      const blob = await fileToBlob(selectedDocs);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}`;
      a.setAttribute("id", "download-view-doc");
      document.body.appendChild(a);
      a.click();
      toast.success(`${fileName} has been downloaded successfully!`);
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      if (userInfo?.role === ROLE.attorney) {
        ReactGA.event({
          category: `${ROLE.attorney} - 3 Level`,
          action: `Download Will Document - ${ROLE.attorney} - 3 Level`,
          label: `Download Will Document - ${ROLE.attorney} - 3 Level`,
        });
      }
      if (userInfo?.role === ROLE.client) {
        const firmType = userInfo?.firmTypeId;
        firmType === 1 &&
          ReactGA.event({
            category: `${ROLE.client} - 3 Level`,
            action: `Download Will Document - ${ROLE.client} - 3 Level`,
            label: `Download Will Document - ${ROLE.client} - 3 Level`,
          });

        firmType === 2 &&
          ReactGA.event({
            category: `${ROLE.client} - 2 Level`,
            action: `Download Will Document - ${ROLE.client} - 2 Level`,
            label: `Download Will Document - ${ROLE.client} - 2 Level`,
          });
      }
    } catch (err) {
      console.log(err);
      toast.error(
        `${fileName} download has been failed. Please try after sometimes`
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener("resize", throttle(setPdfSize, 3000));
    };
  }, []);
  const handleNavigation = () => {
    setSuccessModal(false);
    if (userInfo?.role === ROLE.attorney) {
      navigate(attorney_LandingPage);
    }
    if (userInfo?.role === ROLE.client) {
      navigate(client_LandingPage);
    }
    if (userInfo?.role === ROLE.executor) {
      navigate(executor_LandingPage);
    }
  };
  return (
    <>
      <div className="Example">
        <div className="Example__container__document">
          {error && <div>Error: {error}</div>}
          {numPages && !error && (
            <div className="page-container-pdf d-flex justify-content-between" data-testid="pdf_container">
              <div className="doc-filename mb-1" data-testid="fileName">{fileName}</div>
              <div className="d-flex align-items-center">
                <div className="scale-pdf d-flex align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Zoom In</Tooltip>}
                  >
                    <div className="me-3 zoom-icons" data-testid="zoom-in" onClick={zoomIn}>
                      <ZoomIn />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Zoom Out</Tooltip>}
                  >
                    <div className="me-3 zoom-icons" data-testid="zoom-out" onClick={zoomOut}>
                      <ZoomOut />
                    </div>
                  </OverlayTrigger>
                  <div className="me-3 zoom-percentage d-flex align-items-center">
                    <Form.Control
                      type="number"
                      className="custom-form-percentage"
                      min={10}
                      max={200}
                      value={zoomInput}
                      onChange={handleZoomChange}
                      style={{ marginRight: "5px" }}
                      data-testid="zoom-input"
                    />
                    <div>%</div>
                  </div>
                </div>
                <div className="page-custom">
                  <div
                    className={`left-arrow ${pageNumber <= 1 && "disabled"}`}
                    onClick={prevPage}
                    data-testid="prev-page"
                  >
                    <LeftArrow />
                  </div>

                  <div className="ps-1 pe-1 page-number">
                    <Form.Control
                      type="number"
                      min={1}
                      max={numPages}
                      value={pageNumberInput}
                      onChange={handlePageInputChange}
                      className="custom-form-page-number"
                      data-testid = "num-pages"
                    />
                    / {numPages}
                  </div>
                  <div
                    className={`right-arrow ${
                      pageNumber >= numPages && "disabled"
                    }`}
                    onClick={nextPage}
                    data-testid = "next-page"
                  >
                    <RightArrow />
                  </div>
                </div>
              </div>
              {mode === "download" && (
                <div className="d-flex align-items-center icon-web-section">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Download</Tooltip>}
                  >
                    <div className="me-3">
                      <Download onClick={handleDownload} data-testid = "download"/>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Share</Tooltip>}
                  >
                    <div className="me-3">
                      <Share onClick={handleShare} data-testid = "share"/>
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip className="tooltip">Print</Tooltip>}
                  >
                    <div>
                      <Print onClick={handlePrint} data-testid="print" />
                    </div>
                  </OverlayTrigger>
                </div>
              )}
            </div>
          )}
          {selectedDocs && !error && (
            <div
              className="pdf-page-container"
              ref={pdfContainerRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <Document
                file={selectedDocs}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
                options={options}
              >
                <Page
                  renderAnnotationLayer={true}
                  pageNumber={pageNumber}
                  className="page-pdf"
                  width={initialWidth * (zoomPercentage / 100)}
                />
              </Document>
            </div>
          )}
        </div>
      </div>
      <ShareDocumentModalPopup
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        docId={docId}
        selectedDocs={selectedDocs}
        setSuccessCallback={
          () => {
            setShowModal(false);
            setSuccessModal(true);
          }
        }
      />
      <ModalPopup
        show={showSuccessModal}
        setShow={() => setSuccessModal(false)}
        hasCloseButton={true}
        hasBottomCloseButton={false}
        modalSize="md"
        modalClass="modal-container success-alert"
        titleClass="header-title"
        headerClass="no-border"
        modalContentElement={
          <SuccessModalContent text={sharedDocSuccessText} />
        }
        hasFooter={false}
        footerClass="no-border pt-0"
        footerButtonText="Add"
        footerButtonClass="custom-button btn-standard btn-add-modal"
        onClickCloseNavigation={handleNavigation}
        animation={false}
      />
    </>
  );
}
