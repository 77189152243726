export const wills = {
  "application/pdf": [".pdf"],
  // "text/doc": [".doc", ".docx"],
};

export const videosigning = {
  "video/mp4": [".mp4"],
};

export const photos = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff", ".tif"],
  "image/x-raw": [".raw"],
};

export const videos = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "video/avi": [".avi"],
  "video/x-ms-wmv": [".wmv"],
  "video/x-matroska": [".mkv"],
  "video/x-flv": [".flv"],
  "video/webm": [".webm"],
  "video/mpeg": [".mpeg"],
};

export const defaultDocType = {
  "application/pdf": [".pdf"],
  "text/doc": [".doc", ".docx"],
};

export const allMimeTypes = {
  "application/pdf": [".pdf"],
  "text/doc": [".doc", ".docx"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff", ".tif"],
  "image/x-raw": [".raw"],
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "video/avi": [".avi"],
  "video/x-ms-wmv": [".wmv"],
  "video/x-matroska": [".mkv"],
  "video/x-flv": [".flv"],
  "video/webm": [".webm"],
  "video/mpeg": [".mpeg"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "text/plain": [".txt"],
  "text/html": [".html", ".htm"],
};
export const newFolderDocumentTypes = {
  "application/pdf": [".pdf"],
  "text/doc": [".doc", ".docx"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff", ".tif"],
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
  "video/avi": [".avi"],
  "video/x-ms-wmv": [".wmv"],
  "video/x-matroska": [".mkv"],
  "video/x-flv": [".flv"],
  "video/webm": [".webm"],
  "video/mpeg": [".mpeg"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "text/plain": [".txt"],
 };
export const deathClaimDocType = {
  "application/pdf": [".pdf"],
};
