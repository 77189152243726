import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FirmPageTitleBar from "../../components/firm/FirmPageTitleBar";
import { newOnboardButtonText, firmDetailsLabel } from "../../constants/labels";
import { firmLandingPlaceholder } from "../../constants/firm/labels";
import { mvssuperadmin_firmOnboardingForm, mvssuperadmin_firmViewDetails } from "../../constants/routes";
import AgGrid from "../../components/table/AgGrid";
import TabButton from "../../components/tabs/TabButton";
import { useGetAllFirmDetailsQuery } from "../../slices/getAllFirmDetailsSlice";
import Loader from "../../components/Loader/Loader";
import { ReactComponent as AGSearchIcon } from "../../assets/images/AGSearchIcon.svg";
import { ReactComponent as SearchXIcon } from "../../assets/images/SearchXIcon.svg";

export const parseDate = (params) => {
  if (!params.value) return "";
  const dateObj = new Date(params.value);
  return dateObj.toISOString().split("T")[0];
};

 const FirmLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location?.state?.activeTab ? location?.state?.activeTab : "attorneyBased"
  );
  const [searchText, setSearchText] = useState("");
  const handelFirmOnboarding = () => {
    navigate(mvssuperadmin_firmOnboardingForm);
  };
  const firmDetailsLanding = (event) => {
    navigate(mvssuperadmin_firmViewDetails, {
      state: {
        page: "firmviewdetailspage",
        id: event.data.firmId,
        activeTab:
          event.data.firmTypeId === 1 ? "attorneyBased" : "nonAttorneyBased",
      },
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };

  const [rowData, setRowData] = useState([]);

  const { data: Attorney, isLoading: attorneyLoading} =
    useGetAllFirmDetailsQuery("Attorney", {
      skip: activeTab === "nonAttorneyBased",
      refetchOnMountOrArgChange: true,
    });
  const { data: NonAttorney, isLoading: nonAttorneyLoading} =
    useGetAllFirmDetailsQuery("NonAttorney", {
      skip: activeTab === "attorneyBased",
      refetchOnMountOrArgChange: true,
    });
  useEffect(() => {
    if (activeTab === "attorneyBased") {
      if (Attorney?.length > 0) {
        setRowData(Attorney);
      }else {
        setRowData([]);
      }
    } else if (activeTab === "nonAttorneyBased") {
      if (NonAttorney?.length > 0) {
        setRowData(NonAttorney);
      }else {
        setRowData([]);
      }
    }
  }, [activeTab, Attorney, NonAttorney]);

  const GridButton = ({ value }) => {
    const buttonClass =
      value === "Inactive" ? "grid-inactive-button" : "grid-active-button";
    return (
      <div className={buttonClass}>
        <Button>{value}</Button>
      </div>
    );
  };
 
  const GridFirmName = (params) => {
    const { value } = params;
    const fullName = value.length > 20 ? value.substring(0, 20) + "..." : value;

    if (value.length > 20) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
        >
          <div className="truncate-tooltip grid-firm-name-anchor">
            {fullName}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="truncate-tooltip grid-firm-name-anchor">{fullName}</div>
      );
    }
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return <div className="grid-center-aligned-text">{p.value}</div>;
  };

  const [columnDefs] = useState([
    {
      headerName: firmLandingPlaceholder.firmName,
      field: "firmName",
      minWidth: 140,
      sortable: true,
      cellRenderer: GridFirmName,
      unSortIcon: true,
      pinned: "left",
      onCellClicked: (event) => firmDetailsLanding(event, activeTab),
    },
    {
      headerName: firmLandingPlaceholder.plan,
      field: "planName",
      headerComponentParams: { "data-testid": "planName" },
      getQuickFilterText: () => "",
    },
    {
      headerName: firmLandingPlaceholder.accountActivation,
      field: "subBillingCycleStartDate",
      cellRenderer: parseDate,
      getQuickFilterText: () => "",
    },
    {
      headerName: firmLandingPlaceholder.renewalDate,
      field: "subBillingCycleEndDate",
      cellRenderer: parseDate,
      getQuickFilterText: () => "",
    },
    {
      headerName: firmLandingPlaceholder.numberOfAccounts,
      field: "numberOfAccounts",
      getQuickFilterText: () => "",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: firmLandingPlaceholder.willsUploaded,
      field: "willsUploaded",
      getQuickFilterText: () => "",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
    },
    {
      headerName: firmLandingPlaceholder.subStatus,
      field: "subStatus",
      cellRenderer: GridButton,
      getQuickFilterText: () => "",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 150,
    };
  }, []);

  const paginationSizeLimit = 10;

  return (
    <>
      <div className="firm-title-page-container">
        <Container fluid>
          <div className="px-lg-5 px-md-4 px-1">
            <FirmPageTitleBar
              title={firmDetailsLabel}
              hasButton={true}
              buttonText={newOnboardButtonText}
              navigateTo={handelFirmOnboarding}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4">
        <Container fluid>
          <div className="firm-page-container px-3 px-lg-4 px-md-4 px-sm-4 pt-2">
            <Row className="mb-3">
              <Col
                xl={6}
                lg={6}
                md={12}
                xs={12}
                className="d-flex justify-content-start mt-3"
              >
                <TabButton
                  activeTab={activeTab}
                  setActiveTab={(tabKey) => setActiveTab(tabKey)}
                  data-testid="tab-container"
                />
              </Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                xs={12}
                className="d-flex justify-content-end mt-3"
              >
                <div className="search-container">
                  <div className="search-icon">
                    <AGSearchIcon />
                  </div>
                  <div className="search-input-field ">
                    <input
                      type="text"
                      placeholder="Search by Firm Name"
                      value={searchText}
                      autoComplete="off"
                      onChange={handleSearchChange}
                      id="search-firm-input-field"
                      data-testid="grid-search-input"
                    />
                  </div>
                  {searchText && (
                  <div
                    onClick={handleClearSearch}
                    className="clear-search-button"
                  >
                    <SearchXIcon />
                  </div>
                )}
                </div>
              </Col>
            </Row>
            <Row className="m-10">
              <Col lg={12} xs={12}>
                <AgGrid
                  activeTab={activeTab}
                  quickFilterText={searchText}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  getRowStyle={getRowStyle}
                  paginationSizeLimit={paginationSizeLimit}
                />
                {(attorneyLoading || nonAttorneyLoading) && (
                  <Loader data-testid="loader" />
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default FirmLandingPage;

