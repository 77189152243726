import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useSignoutsuperuserMutation } from "../../slices/loginAuthManagementSlice";
import { toast } from "react-toastify";
import { signOutApiCall } from "../../slices/authSlice";
import Loader from "../Loader/Loader";
import { TIMEOUT_IN_MINUTES } from "../../constants/idleTimer";

const IdleTimerComponent = () => {
  const dispatch = useDispatch();
  const [signoutsuperuser, { isLoading }] = useSignoutsuperuserMutation();
  const { userInfo } = useSelector((state) => state.auth);

  const onIdle = async () => {
    try {
      const res = await signoutsuperuser(userInfo?.accessToken).unwrap();
      dispatch(signOutApiCall());
      if(res?.isSuccessful) {
        toast.success(res?.message);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const timeoutInMilliseconds = TIMEOUT_IN_MINUTES * 60 * 1000;

  useIdleTimer({
    onIdle,
    timeout: timeoutInMilliseconds,
    promptBeforeIdle: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  return (
    <>
      <div data-testid="idle-timer">{isLoading && <Loader />}</div>
    </>
  );
};

export default IdleTimerComponent;
