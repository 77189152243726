import { gaApiSlice } from "./gaApiSlice";

export const gaApiSliceManagementSlice = gaApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    googleAnalyticsKey: builder.query({
      query: () => ({
        url: `/analytics-key`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGoogleAnalyticsKeyQuery } = gaApiSliceManagementSlice;
