import React from "react";
import {
  Offcanvas,
  Container,
  Alert,
} from "react-bootstrap";
import Loader from "../Loader/Loader";
import {
  useGetClientQuery,
} from "../../slices/attorneyApiManagementSlice";
import ClientOffCanvasViewProfile from "./ClientOffCanvasViewProfile";

export default function ClientOffCanvasEditComponent({
  visible,
  onClose,
  state,
  handleEditClient,
  handleEditFamily,
  handleAddFamily
}) {
  const { data, error, isLoading } = useGetClientQuery(state?.id, {
    skip: state?.id === undefined,
  });
  if (error) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status - {error?.status}.{" "}
          {error?.data}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <div data-testid="client-edit-profile">
        <Offcanvas
          show={visible}
          onHide={onClose}
          placement="end"
          id="client-off-canvas"
          className="client-off-canvas"
        >
        <Offcanvas.Header closeButton>
            <Offcanvas.Title><b>My Information</b></Offcanvas.Title>
          </Offcanvas.Header>
          <div className="px-3 mb-0">
            <div className="divider-firm"></div>
          </div>
          <Offcanvas.Body>
            <ClientOffCanvasViewProfile data={data} handleEditClient={handleEditClient} handleEditFamily={handleEditFamily} handleAddFamily={handleAddFamily}/>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {(isLoading) && <Loader />}
    </>
  );
};
