import { additionalProUserApiSlice } from "./additionalProfUserApiSlice";

export const additionalProfUserApiManagementSlice =
additionalProUserApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      additionalProfessionalAccess: builder.mutation({
        query: (data) => ({
          url: `/additional-professional`,
          method: "POST",
          body: data,
        }),
      }),
    }),
  });

export const {
  useAdditionalProfessionalAccessMutation,
} = additionalProfUserApiManagementSlice;
