import { noClaimApiSlice } from "./noClaimSlice";

export const noClaimApiManagementSlice =
  noClaimApiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getNoClaim: builder.query({
        query: (identifier) => ({
          url: `/death-claim/${identifier}`,
          method: "GET",
        }),
        providesTags: ["no-claim"],
      }),
    }),
  });

export const {
    useGetNoClaimQuery
} = noClaimApiManagementSlice;
