import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FormTitle from "../components/form/FormTitle";
import Header from "../components/headers/Header";
import { inputFocus } from "../utils/inputMethod";
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSendOtpMutation } from "../slices/documentApiManagementV2";
import axios from "axios";
import OtpShareLinkPopup from "../components/modal/OtpShareLinkPopup";
import OtpExpiredModal from "../components/modal/OtpExpiredModal";
import { famVault_wesbite } from "../constants/routes";

export default function ShareOtpVerification() {
  const otpTime = 30;
  const [seconds, setSeconds] = useState(otpTime); 
  const [timerRunning, setTimerRunning] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const effectRan = useRef(false);
  const [isLoading, setIsLoading] = useState(false)
  const [progressMap, setProgressMap] = useState({});
  const identifierID = useSelector(
    (state) => state.shareReceiveBankDetails.identifierID
  );
  const requestType = useSelector(
    (state) => state.shareReceiveBankDetails.requestType
  );
  const checkShare = identifierID && requestType && (requestType === "SHARE" || requestType === "BULK_SHARE")
  const [sendOtp, {isLoading: isOtpLoading}] = useSendOtpMutation();

  const sendOtpFunction = useCallback(async () => {  
    if (checkShare) {
      try {
        const res = await sendOtp({
          identifierID,
          requestType,
        }).unwrap();
        
        if (res?.isSuccessful) {
          
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
          if(err?.data?.isExpired && err?.data?.isExpired === true) {
            setIsExpired(true)
          }
          else{
            toast.error(err?.data?.message || err?.message || err?.error?.message || err?.error || err);
          }
      } 
    }
  }, [checkShare, identifierID, requestType, sendOtp]);

  const retryDownload =  () => {
    setShowModal(false)
    setProgressMap({})
    reset()
    handleResendOTP()
  }

  const handleModalClose = () =>{
    setProgressMap({})
    setShowModal(false)
    reset()
  }
  useEffect(() => {
    let timer;
    if (timerRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(timer);
          setTimerRunning(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [seconds, timerRunning]);

  useEffect(() => {
    if (!effectRan.current) {
      sendOtpFunction();
    }
 
    return () => effectRan.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;


  const timerDisplay = `${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm();
  const handleDigitChange = (event, value) => {
    if (event.target.value.length > 1) {
      setValue(value, event.target.value[event.target.value.length - 1]);
    } else {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");
      setValue(value, numericValue);
    }
  };

  const onSubmit = async (formData) => {
    const currentotp = Object.values(formData).toString().replaceAll(",", "");
    const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v2/share/otp/${identifierID}/verify?otp=${currentotp}`;
  
    try {
      const response = await axios({
        url: baseUrl,
        method: "POST",
        responseType: "blob",
        headers: { "Access-Control-Allow-Credentials": true },
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          const docName = "File"
          if (totalLength !== null) {
            setIsLoading(true);
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            // Update progress for this specific file
            setProgressMap((prevProgressMap) => ({
              ...prevProgressMap,
              [docName]: { progress, docName },
            }));
          }
        },
      });
  
      if (response.status === 200) {
        setIsLoading(true)
        const contentDisposition = response.headers['content-disposition'];
        let fileName = "downloaded-file"; // Default filename
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches[1]) {
            fileName = matches[1];
          }
        }
  
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setIsLoading(false)
        setProgressMap({})
        setShowModal(true);
        toast.success(`File has been downloaded successfully!`);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(`Error : Invalid OTP`);
      } else {
        console.error("Error downloading file:", error);
        toast.error(`File downloading failed. Try again!`);
      }
    }
  };
  
  
  const handleResendOTP = async () => {
    setSeconds(otpTime);
    setTimerRunning(true);
      try {
        const res = await sendOtp({
          identifierID,
          requestType,
        }).unwrap();
        
        if (res?.isSuccessful) {
          
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
          if(err?.data?.isExpired && err?.data?.isExpired === true) {
            setIsExpired(true)
          }
          else{
            toast.error(err?.data?.message || err?.message || err?.error?.message || err?.error || err);
          }
      } 
    }
  return (
    <>
      <Header />
      <div className="otp-verification-container">
        
        <Container fluid>
          <Row>
          <Col xs={12} lg={7} className="d-none d-lg-block p-0">
              <div className="change-pwd-web-image"></div>
            </Col>
            <Col md={12} sm={12} lg={5} xs={12}>
              <div className="d-flex justify-contant-lg-center justify-content-md-center justify-content-sm-center justify-align-container">
                <div className="inner-shareotp-container">
                  <Form
                    className="custom-form w-100 type-2"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormTitle
                      title="OTP Verification"
                      variant="variant-2"
                    />
                    <Form.Group>
                      <Form.Label
                        htmlFor="photp1"
                        className="custom-form-label custom-label-shareotp"
                      >
                       Enter the code sent to your Mobile Phone 
                      </Form.Label>
                      <div className="ms-0 d-flex justify-content-between">
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp1"
                          name="photp1"
                          data-testid="photp1-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          autoFocus={true}
                          min={0}
                          max={9}
                          {...register("photp1", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp1")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="1"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp2"
                          name="photp2"
                          data-testid="photp2-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp2", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp2")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="2"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp3"
                          name="photp3"
                          data-testid="photp3-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp3", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp3")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="3"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp4"
                          name="photp4"
                          data-testid="photp4-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp4", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp4")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          //isInvalid={!!errors.otp4}
                          tabIndex="4"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp5"
                          name="photp5"
                          data-testid="photp5-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp5", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp5")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="5"
                        />
                        <Form.Control
                          className="form-input-otp form-input-otp-ib"
                          id="photp6"
                          name="photp6"
                          data-testid="photp6-input"
                          placeholder="0"
                          autoComplete="off"
                          type="number"
                          min={0}
                          max={9}
                          {...register("photp6", {
                            valueAsNumber: true,
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]$/,
                              message: "Please enter only one digit",
                            },
                          })}
                          onChange={(e) => handleDigitChange(e, "photp6")}
                          onKeyUp={(e) => inputFocus(e, 1, 6)}
                          tabIndex="6"
                        />
                      </div>
                      {errors &&
                        (errors?.photp1 ||
                          errors?.photp2 ||
                          errors?.photp3 ||
                          errors?.photp4 ||
                          errors?.photp5 ||
                          errors?.photp6) && (
                          <Row>
                            <Col className="mt-1">
                              <span className="otp-verify-error">
                                Please Enter 6 Digit OTP
                              </span>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end  mt-2">
                                <div className="custom-resend-otp">
                                  <span
                                    onClick={handleResendOTP}
                                    className={`btn-resent-otp ${
                                      seconds === 0 ? "active" : "disabled"
                                    }`}
                                  >
                                    Resend OTP
                                  </span>{" "}
                                  in {timerDisplay}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                    </Form.Group>
                    {!(
                      errors &&
                      (errors?.photp1 ||
                        errors?.photp2 ||
                        errors?.photp3 ||
                        errors?.photp4 ||
                        errors?.photp5 ||
                        errors?.photp6)
                    ) && (
                      <div className="d-flex justify-content-end  mt-2">
                        <div className="custom-resend-otp">
                          <span
                            onClick={handleResendOTP}
                            className={`btn-resent-otp ${
                              seconds === 0 ? "active" : "disabled"
                            }`}
                          >
                            Resend OTP
                          </span>{" "}
                          in {timerDisplay}
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end  mt-4">
                      <Button
                        className="custom-button btn-standard btn-shareotp-submit"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showModal && 
      <OtpShareLinkPopup
      showModal={showModal}
      setShowCallback={handleModalClose}
      retryDownload={retryDownload}
    />}
    {isExpired && 
    <OtpExpiredModal
    showModal={isExpired}
    setShowCallback={() => window.location.href = `${famVault_wesbite}`}
    />}
      {(isOtpLoading || isLoading) && <Loader />}
      <div className="background-mvs-image type-1"></div>
      {Object.entries(progressMap).length > 0 &&  (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(([docId= "docId", { progress }]) => (
            <div key={docId} className="progress-bar-document">
              <div className="d-flex flex-column">
                <div>File downloading</div>
                <div className="d-flex align-items-center">
                  <progress value={progress} max={100} />{" "}
                  <div className="ps-2">{progress}%</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
