import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
import AgGrid from "../../components/table/AgGrid";
import Loader from "../../components/Loader/Loader";
import { medTable } from "../../constants/deathclaim/labels";
import { useGetMedicalProfessionalDocsQuery } from "../../slices/medicalProfessionalApiManagementSlice";
import { toast } from "react-toastify";
import { signOutApiCall } from "../../slices/authSlice";
import bytes from "bytes";
import { defaultRoute } from "../../constants/routes";
import dayjs from "dayjs";

export default function MedicalProfessionalLandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [rowData, setRowData] = useState([]);
  const clientId = location.state?.userId;
  const medicalprofessionalId = location.state?.medicalprofessionalid;

  if (clientId === undefined || medicalprofessionalId === undefined) {
    navigate(defaultRoute);
  }

  const getdocs = {
    clientIdentifier : clientId,
    professionalId : medicalprofessionalId
  }

  let getdocsstr = JSON.stringify(getdocs)

  const {
    data,
    isLoading,
    error: medLoadingError,
  } = useGetMedicalProfessionalDocsQuery(getdocsstr);

  const clientDocuments = async (e) => {
    const docName = e.data?.documentName;
    try {
      const link = document.createElement("a");
      link.href = e.data?.link;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(signOutApiCall());
        toast.error(`Signed out successfully due to token expiration!`);
      }
      console.error("Error downloading file:", error);
      toast.error(`${docName} downloading failed. Try again!`);
    }
  };

  const GridFileName = (params) => {
    const value = params.data?.documentName;
    const fileName = value.length > 60 ? value.substring(0, 56) + "..." : value;

    return fileName.includes("...") ? (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${value}`}>{value}</Tooltip>}
      >
        <div className="truncate-tooltip grid-firm-name-anchor">{fileName}</div>
      </OverlayTrigger>
    ) : (
      <div className="truncate-tooltip grid-firm-name-anchor">{fileName}</div>
    );
  };

  const dateFormat = (params) => {
    const value = params.data?.lastModified;
    const formattedDate = dayjs(value).format("MM/DD/YYYY h:mm A");
    return formattedDate;
  };

  const getRowStyle = () => {
    return { borderBottom: "unset" };
  };

  const CenterAligned = (p) => {
    return (
      <div className="grid-center-aligned-text">
        {bytes.format(p.value, {
          unitSeparator: " ",
        })}
      </div>
    );
  };

  const [columnDefs] = useState([
    {
      headerName: medTable.file,
      field: "documentName",
      minWidth: 450,
      cellRenderer: GridFileName,
      headerClass: "grid-center-aligned-text",
      onCellClicked: (event) => clientDocuments(event),
    },
    {
      headerName: medTable.uploadedby,
      field: "uploadedBy",
      minWidth: 200,
    },
    {
      headerName: medTable.last_modified,
      field: "lastModified",
      cellRenderer: dateFormat,
      headerClass: "grid-center-aligned-text",
      minWidth: 200,
    },
    {
      headerName: medTable.size,
      field: "docSize",
      cellRenderer: CenterAligned,
      headerClass: "center-aligned-header",
      minWidth: 100,
    },
  ]);

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      suppressMovable: false,
      sortable: false,
      wrapHeaderText: true,
      wrapText: true,
      headerClass: "custom-header",
      resizable: false,
      lockPosition: true,
      filter: false,
      minWidth: 140,
    };
  }, []);

  const paginationSizeLimit = 10;

  if (medLoadingError) {
    return (
      <Container
        data-testid="error-check-view"
        className="pt-5"
        style={{ position: "absolute", zIndex: 999, top: 30 }}
      >
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {medLoadingError?.status}.{" "}
          {medLoadingError?.data || medLoadingError?.message}
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Container fluid className="pt-2">
        <div className="med-page-container px-3 px-lg-4 px-md-4 px-sm-4 mt-4">
          <Row className="m-10 pt-4 px-lg-3 px-md-3">
            <Col lg={12} xs={12} id="firm-admin-attorney-view-aggrid">
              <AgGrid
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowStyle={getRowStyle}
                paginationSizeLimit={paginationSizeLimit}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {isLoading && <Loader />}
    </>
  );
}
