export const stepStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        width: "20px",
        height: "20px",
        color: "#fff",
        border: "2px solid rgba(217, 219, 227, 1)",
        borderRadius: "50%",
        //color: "rgba(237,108,2,255)"
      },
      "& .MuiStepConnector-line": {
        borderColor: "rgba(80, 184, 74, 1)",
        borderTopWidth: "2px",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "rgba(80, 184, 74, 1)",
        border: "0",
      },
      "& .MuiStepConnector-line": {
        borderColor: "rgba(80, 184, 74, 1)",
        borderTopWidth: "2px",
      },
    },
    "& .MuiStepIcon-root": {
      width: "20px",
      height: "20px",
      color: "#fff",
      border: "2px solid rgba(217, 219, 227, 1)",
      borderRadius: "50%",
    },
    "& .MuiStepConnector-root": {
      top: "10px",
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
      "& .MuiStepConnector-line": {
        borderTopWidth: "2px",
        //borderColor: "rgba(80, 184, 74, 1)",
      },
    },
    "& .MuiStepLabel-root":{
      fontFamily:"Helvetica Regular !important",
      src:"url('../../assets/fonts/Helvetica.ttf')"
    }
  };
export const stepSkipStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        width: "20px",
        height: "20px",
        color: "#fff",
        border: "2px solid rgba(217, 219, 227, 1)",
        borderRadius: "50%",
        //color: "rgba(237,108,2,255)"
      },
      "& .MuiStepConnector-line": {
        borderColor: "rgba(80, 184, 74, 1)",
        borderTopWidth: "2px",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "rgba(80, 184, 74, 1)",
        border: "0",
      },
      "& .MuiStepConnector-line": {
        borderColor: "rgba(80, 184, 74, 1)",
        borderTopWidth: "2px",
      },
    },
    "& .MuiStepIcon-root": {
      width: "20px",
      height: "20px",
      color: "#fff",
      border: "2px solid rgba(217, 219, 227, 1)",
      borderRadius: "50%",
    },
    "& .MuiStepConnector-root": {
      top: "10px",
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
      "& .MuiStepConnector-line": {
        borderTopWidth: "2px",
        borderColor: "rgba(80, 184, 74, 1)",
      },
    },
    "& .MuiStepLabel-root":{
      fontFamily:"Helvetica Regular !important",
      src:"url('../../assets/fonts/Helvetica.ttf')"
    }
  };