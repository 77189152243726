import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userIdentifer:null 
};

const addprofSlice = createSlice({
  name: "addprof",
  initialState,
  reducers: {
    setIdentifier: (state, action) => {
      state.userIdentifer = action.payload;
      }
  },
});

export const { setIdentifier} = addprofSlice.actions;

export default addprofSlice.reducer;
